<template>
	<div class="layout">
		<UiNavigation :items="navItems">
	    <template #logo>
	      <img src="https://via.placeholder.com/150x50" alt="Logo" />
	    </template>
	    <template #dropdown-menu>
	      <div class="dropdown-item">Profile</div>
	      <div class="dropdown-item">Settings</div>
	      <div class="dropdown-item">Logout</div>
	    </template>
	  </UiNavigation>
		<slot/>
	</div>
  <!-- <UiSlide width="600px" id="chat-slide">
    <ChatPopup v-if="$store.state.auth.authenticated"></ChatPopup>
  </UiSlide> -->
</template>

<script setup>
	import { ref } from "vue"
  import ChatPopup from "./ChatPopup"

	const navItems = [
  {
    key: 'home',
    name: 'Home',
    route: '/',
    icon: 'home', // Update with the actual icon path
  },
  {
    key: 'manage',
    name: 'Manage',
    route: '/m/virts',
    icon: 'cog', // Update with the actual icon path
    children: [
      {
        key: 'virts',
        name: 'Virts',
        route: '/m/virts',
        icon: 'users', // Update with the actual icon path
      },
      {
        key: 'contacts',
        name: 'Contacts',
        route: '/m/contacts',
        icon: 'users', // Update with the actual icon path
      },
      {
        key: 'documents',
        name: 'Documents',
        route: '/m/documents',
        icon: 'documents', // Update with the actual icon path
      },
      {
        key: 'analytics',
        name: 'Performance',
        route: '/',
        icon: 'chart-bar',
        notifications: 2,
      },
      {
        key: 'campaigns',
        name: 'Campaigns',
        route: '/',
        icon: 'lightning-bolt', // Update with the actual icon path
      },
      {
        key: 'funnel',
        name: 'Funnel',
        route: '/',
        icon: 'filter', // Update with the actual icon path
      },
      {
        key: 'emails',
        name: 'E-Mails',
        route: '/',
        icon: 'mail', // Update with the actual icon path
      },
      {
        key: 'testimonials',
        name: 'Testimonials',
        route: '/',
        icon: 'thumb-up', // Update with the actual icon path
      },
    ],
  },
  {
    key: 'web',
    name: 'Web & Apps',
    route: '/',
    icon: 'desktop-computer', // Update with the actual icon path
    children: [
      {
        key: 'website',
        name: 'Website',
        route: '/',
        icon: 'desktop-computer', // Update with the actual icon path
      },
      {
        key: 'apps',
        name: 'Apps',
        route: '/',
        icon: 'puzzle', // Update with the actual icon path
        children: [
          {
            key: 'stripe',
            name: 'Stripe',
            route: '/',
            icon: 'brand-stripe', // Update with the actual icon path
          },
          {
            key: 'billing',
            name: 'Billing',
            route: '/',
            icon: 'credit-card', // Update with the actual icon path
          },
        ],
      },
    ],
  },
  {
    key: 'settings',
    name: 'Settings',
    route: '/',
    icon: 'cog', // Update with the actual icon path
    children: [
      {
        key: 'security',
        name: 'Security',
        route: '/',
        icon: 'shield-check', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'Billing',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      },
    ],
  },
  {
    key: 'tasks',
    name: 'Tasks',
    route: '/tasks',
    icon: 'cog',
  },
  {
    key: 'settings',
    name: 'Virt',
    route: '/virts',
    icon: 'cog', // Update with the actual icon path
    children: [
      {
        key: 'security',
        name: 'Security',
        route: '/',
        icon: 'shield-check', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'Billing',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      },
    ],
  },
  {
    key: 'admin',
    name: 'Admin',
    route: '/virts',
    icon: 'cog', // Update with the actual icon path
    children: [
      {
        key: 'security',
        name: 'API\'s',
        route: '/',
        icon: 'shield-check', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'Features',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'Virt Jobs',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'GPT Models',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'CRM Tables',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      },
      {
        key: 'billing',
        name: 'Form Schemas',
        route: '/',
        icon: 'credit-card', // Update with the actual icon path
      }
    ],
  },
];
</script>