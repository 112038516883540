<template>
	<div :class="classes">
		<div class="grid-tile-inner">
			<slot></slot>
		</div>
	</div>
</template>

<style scoped>
	.ui-grid-tile {
		border-radius: 16px;
		background: var(--ui-grid-tile_background);
		color: var(--ui-grid-tile_color);
		padding: var(--ui-grid-tile_padding);
		display: inline-block;
		font-weight: 500; 
		line-height: normal; 
		position: relative;
	}
	.ui-grid-tile.full {
		width: 100%;
	}
	.ui-grid-tile.grow {
		height: 100%;
	}
	.ui-grid-tile-size-s {
		font-size: 14px;
		padding: 8px 12px;
		border-radius: 12px;
	}
	.ui-grid-tile-color-white {
		background: var(--ui-grid-tile-white_background);
	}
	.ui-grid-tile.clickable {
		cursor: pointer;
	}
	.ui-grid-tile.clickable:hover {
		background: var(--ui-grid-tile-hover_background);
	}
</style>

<script setup>
	import { computed } from "vue"

	const props = defineProps({
		full: {
			type: Boolean,
		},
		grow: {
			type: Boolean,
		},
		clickable: {
			type: Boolean,
		},
		size: {
			type: String,
			default: 'm'
		},
		color: {
			type: String,
			default: 'primary'
		},
	})

	const classes = computed(() => [
		'ui-grid-tile',
		props.full && 'full',
		props.grow && 'grow',
		props.clickable && 'clickable',
		`ui-grid-tile-color-${props.color}`,
		`ui-grid-tile-size-${props.size}`
	])
</script>