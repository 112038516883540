<template>
  <UiInput label="Title" v-model="modelValue.title" @keyup.enter="onEnter" />

  <!-- Event Type -->
  <UiInputDropdown
    v-model="modelValue.key"
    :items="eventsList"
    label="Event Type"
    placeholder=""
    @change="modelValue.reference_id = null"
    @keyup.enter="onEnter"
  />

  <!-- Event Option -->
  <template v-if="modelValue.key && referenceItemsList.length && currentEvent && currentEvent.hasItems">
    <UiInputDropdown
      v-model="modelValue.reference_id"
      :items="referenceItemsList"
      :label="`Select ${currentReference ? currentReference.name : 'Option'}`"
      placeholder=""
      @create="onCreateEventType"
      @keyup.enter="onEnter"
    />
    <!-- <a @click="showViewWebhookModal = true" v-if="">View webhook data output schema</a> -->
    <br>
  </template>

  <!-- IF Trigger -->
  <div v-show="['trigger_webhook'].includes(modelValue.key)">
    <UiButton size="s" @clicked="onTestRunTrigger" :loading="testRunning" :disabled="testRunning">Test run</UiButton>

    <div class="form-field">
      <label>Test Data:</label>
      <textarea v-model="testData" @change="onChangeTestData"></textarea>
    </div>

    Results
    <div v-for="result in sequenceRunResults" :key="result">
      {{ result }}
    </div>
  </div>

  <!-- IF Condition -->
  <div v-show="['query_db', 'condition_db', 'condition_related'].includes(modelValue.key)">
    <div class="form-field">
      <label size="s" style="margin-bottom: 16px;">Condition</label>
      <FormConditionBuilder v-model="modelValue.condition" @keyup.enter="onEnter" />
    </div>

    <!-- Output -->
    <template v-show="['query_db'].includes(modelValue.key)">
      <FormQueryModeSelector v-model="modelQuerySelector" @keyup.enter="onEnter" />
      <UiButton size="s" @click="onTestRunQuery">Run Query</UiButton>
      <div v-if="testResult">
        <div class="form-field">
          <label>Result</label>
          <vue-json-pretty :data="testResult" />
        </div>
      </div>
      <div class="form-field">
        <label>Format data:</label>
        <textarea v-model="modelValue.data.transformations"></textarea>
      </div>
      <vue-json-pretty :data="transformPreview" />
      <div class="form-field">
        <label>Output template:</label>
        <textarea v-model="modelValue.data.output_template"></textarea>
      </div>
      <div class="form-field">
        <label>Output preview:</label>
        <div v-html="outputPreview" style="background: #f9f9f9; border-radius: 12px; min-height: 128px; padding: 4px 8px;"></div>
      </div>
    </template>
  </div>

  <template v-if="['send_email', 'send_form'].includes(modelValue.key)">
    <a @click="showEventSendEmailModal = true">Edit E-Mail Template</a>
    <br>
  </template>
  <template v-if="modelValue.key === 'send_form'">
    <a @click="showEventSendFormModal = true">Edit Form</a>
    <br>
  </template>
  <!-- <label for="memberSelect">Select Member:</label>
  <select id="memberSelect">
    <option v-for="member in members" :value="member.imageUrl">{{ member.name }}</option>
  </select>
  <br> -->
  <div class="form-field">
    <label for="statusSelect">Status:</label>
    <select id="statusSelect" v-model="modelValue.status">
      <option value="active">Active</option>
      <option value="inactive">Inactive</option>
      <option value="skip">Skip</option>
    </select>
  </div>

  <StudioModal position="right" v-if="showEventSendEmailModal" no-bg>
    <StudioModalBody @close="showEventSendEmailModal = false" full-height>
      <!-- Email Subject -->
      <UiInput v-model="modelValue.data.email_subject" label="Subject" @keyup.enter="onEnter" />

      <!-- Email Template -->
      <UiInputDropdown
        v-model="modelValue.data.email_template"
        :items="emailTemplatesList"
        label="E-Mail Template"
        placeholder=""
        @keyup.enter="onEnter"
      />

      <!-- Email Content -->
      <div class="form-field">
        <label>Content</label>
        <textarea class="textarea-edit" v-model="modelValue.data.body" placeholder="E-Mail Body"></textarea>
      </div>

      <div style="width: 100%; display: flex; justify-content: center; margin-top: 8px;">
        <UiButton @click="onUpdate" :loading="updatingData" style="width: 200px;">Update</UiButton>
      </div>
    </StudioModalBody>
  </StudioModal>
</template>

<script setup>
import { ref, watch, computed } from 'vue';
import { useStore } from "vuex";

import StudioModal from "@src/components/Studio/Modal"
import StudioModalBody from "@src/components/Studio/ModalBody"
import FormConditionBuilder from "./FormConditionBuilder"
import FormQueryModeSelector from "./FormQueryModeSelector"

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['update:modelValue', 'close', 'enter', 'save']);

const store = useStore();

const conditionOptionsList = computed(() =>
  store.state.studio.sequence.nodes
    .filter((s) => ["send_form"].includes(s.key))
    .map((s) => ({
      label: s.title,
      description: s.key,
      event: "condition_related",
      reference: s.key,
      value: s.id,
    }))
);

const referenceItemsLists = computed(() => [
  ...store.getters['studio/getEventOptions'],
  ...conditionOptionsList.value,
]);

const referenceItemsList = computed(() =>
  referenceItemsLists.value.filter((r) => r.event == props.modelValue.key)
);

const availableReferences = ref([
    {
        key: "trigger_webhook",
        name: "Webhook",
    },
    {
        key: "send_form",
        name: "Form",
    }
])

const currentReference = computed(() => availableReferences.value.find(r => r.key == props.modelValue.key))

const eventsList = ref([
    { label: 'Webhook', value: 'trigger_webhook', icon: '', description: 'This event fires when a defined webhook is called', hasItems: true },
    { label: 'Condition DB', value: 'condition_db', icon: '', description: 'Database based condition', hasItems: true },
    { label: 'Condition Related', value: 'condition_related', icon: '', description: 'Related events based condition', hasItems: true },
    { label: 'Send Form', value: 'send_form', icon: '', description: 'Send a form to an email address', hasItems: true },
    { label: 'Send E-Mail', value: 'send_email', icon: '', description: 'Send an email', hasItems: false },
    { label: 'Create Task', value: 'create_task', icon: '', description: 'Creates a task based on a template', hasItems: true },
    { label: 'API Request', value: 'api_request', icon: '', description: 'Call an API Endpoint', hasItems: true },
    { label: 'Query DB', value: 'query_db', icon: '', description: 'Query data', hasItems: true },
])

store.dispatch('studio/fetchEventOptions')
store.dispatch('studio/fetchEmailTemplates')

const emailTemplatesList = computed(() => store.getters["studio/getEmailTemplatesForDropdownList"])

const currentEvent = computed(() => eventsList.value.find(r => r.value == props.modelValue.key))

const testRunning = ref(false);
const testData = ref('');
const sequenceRunResults = ref([]);
const modelQuerySelector = ref(null);
const testResult = ref(null);
const transformPreview = ref(null);
const outputPreview = ref(null);
const updating = ref(false);
const updatingData = ref(false);
const showEventSendEmailModal = ref(false);
const showEventSendFormModal = ref(false);

watch(() => props.modelValue, () => {
    testData.value = JSON.stringify(props.modelValue.test_data)
})

const onTestRunTrigger = async () => {
    await onUpdate();
    testRunning.value = true
    sequenceRunResults.value = []
    store.dispatch('studio/runTrigger', {
        id: props.modelValue.id,
        data: {
            test_data: testData.value
        }
    }).then((res) => {
        testRunning.value = false
    }).catch(() => {
        testRunning.value = false
    })
}

const onTestRunQuery = async () => {
    await onUpdate();
    store.dispatch('studio/runSequenceQuery', {
        id: props.modelValue.id,
    }).then((res) => {
        testResult.value = res.data
    }).catch(() => {

    })
}

function onUpdate() {
  emit('save')
}

function onCreateEventType() {
  // Your function logic here
}

function onEnter() {
  emit('enter');
}

const onChangeTestData = () => {
    emit('update:modelValue', {...props.modelValue,test_data: JSON.parse(testData.value)});
}

</script>
