<template>
  <div v-html="svgContent" :style="svgStyle"></div>
</template>

<script>
export default {
  name: 'IconComponent',
  props: {
    iconName: {
      type: String,
      required: true
    },
    color: {
      type: String,
      default: '#000000'
    },
    size: {
      type: [String, Number],
      default: 24
    }
  },
  data() {
    return {
      svgContent: ''
    };
  },
  computed: {
    svgStyle() {
      return {
        fill: this.color,
        width: typeof this.size === 'number' ? `${this.size}px` : this.size,
        height: typeof this.size === 'number' ? `${this.size}px` : this.size
      };
    }
  },
  watch: {
    iconName: {
      immediate: true,
      handler(newVal) {
        this.loadSvg(newVal);
      }
    }
  },
  methods: {
    async loadSvg(iconName) {
      try {
        const svg = await import(`../../../assets/icons/${iconName}.svg`);
        this.svgContent = svg.default;
      } catch (error) {
        console.error('Error loading SVG:', error);
      }
    }
  }
};
</script>

<style scoped>
div {
  display: inline-block;
}
</style>
