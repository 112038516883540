<template>
  <div class="condition-builder">
    <condition-group
      v-for="(condition, index) in parsedConditions"
      :key="index"
      :index="index"
      :fields="fields"
      :conditions="conditionOptions"
      v-model="parsedConditions[index]"
      @remove-group="removeGroup"
      @change="onChange"
    />
    <vue-json-pretty :data="stringifyConditions(parsedConditions)" />
    <div style="display: flex; justify-content: end;">
      <UiButton color="outline" size="s" @click="addGroup">Add Condition</UiButton>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue';
import ConditionGroup from './ConditionGroup.vue';
import VueJsonPretty from 'vue-json-pretty';
import 'vue-json-pretty/lib/styles.css';

const props = defineProps({
  modelValue: String
});

const emit = defineEmits(['update:modelValue']);

const fields = ref(['status', 'form_id', 'object_id']); // Example fields, replace with your actual fields
const conditionOptions = ref(['IS', 'NOT']);
const parsedConditions = ref(parseConditions(props.modelValue));

function parseConditions(conditions) {
  if (!conditions) return [];
  return conditions.split(' AND ').map(condition => {
    let [field, rest] = condition.split(' IS ');
    if (rest === undefined) {
      [field, rest] = condition.split(' NOT ');
      if (rest !== undefined) {
        return { field: field.trim(), condition: 'NOT', value: rest.replace(/"/g, '').trim() };
      }
    } else {
      return { field: field.trim(), condition: 'IS', value: rest.replace(/"/g, '').trim() };
    }
    return { field: field.trim(), condition: '', value: '' };
  });
}

function stringifyConditions() {
  return parsedConditions.value.map(cond => `${cond.field} ${cond.condition} "${cond.value}"`).join(' AND ');
}

watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    parsedConditions.value = parseConditions(newValue);
  }
}, { immediate: true });

const onChange = () => {
  emit('update:modelValue', stringifyConditions());
}

function addGroup() {
  parsedConditions.value.push({ field: fields.value[0], condition: 'IS', value: '' });
}

function removeGroup(index) {
  parsedConditions.value.splice(index, 1);
  emit('update:modelValue', stringifyConditions());
}
</script>

<style scoped>
.condition-builder {
  display: flex;
  flex-direction: column;
}
</style>
