<template>
  <div class="condition-group">
    <div class="form-field">
      <label>Field</label>
      <select v-model="selectedField" @change="$emit('change')">
        <option v-for="field in fields" :key="field" :value="field">{{ field }}</option>
      </select>
    </div>
    <div class="form-field">
      <label>Condition</label>
      <select v-model="selectedCondition" @change="$emit('change')">
        <option v-for="condition in conditions" :key="condition" :value="condition">{{ condition }}</option>
      </select>
    </div>
    <div class="form-field">
      <label>Value</label>
      <input v-model="selectedValue" type="text" placeholder="Value" @change="$emit('change')">
    </div>
    <div @click="removeGroup" style="height: 40px;width: 40px;">x</div>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  fields: Array,
  conditions: Array,
  index: Number,
  modelValue: Object
});

const emit = defineEmits(['update:modelValue', 'remove-group', 'change']);

const selectedField = ref(props.modelValue?.field || '');
const selectedCondition = ref(props.modelValue?.condition || '');
const selectedValue = ref(props.modelValue?.value || '');

watch([selectedField, selectedCondition, selectedValue], () => {
  emit('update:modelValue', {
    field: selectedField.value,
    condition: selectedCondition.value,
    value: selectedValue.value
  });
});

function removeGroup() {
  emit('remove-group', props.index);
}
</script>

<style scoped>
.condition-group {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  gap: 4px;
}
.condition-group select, .condition-group input {
  margin-right: 10px;
}
</style>
