<template>
	<div :class="classes">
		<slot></slot>
	</div>
</template>

<style scoped>
	.ui-col {
		flex: none;
		display: flex;
		flex-flow: column;
	}
	.ui-col.align-center {
		align-items: center;
	}
	.ui-col.justify-end {
		justify-content: end;
	}
	.ui-col.gap-1 {
		gap: 4px;
	}
	.ui-col.gap-2 {
		gap: 8px;
	}
	.ui-col.gap-4 {
		gap: 16px;
	}
</style>

<script setup>
	import { computed } from "vue"

	const props = defineProps({
		gap: {
			type: String,
			default: ''
		},
		align: {
			type: String,
			default: ''
		},
		justify: {
			type: String,
			default: ''
		},
	})

	const classes = computed(() => [
		'ui-col',
		`align-${props.align}`,
		`justify-${props.justify}`,
		`gap-${props.gap}`,
	])
</script>