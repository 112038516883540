// utils.js

import {
    getParent,
    calculateScaledWidth,
    calculateScaledHeight,
    calculateVerticalOffset,
    drawTextLines,
    handleRotation,
    handleBoxShadow,
    handleBorderRadius
} from './helpers';

export function drawRect(ctx, rect, zoomLevel, offsetX, offsetY, hoveredRectangle, holdingRectangle, selectedRectangles) {
    const { 
        x, 
        y, 
        width, 
        height,
        lineWidth = 0, 
        strokeStyle = '#e7e7e7', 
        backgroundColor = '#f4f6f9', 
        boxShadowColor = null,
        boxShadowOffsetX = null,
        boxShadowOffsetY = null,
        boxShadowBlur = null,
        borderRadius = 0, 
        rotation = 0
    } = rect;

    const scaledX = (x * zoomLevel) + offsetX;
    const scaledY = (y * zoomLevel) + offsetY;
    const scaledWidth = width * zoomLevel;
    const scaledHeight = height * zoomLevel;

    let isHovered = false;
    if (hoveredRectangle && hoveredRectangle.sequence_id === rect.sequence_id && !holdingRectangle) {
        isHovered = true;
    }

    let isHolding = false;
    if (holdingRectangle && holdingRectangle.sequence_id === rect.sequence_id) {
        isHolding = true;
    }

    const finalBorderColor = (isHolding || isHovered) ? 'blue' : strokeStyle;
    const finalBorderWidth = 2;

    ctx.save();
    ctx.fillStyle = backgroundColor;

    // Handle rotation
    if (rotation !== 0) {
        ctx.translate(scaledX + scaledWidth / 2, scaledY + scaledHeight / 2);
        ctx.rotate(rotation * Math.PI / 180);
        ctx.translate(-(scaledX + scaledWidth / 2), -(scaledY + scaledHeight / 2));
    }

    // Handle box shadow
    if (boxShadowColor) {
        ctx.shadowColor = boxShadowColor || 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = boxShadowBlur || 10;
        ctx.shadowOffsetX = boxShadowOffsetX || 5;
        ctx.shadowOffsetY = boxShadowOffsetY || 5;
    }

    // Handle border radius
    ctx.beginPath();
    ctx.moveTo(scaledX + borderRadius, scaledY);
    ctx.lineTo(scaledX + scaledWidth - borderRadius, scaledY);
    ctx.quadraticCurveTo(scaledX + scaledWidth, scaledY, scaledX + scaledWidth, scaledY + borderRadius);
    ctx.lineTo(scaledX + scaledWidth, scaledY + scaledHeight - borderRadius);
    ctx.quadraticCurveTo(scaledX + scaledWidth, scaledY + scaledHeight, scaledX + scaledWidth - borderRadius, scaledY + scaledHeight);
    ctx.lineTo(scaledX + borderRadius, scaledY + scaledHeight);
    ctx.quadraticCurveTo(scaledX, scaledY + scaledHeight, scaledX, scaledY + scaledHeight - borderRadius);
    ctx.lineTo(scaledX, scaledY + borderRadius);
    ctx.quadraticCurveTo(scaledX, scaledY, scaledX + borderRadius, scaledY);
    ctx.closePath();

    if (lineWidth || isHovered || isHolding) {
        ctx.strokeStyle = finalBorderColor;
        ctx.lineWidth = finalBorderWidth;
        ctx.stroke();
    }

    if (selectedRectangles.includes(rect)) {
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.stroke();
    }

    ctx.fill();
    ctx.restore();
}

export function drawTextRect(ctx, rect, rectangles, zoomLevel, offsetX, offsetY, selectedRectangles) {
    const {
        x,
        y,
        width,
        height,
        horizontalResizing = 'hugContents',
        verticalResizing = 'hugContents',
        content,
        fontFamily = 'Cera',
        fontSize = 16,
        fontStyle = 'normal',
        fontWeight = 'normal',
        color = '#000000',
        letterSpacing = 0,
        lineHeight = 1.5,
        textDecoration = 'none',
        horizontalAlign = 'left',
        verticalAlign = 'top',
        truncate = false
    } = rect;

    const parent = getParent(rect, rectangles);

    const scaledWidth = calculateScaledWidth(ctx, rect, content, horizontalResizing, parent, zoomLevel);
    const scaledHeight = calculateScaledHeight(rect, content, fontSize, lineHeight, verticalResizing, parent, zoomLevel);

    const scaledX = (x * zoomLevel) + offsetX;
    const scaledY = (y * zoomLevel) + offsetY;

    ctx.save();
    ctx.fillStyle = color;
    ctx.font = `${fontStyle} ${fontWeight} ${fontSize * zoomLevel}px ${fontFamily}`;
    ctx.textAlign = horizontalAlign;
    ctx.textBaseline = verticalAlign;

    const lines = content.split('\n');
    const yOffset = calculateVerticalOffset(scaledHeight, lines, fontSize, lineHeight, verticalAlign, zoomLevel);

    drawTextLines(ctx, lines, scaledX, scaledY, scaledWidth, fontSize, lineHeight, horizontalAlign, yOffset, truncate, zoomLevel);

    if (selectedRectangles.includes(rect)) {
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
    }

    ctx.restore();
}

export function drawFrameRect(ctx, rect, zoomLevel, offsetX, offsetY, selectedRectangles, rectangles, layoutFrameChildren) {
    const { 
        x, 
        y, 
        width, 
        height, 
        strokeStyle = "", 
        lineWidth = 0,
        backgroundColor = 'rgba(0, 0, 0, 0.1)', 
        autoLayout = false,
        paddingTop = 0,
        paddingRight = 0,
        paddingBottom = 0,
        paddingLeft = 0
    } = rect;

    let adjustedX = x;
    let adjustedY = y;

    if (ctx._config.centerFrameRect) {
        adjustedX = ((ctx.canvas.width / ctx.dpr) / zoomLevel - width) / 2;
        adjustedY = ((ctx.canvas.height / ctx.dpr) / zoomLevel - height) / 2;
    }

    rect.x = adjustedX
    rect.y = adjustedY
    
    const scaledX = (adjustedX * zoomLevel) + offsetX;
    const scaledY = (adjustedY * zoomLevel) + offsetY;
    const scaledWidth = width * zoomLevel;
    const scaledHeight = height * zoomLevel;

    // Scale padding values by the zoom level
    const scaledPaddingTop = paddingTop * zoomLevel;
    const scaledPaddingRight = paddingRight * zoomLevel;
    const scaledPaddingBottom = paddingBottom * zoomLevel;
    const scaledPaddingLeft = paddingLeft * zoomLevel;

    rect.innerWidth = width - (paddingLeft + paddingRight);
    rect.innerHeight = height - (paddingTop + paddingBottom);

    ctx.save();
    ctx.fillStyle = backgroundColor;
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = lineWidth;

    if (lineWidth) {
        ctx.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);    
    }
    
    ctx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);

    if (selectedRectangles.includes(rect)) {
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
    }

    ctx.restore();

    if (autoLayout) {
        layoutFrameChildren(rect, rectangles, zoomLevel);
    }
}

export function drawGroupRect(ctx, rect, zoomLevel, offsetX, offsetY, selectedRectangles) {
    const { x, y, width, height, strokeStyle = '#000000', backgroundColor = 'rgba(0, 0, 0, 0.05)' } = rect;

    const scaledX = (x * zoomLevel) + offsetX;
    const scaledY = (y * zoomLevel) + offsetY;
    const scaledWidth = width * zoomLevel;
    const scaledHeight = height * zoomLevel;

    ctx.save();
    ctx.fillStyle = backgroundColor;
    ctx.strokeStyle = strokeStyle;
    ctx.lineWidth = 2;

    ctx.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
    ctx.fillRect(scaledX, scaledY, scaledWidth, scaledHeight);

    if (selectedRectangles.includes(rect)) {
        ctx.strokeStyle = 'blue';
        ctx.lineWidth = 2;
        ctx.strokeRect(scaledX, scaledY, scaledWidth, scaledHeight);
    }

    ctx.restore();
}

// utils.js (or wherever your utility functions are defined)

export function drawCard(ctx, rect, zoomLevel, offsetX, offsetY, selectedRectangles, hoveredRectangle, holdingRectangle, imageCache) {
    const { x, y, width, height, title, subtitle, displayData, imageUrl, svg, appName, key, brandColor, status, borderColor = '#e7e7e7', type } = rect;
    const radius = 10;
    const scaledX = (x * zoomLevel) + offsetX;
    const scaledY = (y * zoomLevel) + offsetY;
    const scaledWidth = width * zoomLevel;
    const scaledHeight = height * zoomLevel;

    let isHovered = false;
    if (hoveredRectangle && hoveredRectangle.sequence_id === rect.sequence_id && !holdingRectangle) {
        isHovered = true;
    }

    let isHolding = false;
    if (holdingRectangle && holdingRectangle.sequence_id === rect.sequence_id) {
        isHolding = true;
    }

    const finalWidth = false ? scaledWidth * 1.05 : scaledWidth;
    const finalHeight = false ? scaledHeight * 1.05 : scaledHeight;
    const finalX = false ? scaledX - (finalWidth - scaledWidth) / 2 : scaledX;
    const finalY = false ? scaledY - (finalHeight - scaledHeight) / 2 : scaledY;
    const finalBorderColor = (isHolding || isHovered) ? 'blue' : borderColor;
    const finalBorderWidth = isHolding ? 2 : 1;

    ctx.save();
    ctx.fillStyle = 'white';
    ctx.strokeStyle = finalBorderColor;
    ctx.lineWidth = finalBorderWidth;

    ctx.beginPath();
    ctx.moveTo(finalX + radius, finalY);
    ctx.arcTo(finalX + finalWidth, finalY, finalX + finalWidth, finalY + finalHeight, radius);
    ctx.arcTo(finalX + finalWidth, finalY + finalHeight, finalX, finalY + finalHeight, radius);
    ctx.arcTo(finalX, finalY + finalHeight, finalX, finalY, radius);
    ctx.arcTo(finalX, finalY, finalX + finalWidth, finalY, radius);
    ctx.closePath();

    ctx.fill();
    ctx.stroke();

    drawStandardCardContent(ctx, rect, finalX, finalY, finalWidth, finalHeight, zoomLevel, imageCache);

    ctx.restore();
}

function drawStandardCardContent(ctx, rect, finalX, finalY, finalWidth, finalHeight, zoomLevel, imageCache) {
    const { title, subtitle, displayData, imageUrl, svg, appName, key, brandColor, status } = rect;
    let textY = finalY + 25 * zoomLevel;

    // Draw title text with custom font
    ctx.font = `600 ${16 * zoomLevel}px Cera`;
    ctx.fillStyle = '#000';
    ctx.fillText(title, finalX + 12 * zoomLevel, textY);

    // Draw subtitle text with custom font
    if (subtitle) {
        textY += 20 * zoomLevel;
        ctx.font = `500 ${14 * zoomLevel}px Cera`;
        ctx.fillStyle = '#9fb759';
        ctx.fillText(subtitle, finalX + 12 * zoomLevel, textY);
    }

    // Draw displayData text with custom font, supporting line breaks
    if (displayData) {
        textY += subtitle ? 20 * zoomLevel : 20 * zoomLevel;
        ctx.font = `500 ${12 * zoomLevel}px Cera`;
        ctx.fillStyle = '#000';

        drawTextWithLineBreaks(ctx, displayData, finalX + 12 * zoomLevel, textY, finalWidth - 24 * zoomLevel, zoomLevel);
    }

    if (appName) {
        // Draw the rectangle with SVG icon and app name
        const iconRectWidth = 100 * zoomLevel; // Adjusted width to fit the app name
        const iconRectHeight = 20 * zoomLevel;
        const iconRectX = finalX + 10 * zoomLevel;
        const iconRectY = finalY + finalHeight - iconRectHeight - 10 * zoomLevel;
        ctx.fillStyle = brandColor || 'lightgray'; // Use brandColor if available
        ctx.fillRect(iconRectX, iconRectY, iconRectWidth, iconRectHeight);

        if (svg) {
            const svgIconImg = new Image();
            const svgIconBlob = new Blob([svg], { type: 'image/svg+xml;charset=utf-8' });
            const svgIconUrl = URL.createObjectURL(svgIconBlob);
            svgIconImg.src = svgIconUrl;

            svgIconImg.onload = () => {
                ctx.drawImage(svgIconImg, iconRectX + 5 * zoomLevel, iconRectY + 2 * zoomLevel, 16 * zoomLevel, 16 * zoomLevel); // Adjusted position
                URL.revokeObjectURL(svgIconUrl); // Clean up the object URL
            };
        }

        // Draw the app name beside the icon
        ctx.font = `500 ${12 * zoomLevel}px Cera`;
        ctx.fillStyle = 'black';
        ctx.fillText(appName, iconRectX + 25 * zoomLevel, iconRectY + 14 * zoomLevel); // Adjusted position
    }

    // Draw image in the bottom left
    if (imageUrl && imageUrl !== "undefined") {
        let img = imageCache[imageUrl];
        const imgSize = 24 * zoomLevel; // Size of the image
        const imgX = finalX + 10 * zoomLevel; // X position of the image (10px padding from left)
        const imgY = finalY + finalHeight - imgSize - 10 * zoomLevel; // Y position of the image (10px padding from bottom)
        const imgRadius = imgSize / 2; // Radius for the circular clipping path

        if (img && img.complete) {
            // Create a circular clipping path
            ctx.save();
            ctx.beginPath();
            ctx.arc(imgX + imgRadius, imgY + imgRadius, imgRadius, 0, Math.PI * 2);
            ctx.closePath();
            ctx.clip();

            // Draw the image
            ctx.drawImage(img, imgX, imgY, imgSize, imgSize);

            // Restore the context
            ctx.restore();
        } else {
            img = new Image();
            img.onload = () => {
                // Create a circular clipping path
                ctx.save();
                ctx.beginPath();
                ctx.arc(imgX + imgRadius, imgY + imgRadius, imgRadius, 0, Math.PI * 2);
                ctx.closePath();
                ctx.clip();

                // Draw the image
                ctx.drawImage(img, imgX, imgY, imgSize, imgSize);

                // Restore the context
                ctx.restore();
            };
            img.src = imageUrl;
            imageCache[imageUrl] = img;
        }
    }
}

// Function to handle drawing text with line breaks and word wrapping
function drawTextWithLineBreaks(ctx, text, x, y, maxWidth, zoomLevel) {
    const lines = text.split('\n');
    const lineHeight = 16 * zoomLevel; // Set a suitable line height

    lines.forEach(line => {
        let words = line.split(' ');
        let currentLine = '';
        for (let n = 0; n < words.length; n++) {
            let testLine = currentLine + words[n] + ' ';
            let metrics = ctx.measureText(testLine);
            let testWidth = metrics.width;
            if (testWidth > maxWidth && n > 0) {
                ctx.fillText(currentLine, x, y);
                currentLine = words[n] + ' ';
                y += lineHeight;
            } else {
                currentLine = testLine;
            }
        }
        ctx.fillText(currentLine, x, y);
        y += lineHeight;
    });
}