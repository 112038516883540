<template>
  <ul class="dropdown">
    <li
      class="dropdown-item"
      v-for="item in items"
      :key="item.key"
      @mouseover="showNestedDropdown(item.key)"
      @mouseleave="hideNestedDropdown(item.key)"
      @click.stop="handleItemClick(item)"
    >
      <div class="dropdown-item-content">
        <!-- <UiIcon :icon-name="item.icon" /> -->
        <span>{{ item.name }}</span>
      </div>
      <Dropdown
        v-if="item.children"
        :items="item.children"
        v-show="isDropdownVisible(item.key)"
        @click-item="handleChildItemClick"
      />
    </li>
  </ul>
</template>

<script setup>
import { ref, computed, defineEmits } from 'vue';
import UiIcon from '../Icon/Icon';

const emit = defineEmits(["click-item"])

const props = defineProps({
  items: Array
});

const dropdownVisibility = ref({});

const showNestedDropdown = (key) => {
  dropdownVisibility.value[key] = true;
};

const hideNestedDropdown = (key) => {
  dropdownVisibility.value[key] = false;
};

const isDropdownVisible = (key) => {
  return dropdownVisibility.value[key] || false;
};

const handleItemClick = (item) => {
  if (!item.children) {
    emit('click-item', item.key);
    hideNestedDropdown(item.key);
  }
};

const handleChildItemClick = (key) => {
  emit('click-item', key);
};
</script>

<style scoped>
.dropdown {
  position: absolute;
  top: 0;
  left: 100%;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  z-index: 1000;
  list-style: none;
  margin: 0;
  padding: 4px 0;
  min-width: 128px;
}

.dropdown-item-content {
  display: flex;
  gap: 4px;
}

.dropdown-item {
  display: flex;
  padding: 4px;
  padding-left: 16px;
  position: relative;
}

.dropdown-item:hover {
  background-color: var(--brand-color);
  border-radius: 4px;
}

.dropdown-item:hover > .dropdown-item-content > span {
  color: #fff;
}

.dropdown .dropdown {
  top: -4px;
}
</style>
