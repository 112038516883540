<template>
  <div class="studio-sidebar">
    <div class="sidebar-list">
      <!--
      <div class="sidebar-list-head">
        <div class="sidebar-list-title">Menu</div>
        <div class="sidebar-list-btn" @click="onCreate">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </div>
      </div>
      <div class="sidebar-list-inner">
        <div class="sidebar-list-item">
          <SidebarListItem
            v-for="(craft, index) in demoOptionsList"
            :key="craft.object_id"
            :craft="craft"
            :index="index"
          />
        </div>
      </div>
      -->
    	<div class="sidebar-list-head">
        <div class="sidebar-list-title">Crafts</div>
        <div class="sidebar-list-btn" @click="onCreate">
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
            <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
          </svg>
        </div>
      </div>
    	<div class="sidebar-list-inner">
	      <router-link class="sidebar-list-item" :to="`/studio/${craft.object_id}?sequence=${craft.object_id}`" v-for="(craft, index) in crafts" @click="emit('click-item')">
	        <SidebarListItem
	          :key="craft.object_id"
	          :craft="craft"
	          :index="index"
	          @update="refreshCrafts"
	        />
	      </router-link>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, computed, defineEmits } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import SidebarListItem from './SidebarListItem.vue';

const store = useStore();
const router = useRouter();

const emit = defineEmits(["click-item"])

const crafts = computed(() => store.getters["studio/getCrafts"]);

store.dispatch("studio/fetchCrafts", {}).then(res => {}).catch(() => {});

const onCreate = () => {
  store
    .dispatch("studio/createCraft", {
      data: {
        title: "New",
      },
    })
    .then((res) => {
      router.push(`/studio/${res.data.object_id}?sequence=${res.data.object_id}`);
    })
    .catch(() => {});
};

const refreshCrafts = () => {
  store.dispatch("studio/fetchCrafts");
};

const demoOptionsList = ref([
  {
    name: "Home"
  },
  {
    name: "Mail"
  },
  {
    name: "Chat"
  },
  {
    name: "Ads"
  },
  {
    name: "Contacts"
  },
  {
    name: "Flows"
  },
  {
    name: "Funnel"
  },
  {
    name: "Site"
  },
  {
    name: "Forms"
  },
  {
    name: "Tables"
  },
  {
    name: "Webhook"
  },
  {
    name: "APIs"
  },
  {
    name: "Payments"
  },
  {
    name: "PDF"
  },
  {
    name: "Invoice"
  },
  {
    name: "Socials"
  },
  {
    name: "Design"
  },
  {
    name: "Settings"
  },
])
</script>

<style>
  .studio-sidebar {
    position: fixed;
    left: 0;
    top: 0;
    height: 100vh;
    width: 266px;
    background: white;
    border-right: 1px solid #e7e7e7;
    padding: 64px 0;
    font-size: 14px;
  }
  .studio-sidebar .sidebar-list {
  }
  .studio-sidebar .sidebar-list-inner {
  	overflow: auto;
    max-height: 100vh;
  }
  .studio-sidebar .sidebar-list-title {
    font-weight: 700;
    line-height: 32px;
  }
  .sidebar-list-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background: #fff;
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid rgb(231, 231, 231);
  }
  .sidebar-list-btn {
    border: 1px solid rgb(231, 231, 231);
    height: 24px;
    width: 24px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sidebar-list-btn:hover {
    background: #f4f6f9;
  }
  .sidebar-list-btn svg {
    height: 18px;
  }
  /* width */
	.studio-sidebar .sidebar-list-inner::-webkit-scrollbar {
	  width: 5px;
	  padding: 24px 0;
	}

	/* Track */
	.studio-sidebar .sidebar-list-inner::-webkit-scrollbar-track {
	  background: #fff;
	  border-radius: 999px;
	}

	/* Handle */
	.studio-sidebar .sidebar-list-inner::-webkit-scrollbar-thumb {
	  background: #e8e8e8;
	  border-radius: 999px;
	}

	/* Handle on hover */
	.studio-sidebar .sidebar-list-inner::-webkit-scrollbar-thumb:hover {
	  background: #555;
	}
</style>
