<template>
	<div class="ui-list">
		<slot></slot>
	</div>
</template>

<style>
	.ui-list {
		display: flex;
		flex-flow: column;
		gap: 8px;
	}
</style>