<template>
  <div class="login-view container">
    <div class="form card grid gap-1-5">
      <UiHeadline size="m">Login</UiHeadline>
      <FormGenerator :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></FormGenerator>
      <div class="text-center">Are you new? <router-link class="strong" to="/sign-up">Sign Up</router-link>
      </div>
      <div class="text-center mt-8">
        <router-link to="/password/forgot"><strong>Forgot Password</strong></router-link>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref } from "vue"
import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"
import FormGenerator from "@lib/components/functional/FormGenerator/FormGenerator"

const store = useStore()
const router = useRouter()
const route = useRoute()
const model = ref({
  email: "",
  password: ""
})
const submitting = ref(false)
const errors = ref(null)

const submit = () => {
  if (submitting.value) return
  store.dispatch("dashboard/updateInteracted", true)
  submitting.value = true;
  store.dispatch("auth/login", model.value)
    .then((res) => {
      submitting.value = false;
      if (route.query.redirect) {
        router.push(route.query.redirect)
      } else {
        router.push('/')
      }
    })
    .catch((err) => {
    	console.log(err)
      submitting.value = false;
    });
}

const formSchema = ref(`{
  "rows": [
    {
      "columns": [
        {
          "fields": [
            {
              "key": "email",
              "type": "email",
              "label": "E-Mail address",
              "name": "email",
              "placeholder": "",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "key": "password",
              "type": "password",
              "label": "Password",
              "name": "password",
              "required": true
            }
          ]
        }
      ]
    },
    {
      "columns": [
        {
          "fields": [
            {
              "type": "submit",
              "label": "Login"
            }
          ]
        }
      ]
    }
  ]
}
`);

</script>
<style scoped>
.login-view {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-view .form {
  width: 100%;
  max-width: 500px;
  margin: auto;
}

.login-view .submit {
  margin-top: 24px;
}

</style>
