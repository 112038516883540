<template>
  <div class="task-board">
    <div v-for="(tasks, column) in columns" :key="column" class="column">
      <UiHeadline size="m" style="margin-bottom: 8px;">{{ column }}</UiHeadline>
      <div class="task-list">
        <VueDraggableNext v-model="columns[column]" group="tasks" @end="onEnd" class="draggable-container">
          <div v-for="task in tasks" :key="task.id" class="task-card" @click="$emit('click', task.id)">
            <div class="task-title">{{ task.title }}</div>
            <div class="task-status"></div>
            <div class="task-footer">
              <div class="task-member" v-for="member in task.members">
                <UiAvatar size="24px" :img-url="member.avatar_url" />
              </div>
            </div>
          </div>
        </VueDraggableNext>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue';
import { VueDraggableNext } from 'vue-draggable-next';

const props = defineProps({
  modelValue: {
    type: Object,
    default: () => ({})
  }
})

const columns = computed(() => props.modelValue)

const onEnd = (event) => {
  const { item, to } = event;
  const newStatus = to.previousElementSibling.innerText;
  item.status = newStatus;
};
</script>

<style scoped>
.task-board {
  display: flex;
  justify-content: space-between;
  gap: 8px;
  padding: 8px;
}
.column {
  background-color: #f6f6f6;
  padding: 0 6px;
/*  min-height: 80vh;*/
  width: 272px;
  border: 1px solid #f3f4f6;
  border-radius: 16px;
  box-shadow: 0 4px 8px rgba(0, 12, 43, .05);
  -webkit-backdrop-filter: blur(15.5px);
  backdrop-filter: blur(15.5px);
  /*border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;*/
  overflow: hidden;
}
.task-list {
  overflow-y: scroll;
  height: 100%;
  padding: 0 4px;
}
.draggable-container {
  min-height: 100%;
  padding: 0;
  border-radius: 8px;
}
.task-card {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 6px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  cursor: pointer;
  font-size: 15px;
  height: 64px;
}
.task-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-footer {
  display: flex;
  justify-content: end;
  align-items: center;
}
.dragging {
  opacity: 1; /* Increase the opacity of the dragging item */
}
.sortable-ghost {
  background: #e8e8e8;
  color: #e8e8e8;
}
.sortable-ghost .task-footer {
  display: none;
}
.ui-avatar {
  border: 1px solid #f6f6f6;
}
.ui-headline {
  margin-bottom: 8px;
  position: sticky;
  top: 0;
  background: #f6f6f6;
  margin-bottom: 8px;
  padding: 8px 4px;
  font-size: 20px;
  text-transform: capitalize;
}
/* width */
.task-list::-webkit-scrollbar {
  width: 6px;
  padding: 24px 0;
}

/* Track */
.task-list::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 999px;
}

/* Handle */
.task-list::-webkit-scrollbar-thumb {
  background: #e8e8e8;
  border-radius: 999px;
}

/* Handle on hover */
.task-list::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
