<template>
<div class="simple-spinner">
  <span></span>
</div>
</template>

<script setup>

</script>

<style scoped>
.simple-spinner {
	height: 16px;
	width: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.simple-spinner span {
  display: block;
  width: 16px;
  height: 16px;
  border: 2px solid transparent;
  border-radius: 50%;
  border-right-color: #8eb224;
  animation: spinner-anim 0.8s linear infinite;
  position: absolute;
}

@keyframes spinner-anim {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
