<template>
	<!-- Input -->
	<label>
    <input
      type="file"
      multiple
      @change="handleImageUpload($event)"
      @click="$event.target.value = ''"
    />
		<UiRow align="center" gap="2">
			<UiGridTile>{{ label }}</UiGridTile>
		</UiRow>
	</label>

	<!-- Files -->
	<div class="mt-2" v-if="newFiles.length">
		Files:
		<UiCol gap="1">
			<UiGridTile v-for="(file, index) in newFiles" @click="removeFile(index)">
				<UiRow justify="between" align="center">
					<div>{{ file.name }}</div>
					<Icon icon="close" :size="16"></Icon>
				</UiRow>
			</UiGridTile>
		</UiCol>
	</div>
</template>

<script setup>
	import { ref, defineEmits } from "vue"

	const props = defineProps({
		modelValue: {
	      type: Object,
	      default: () => ({})
	    },
		label: {
			type: String,
			default: 'Choose Files'
		},
		type: String
	})

	const emit = defineEmits(['update:modelValue'])

	const newFiles = ref([])
	const previewImage = ref(null)
	const formData = ref(new FormData());

	const handleImageUpload = (e) => {
	  newFiles.value = [];
	  newFiles.value.push(...e.target.files);
	  if (props.type === 'image') {
	  	previewImage.value = Array.from(e.target.files).map((image) => ({
		    url: URL.createObjectURL(image),
		  }));
	  }

	  updateFormData()
	};

	const updateFormData = () => {
	  newFiles.value.forEach((file) => {
	    formData.value.append("files", file);
	  });

	  emit('update:modelValue', formData.value);		
	}

	const removeFile = (index) => {
		newFiles.value.splice(index, 1);

		updateFormData()
	}
</script>

<style scoped>
	label {
		cursor: pointer;
	}
	input[type="file" i] {
		display: none;
	}
</style>