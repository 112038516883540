<template>
    <div :class="classes">
        <div class="modal-bg" v-if="!noBg"></div>
        <slot></slot>
       <div>
        <slot name="outer"></slot>
    </div>
       </div>
</template>

<script setup>
	import { defineEmits, computed } from "vue"

	const emit = defineEmits(['close'])

	const props = defineProps({
		title: String,
		size: {
			default: '512px',
			type: String
		},
		height: {
			default: 'auto',
			type: String
		},
        position: {
            default:  'middle',
            type: String
        },
        noBg: {
            default: false,
            type: Boolean
        },
	})

    const classes = computed(() => {
        return [
            "modal-wrapper",
            `modal-pos-${props.position}`
        ]
    })

	const onClickOutside = () => {
	    if (!props.preventClose) {
	      emit("close")
	    }
	  }

	  document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape') {
          emit("close")
      }
  });

</script>

<style>
    .modal-content {
        display: flex;
        flex-direction: column;
    }

    .modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh - 96px);
    overflow: none;
    padding-top: calc(100vh / 10);
    padding-bottom: 8px;
        display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
    pointer-events: none;
}
.modal-bg {
        background: rgb(255, 255, 255);
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
    z-index: -1;
    backdrop-filter: blur(16px);
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
}
.modal-wrapper.modal-pos-right {
    height: 100vh;
}
.modal-pos-right>.modal {
    position: absolute;
    top: 4px;
    right: 4px;
    height: 100vh;
}
.modal .modal {
    max-width: 538px;
}
</style>