<template>
  <img
    class="ui-avatar"
    :src="computedImgUrl"
    :style="`width: ${size}; height: ${size};`"
    @error="handleError"
  />
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  imgUrl: String,
  size: {
    type: String,
    default: '48px'
  }
});

const fallbackUrl = 'https://cdn.purdia.com/images/avatar.png';
const computedImgUrl = ref(props.imgUrl);

const handleError = () => {
  computedImgUrl.value = fallbackUrl;
};
</script>

<style scoped>
.ui-avatar {
  border-radius: 999px;
  background: #fff;
  object-fit: cover;
}
</style>
