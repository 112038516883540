const isServiceHours = () => {
  const now = new Date();
  const utcOffset = now.getTimezoneOffset() * 60000; // Convert to milliseconds
  const ctOffset = -300 * 60000; // UTC offset for Central Time (CT) is -5 hours or -300 minutes

  // Calculate current time in CT
  const ctTime = new Date(now.getTime() + utcOffset + ctOffset);

  // Get current hour in CT
  const ctHour = ctTime.getHours();

  // Check if current hour is between 7am and 4pm
  return ctHour >= 7 && ctHour <= 16;
}

export {
  isServiceHours
}

const formatCurrency = (amount, currencyCode) => {
  // Define the currency locales
  const locales = {
    USD: 'en-US', // US Dollar
    EUR: 'de-DE', // Euro
    GBP: 'en-GB', // British Pound
    JPY: 'ja-JP', // Japanese Yen
    CNY: 'zh-CN', // Chinese Yuan
    INR: 'en-IN', // Indian Rupee
    CAD: 'en-CA', // Canadian Dollar
    AUD: 'en-AU', // Australian Dollar
    CHF: 'de-CH', // Swiss Franc
    SEK: 'sv-SE', // Swedish Krona
    NZD: 'en-NZ', // New Zealand Dollar
    ZAR: 'en-ZA', // South African Rand
    RUB: 'ru-RU', // Russian Ruble
    BRL: 'pt-BR', // Brazilian Real
    MXN: 'es-MX'  // Mexican Peso
  };

  // Get the locale for the given currency code, default to 'en-US'
  const locale = locales[currencyCode] || 'en-US';

  // Convert cents to the main currency unit
  const amountInMainUnit = amount / 100;

  // Create a NumberFormat object
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits: 2, // Ensure 2 decimal places
    maximumFractionDigits: 2  // Ensure 2 decimal places
  });

  // Format the amount
  return formatter.format(amountInMainUnit);
};

const formatCurrencyWithSmallFraction = (amount, currencyCode) => {
  const formattedCurrency = formatCurrency(amount, currencyCode);

  // Match the main part and fractional part separately
  const [mainPart, fractionalPart] = formattedCurrency.split(/(\.\d{2}|\,\d{2})/);

  // Return the HTML string with the fractional part wrapped in <small>
  return `${mainPart}<small>${fractionalPart || ''}</small>`;
};

const fillTemplate = (data, template) => {
  // Function to replace placeholders with actual data
  function replacePlaceholder(match, key) {
    // Split the key by dot to handle nested keys
    const keys = key.split('.');
    // Use reduce to traverse the nested keys
    let value = data;
    for (let k of keys) {
      if (value[k] === undefined) {
        return match; // If key is not found, return the original placeholder
      }
      value = value[k];
    }
    return value;
  }

  // Use regex to find all placeholders and replace them with the corresponding values
  return template.replace(/{{\s*([^{}\s]+)\s*}}/g, replacePlaceholder);
}

const transformObject = (data, transformationStr) => {
  try {
    const transformedData = JSON.parse(JSON.stringify(data)); // Deep copy the data
    const transformations = parseTransformations(transformationStr);

    Object.keys(transformations).forEach(key => {
      const expression = transformations[key];
      const keys = key.split('.');
      let obj = transformedData;

      // Navigate to the nested object
      for (let i = 0; i < keys.length - 1; i++) {
        obj = obj[keys[i]];
      }

      // Evaluate the expression
      const lastKey = keys[keys.length - 1];
      obj[lastKey] = (new Function('data', 'return ' + expression))(transformedData);
    });

    return transformedData;
  } catch(err) {
    console.log(err)
    return data;
  }
}

function parseTransformations(transformationStr) {
  const transformations = {};
  const lines = transformationStr.split('\n');

  lines.forEach(line => {
    const [key, expression] = line.split(' = ');
    if (key && expression) {
      transformations[key.trim()] = expression.trim();
    }
  });

  return transformations;
}

export default {
  isServiceHours,
  formatCurrency,
  formatCurrencyWithSmallFraction,
  fillTemplate,
  transformObject
}