<template>
	<Layout>
		<div class="panel" ref="chatContainer">
			<div class="panel-content">
				<div class="content">
	        <UiHeadline size="l" style="padding: 0 24px;flex: none;">
	          Tasks
	        </UiHeadline>
	        <UiCard type="flat" footer-sticky head-sticky>
	          <template v-slot:head>
	            <UiMenuBar :items="menuItems" style="width:100%;"/>
	            <div style="width:100%;display: flex;justify-content: end;gap: 8px;">
	            	<UiButton color="outline" size="s">Create</UiButton>
	            </div>
	          </template>
        	</UiCard>
        	<Test></Test>

        	<div style="padding: 0 12px;flex: none;">
		        <div v-for="(tasks, column) in tasks">
		        	<div v-if="tasks.length" style="margin-bottom: 32px;">
			        	<UiHeadline size="s" style="margin-bottom: 8px;padding: 0 12px;text-transform: capitalize;">
				          {{ column }}
				        </UiHeadline>
				        <div v-if="tasks.length">
				        	<div v-for="task in tasks" :key="task.id" class="task-card" :class="{'task-expanded': expanded}" @click="onClick(task.id)">
				        		<div class="task-body">
				            	<div class="task-title">{{ task.title }}</div>
				            	<div class="task-description">{{ task.description }}</div>
				            	<div class="task-status" v-if="expanded"></div>
				            </div>
				            <div class="task-footer">
				              <div class="task-member" v-for="member in task.members">
				                <UiAvatar size="32px" :img-url="member.avatar_url" />
				              </div>
				            </div>
				          </div>
				        </div>
				        <div v-else>
				        	0 Tasks in {{ column }}
				        </div>
				      </div>
		        </div>
		       </div>
	      </div>
				<TaskBoard v-model="tasks" @click="onClick"/>
			</div>
		</div>
		<UiModal v-if="$route.params.id && task" @close="onClose" :title="task.title">
			<div>in {{ task.status }}</div>

			<UiButton color="outline" size="s" style="margin-top: 4px;">Edit</UiButton>

			<!-- Members -->
			<div style="margin-top: 32px;">
				<UiLabel style="font-size: 14px;margin-bottom: 8px;">Members</UiLabel>
				<div class="task-member" v-for="member in task.members">
          <UiAvatar size="32px" :img-url="member.avatar_url" />
        </div>
			</div>

			<!-- Description -->
			<div style="margin-top: 32px;">
				<UiLabel style="margin-bottom: 8px;">Description</UiLabel>
				<UiTextarea v-model="task.description" toggle-edit></UiTextarea>
			</div>

			<!-- Activity -->
			<div style="margin-top: 32px;">
				<UiLabel style="margin-bottom: 8px;">Activity</UiLabel>
			</div>
		</UiModal>
	</Layout>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRoute, useRouter } from "vue-router"
	import TaskBoard from "../components/TaskBoard"
	import Test from "../components/Test"

	const store = useStore()
	const route = useRoute()
	const router = useRouter()

	const tasks = computed(() => store.getters['virt/getTasksForTaskBoard'])
	const task = computed(() => store.getters['virt/getSelectedTask'])

	const expanded = ref(false)

	store.dispatch('virt/fetchTasks')

	const onClick = (id) => {
		if (typeof id === "number") router.push(`/tasks/${id}`)
	}

	const onClose = () => router.push('/tasks')

	watch(
    () => route.params.id, 
    (newId) => {
    	store.dispatch('virt/setTask', route.params.id)
    },{
    	immediate: true
    })

	const menuItems = [
    {
      key: 'file',
      name: 'File',
      icon: 'file',
      children: [
        {
          key: 'new',
          name: 'Export As',
          icon: 'plus',
          children: [
            { key: 'new-file', name: 'CSV', icon: 'file' },
            { key: 'new-folder', name: 'JSON', icon: 'folder' },
          ],
        },
        { key: 'open', name: 'Open', icon: 'folder-open' },
        { key: 'save', name: 'Save', icon: 'save' },
      ],
    },
    {
      key: 'edit',
      name: 'Edit',
      icon: 'edit',
      children: [
        { key: 'undo', name: 'Undo', icon: 'undo' },
        { key: 'redo', name: 'Redo', icon: 'redo' },
      ],
    }
  ]
</script>

<style scoped>
.task-card {
  background-color: #ffffff;
  padding: 10px;
  margin-bottom: 6px;
  border-radius: 5px;
  border: 1px solid #e8e8e8;
  border-radius: 10px;
  cursor: pointer;
/*  height: 56px;*/
/*  display: flex;*/
  justify-content: space-between;
  overflow: hidden;
}
.task-expanded {
	height: 96px;
	flex-flow: column;
}
.task-title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.task-body {
	width: 100%;
}
.task-description {
	white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  color: gray;
  width: 100%;
}
.task-footer {
  display: flex;
  justify-content: end;
  align-items: center;
}
.task-member {
	display: flex;
}
</style>