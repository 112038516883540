<template>
  <div v-click-outside="handleClickOutside">
    <transition name="slide-fade">
      <div
        v-if="isVisible"
        :style="{ width: modalWidth, transform: isPartiallyHidden ? 'translateX(75%)' : 'translateX(0)', opacity: isPartiallyHidden ? '0.25' : '1' }"
        class="modal"
        @mouseover="handleMouseOver"
        @mouseleave="handleMouseLeave"
      >
        <div class="modal-content">
          <button @mousedown.prevent="startResizing" class="resize-btn">Resize</button>
          <slot></slot>
          <button @click="closeModal" class="close-btn">Close</button>
        </div>
      </div>
    </transition>
    <button v-if="!isVisible" @click="openModal" class="open-btn">Pull</button>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import cookieService from '@lib/services/cookie';

export default {
  props: {
    width: {
      type: String,
      default: '300px',
    },
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isVisible = ref(true);
    const isPartiallyHidden = ref(false);
    const initialWidth = '300px';
    const resizing = ref(false);
    const startX = ref(0);
    const modalWidth = ref('300px');

    const closeModal = () => {
      isVisible.value = false;
    };

    const openModal = () => {
      isVisible.value = true;
      isPartiallyHidden.value = false;
    };

    const handleClickOutside = () => {
      if (isVisible.value) {
        isPartiallyHidden.value = true;
      }
    };

    const handleMouseOver = () => {
      if (isPartiallyHidden.value) {
        isPartiallyHidden.value = false;
      }
    };

    const handleMouseLeave = () => {
      if (isVisible.value) {
        isPartiallyHidden.value = true;
      }
    };

    const startResizing = (event) => {
      resizing.value = true;
      startX.value = event.clientX;
    };

    const handleMouseMove = (event) => {
      if (resizing.value) {
        const newWidth = parseInt(modalWidth.value) - (event.clientX - startX.value) + 'px';
        modalWidth.value = newWidth;
        startX.value = event.clientX;
        cookieService.set(props.id, newWidth);
      }
    };

    const stopResizing = () => {
      if (resizing.value) {
        resizing.value = false;
      }
    };

    onMounted(() => {
      const savedWidth = cookieService.get(props.id);
      if (savedWidth) {
        modalWidth.value = savedWidth;
      } else if (props.width) {
        modalWidth.value = props.width;
      } else {
        modalWidth.value = initialWidth;
      }
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', stopResizing);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', stopResizing);
    });

    return {
      isVisible,
      isPartiallyHidden,
      modalWidth,
      closeModal,
      openModal,
      handleClickOutside,
      handleMouseOver,
      handleMouseLeave,
      startResizing,
      stopResizing,
    };
  },
};
</script>

<style scoped>
.modal {
  position: fixed;
  bottom: 0;
  right: 0;
  height: calc(100vh - var(--navigation_height));
  padding: 20px;
  background: white;
  box-shadow: -4px 0 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.modal-content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  height: 100%;
  justify-content: space-between;
}

.resize-btn {
  position: absolute;
  top: 10px;
  left: 10px;
  padding: 5px 10px;
  background-color: #2196f3;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  z-index: 1001;
}

.close-btn {
  padding: 5px 10px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.open-btn {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  padding: 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
  z-index: 1000;
}

.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s ease;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
  transform: translateX(100%);
}
</style>
