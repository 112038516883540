<template>
  <div :class="['panel', containerClass]">
    <div class="panel-content">
      <!-- <UiSidebar :items="navItems" /> -->
      <div class="content">
        <UiHeadline size="l" style="padding: 0 24px;flex: none;">
          Manage {{ appKey }}
        </UiHeadline>
        <UiCard type="flat" footer-sticky head-sticky>
          <template v-slot:head>
            <UiMenuBar :items="menu" style="width:100%;" @click-item="onClickMenuItem"/>
            <div style="width:100%;display: flex;justify-content: end;">
              <UiButton color="outline" size="s" @click="onCreate">Create {{ appKey }}</UiButton>
            </div>
          </template>
          <!-- Search -->
          <div class="panel-search">
            <UiInput label="Search" @update:modelValue="searchTerm" @enter="onSearch"/>
            <UiButton color="outline" size="s">End Search</UiButton>
          </div>
          <!-- <UiMenuBar :items="filterItems" style="width:100%;"/> -->
          <div v-for="item in items" @click="onSelectItem(item.id)">
            <UiListItem>
              {{item}}
            </UiListItem>
          </div>
          <slot></slot>
          <template v-slot:footer>
            <UiPagination :current-page="currentPage" :total-pages="totalPages" :items-per-page="itemsPerPage" @update:currentPage="updateCurrentPage" @update:itemsPerPage="updateItemsPerPage"></UiPagination>
          </template>
        </UiCard>
      </div>
    </div>
  </div>

  <div class="side-modal-wrapper" v-if="showCreateForm && model">
    <div class="side-modal-blur"></div>
    <div class="side-modal">
      <div class="side-modal-head">
        <UiHeadline>
          <template v-if="model.name">
            <UiLabel>Edit</UiLabel>
            {{ model.name }}
          </template>
          <template v-else>
            Onboarding
            <UiLabel>Create {{ appKey }}</UiLabel>
          </template>
        </UiHeadline>
        <UiButton color="outline" size="s" @click="showCreateForm = false">Close</UiButton>
      </div>
      <div class="side-modal-body">
        <!-- Custom Component -->
        <Chat v-bind="model" v-if="$store.state.manage.component == 'Chat'"></Chat>

        <!-- Form -->
        <template v-else-if="formSchema">
          <UiButton color="outline" size="s" @click="isEditMode = true" v-if="model.id">Edit</UiButton>
          <FormGenerator :schema="formSchema" v-if="formSchema" v-model="model" :mode="isEditMode ? 'form' : 'view'"></FormGenerator>
          <div v-for="table in referenceTables">
            <UiHeadline size="s">{{ table.table_name }}</UiHeadline>
            <UiTable :data="table.items" :fieldLabels="fieldLabels"></UiTable>
          </div>
        </template>
      </div>
      <div class="side-modal-footer"v-if="model.id && isEditMode">
        <UiButton @click="onSubmit" :loading="submiting">
          Update
        </UiButton>
      </div>
      <div class="side-modal-footer" v-else-if="!model.id">
        <UiButton @click="onSubmit" :loading="submiting">
          Create
        </UiButton>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, watch } from 'vue';
import { useStore } from "vuex";
import { useRouter, useRoute } from 'vue-router';
import axios from "axios";
import { UiSidebar, UiCard, UiHeadline, UiNavigation, UiMenuBar, UiButton, UiPagination, UiInput } from '../../components/UI';
import FormGenerator from "@lib/components/functional/FormGenerator/FormGenerator"
import Chat from "@lib/components/functional/Chat/Chat"
import UserListItem from '../../components/functional/UserListItem/UserListItem';

const store = useStore()
const router = useRouter()
const route = useRoute()

const model = ref({

})

// let items = ref([])
let currentPage = ref(1)
let itemsPerPage = ref(25)
let searchTerm = ref('')
const submiting = ref(false)
const isEditMode = ref(false)

const appKey = computed(() => route.params.key);

const totalPages = computed(() => {
  return Math.ceil(totalItems.value / itemsPerPage.value)
})

const skipItems = computed(() => {
  return currentPage.value > 1 ? currentPage.value * itemsPerPage.value : 0
})

const items = computed(() => store.state.manage.items)
const totalItems = computed(() => store.state.manage.total)
const form = computed(() => store.state.manage.form)
const formSchema = computed(() => store.getters['manage/getFormSchema'])
const referenceTables = computed(() => store.getters['manage/getMappedTableData'])

const showCreateForm = ref(false)


const fieldLabels = {
  custom_field_1: "Order Number",
  custom_field_2: "Customer Name"
}

store.dispatch('manage/getForm', {
  key: appKey.value,
}).then(() => {}).catch(() => {})

const fetchItems = () => {
  store.dispatch('manage/fetchItems', {
    key: appKey.value,
    query: {
      skip: skipItems.value,
      limit: itemsPerPage.value,
      order_by: 'id',
      direction: 'asc'
    }
  }).then(() => {
    if (!model.value.id) {
      setItem(route.params.id)
    }
  }).catch(() => {

  })
}

const fetchItem = (id) => {
  store.dispatch('manage/fetchItem', {
    key: appKey.value,
    id
  }).then((res) => {
    model.value = res.data
  }).catch(() => {})
}

const getItemReferenceTables = (id) => {
  store.dispatch('manage/getItemReferenceTables', {
    key: appKey.value,
    id
  }).then((res) => {

  })
}

const onCreate = () => {
  showCreateForm.value = true
  model.value = {}
  isEditMode.value = true
  router.push(`/m/${route.params.key}`)
}

const onSubmit = () => {
  submiting.value = true
  if (model.value.id) {
    store.dispatch('manage/updateItem', {
      key: appKey.value,
      id: model.value.id,
      data: model.value
    }).then(() => {
      fetchItems()
      submiting.value = false
    })
  } else {
    store.dispatch('manage/createItem', {
      key: appKey.value,
      data: model.value
    }).then((res) => {
      model.value = res.data
      fetchItems()
      router.push(`/m/${route.params.key}/${model.value.id}`)
      submiting.value = false
    })
  }
}

const onSelectItem = (id) => {
  router.push(`/m/${route.params.key}/${id}`)
}

const setItem = (itemId) => {
  showCreateForm.value = true
  fetchItem(itemId)
  model.value = items.value.find(i => i.id == itemId)
  getItemReferenceTables(itemId)
}

watch(route, () => {
  const itemId = route.params.id

  if (itemId) {
    if (items.value.length) {
      setItem(itemId)
    }
  }
}, {
  immediate: true,
  deep: true 
})

watch(skipItems, () => {
  fetchItems()
}, {
  immediate: true 
})

const filterItems = computed(() => [
  {
    key: 'order',
    name: 'Order by: Name',
    icon: 'edit',
    children: [
      { key: 'undo', name: 'Undo', icon: 'undo' },
      { key: 'redo', name: 'Redo', icon: 'redo' },
    ],
  },
  {
    key: 'skip',
    name: 'Per page: 10',
    icon: 'edit',
    children: [
      { key: 'undo', name: 'Undo', icon: 'undo' },
      { key: 'redo', name: 'Redo', icon: 'redo' },
    ],
  },
])

const props = defineProps({
  title: String,
  display: {
    type: String,
    default: 'list'
  },
  navItems: {
    type: Array,
    required: true,
  },
  userAvatar: {
    type: String,
    required: true,
  },
  containerSize: {
    type: String,
    default: 'full',
    validator: (value) => ['full', 'xl', 'm', 's'].includes(value),
  },
  fixed: {
    type: Boolean,
    default: false,
  },
  menu: Array,
  totalPages: Number,
});

const containerClass = computed(() => {
  return {
    'container-full': props.containerSize === 'full',
    'container-xl': props.containerSize === 'xl',
    'container-m': props.containerSize === 'm',
    'container-s': props.containerSize === 's',
  };
});

const updateCurrentPage = (page) => {
  currentPage.value = page;
}

const updateItemsPerPage = (items) => {
  itemsPerPage.value = items
}

const onSearch = (searchTerm) => {
  watch(skipItems, () => {
    store.dispatch('manage/search', {
      key: 'contacts',
      query: {
        skip: skipItems.value,
        limit: itemsPerPage.value,
        query: searchTerm,
      }
    })
  }, {
    immediate: true 
  })
}

const onClickMenuItem = (key) => {
  console.log(key)
}
</script>

<style scoped>
.panel {
  position: fixed;
  top: var(--navigation_height);
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navigation_height));
  overflow: auto;
}

.panel-content {
  display: flex;
  flex: 1;
  margin: auto;
  height: 100%;
}

.container-full {
  width: 100%;
}

.container-xl {
  max-width: 1200px;
  margin: 0 auto;
}

.container-m {
  max-width: 800px;
  margin: 0 auto;
}

.container-s {
  max-width: 600px;
  margin: 0 auto;
}

.content {
  width: 100%;
  padding: 20px;
  margin: auto;
  max-width: 960px;
  height: 100%;
}

.panel .sidebar {
  position: fixed;
  top: 74px;
  left: 0;
  z-index: 128;
  height: calc(100vh - 74px);
}

.panel-search {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: end;
  margin-bottom: 16px;
}

.side-modal-wrapper {
  z-index: 256;
  height: 100vh;
  overflow: auto;
  padding: 16px;
  margin: auto;
  width: 100%;
  display: flex;
  justify-content: center;
}

.side-modal-blur {
  filter: blur(1px);
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: rgb(255 255 255 / 80%);
  z-index: 256;
  backdrop-filter: blur(10px);
}

.side-modal {
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
  border: 1px solid rgb(231, 231, 231);
  border-radius: 24px;
  top: 0;
  right: 0;
  z-index: 256;
  background: #fff;
  width: 100%;
  max-width: 720px;
  overflow: auto;
}

.side-modal-head {
  position: absolute;
  display: flex;
  justify-content: space-between;
  align-items: center;
  top: 0;
  background: #fff;
  border-bottom: 1px solid #e7e7e7;
  padding: 32px;
  z-index: 1;
  width: 100%;
}

.side-modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  bottom: 0;
  background: #fff;
  border-top: 1px solid #e7e7e7;
  padding: 16px 32px 32px;
}

.side-modal-body {
  position: relative;
  padding: 114px 0px 0px;
  height: 100%;
}

@media (min-width: 768px) {
  .panel {
    flex-direction: row;
  }
}
</style>
