<template>
	<div class="thumbnails">
		<slot></slot>
	</div>
</template>

<style scoped>
.thumbnails {
	overflow: auto;
	flex: none;
	gap: .5rem;
	display: flex;
	flex-flow: column;
}
</style>