<template>
  <div :style="`height:${height};`">
    <canvas ref="canvas"></canvas>
  </div>
  <div style="display: flex;gap: 8px;align-items: center; padding: 10px; cursor: pointer;width: fit-content;" v-if="false">
    <div v-for="data in dataValues" style="display:flex;gap: 4px;align-items: center;">
      <div v-if="data.borderDash" style="display:flex;gap: 2px;">
        <div :style="`height: 4px;width: 7px;background: ${data.backgroundColor};display:block;border-radius:999px;`"></div>
        <div :style="`height: 4px;width: 7px;background: ${data.backgroundColor};display:block;border-radius:999px;`"></div>
      </div>
      <div :style="`height: 4px;width: 16px;background: ${data.backgroundColor};display:block;border-radius:999px;`" v-else></div>
      <div style="font-weight: 500;">{{ data.label }}</div>
    </div>
  </div>
</template>

<script setup>
  import { ref, onMounted, onUnmounted, watchEffect, watch } from 'vue';
  import { Chart, registerables } from 'chart.js';
  import 'chartjs-adapter-moment';

  const props = defineProps({
    dataValues: {
      type: Array,
      required: true
    },
    labels: {
      type: Array,
      required: true
    },
    options: {
      type: Object,
      required: false
    },
    height: {
      type: String,
      default: '200px'
    }
  });

  let chart = null;
  const canvas = ref(null);

  Chart.register(...registerables);

  const createChart = () => {
    if (!canvas.value) return;
    const ctx = canvas.value.getContext('2d');

    // Convert data values to absolute values and determine colors
    const positiveValues = props.dataValues.map(value => Math.abs(value));
    const backgroundColors = props.dataValues.map(value => value >= 0 ? 'lightgray' : 'gray');

    chart = new Chart(ctx, {
      type: 'line',
      data: {
        labels: props.labels,
        datasets: props.dataValues
      },
      options: props.options
    });

    chart.resize();
  };

  const handleResize = () => {
    if (chart) {
      chart.resize();
    }
  };

  onMounted(() => {
    setTimeout(() => {
      createChart()
    }, 300)
  	window.addEventListener('resize', handleResize);
  })

  onUnmounted(() => {
    window.removeEventListener('resize', handleResize);
  });

  createChart()

  console.log(props.dataValues)

  watchEffect(() => {
  	handleResize()
  });
</script>

<style scoped>
	canvas {
	  width: 100% !important;
	  height: 100% !important;
	}
</style>