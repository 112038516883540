<template>
	<div :class="classes">
    <span class="ui-status-inner"></span>
  </div>
</template>

<script setup>
	import { computed, inject } from "vue"

	const props = defineProps({
		type: {
			type: String,
			default: "success"
		},
		size: {
			type: String,
			default: "m"
		},
	})

	const classes = computed(() => [
		"ui-status",
		`ui-status-${props.type}`,
		`ui-status-${props.size}`
	])

	const t = inject('$t');
</script>

<style setup>
	.ui-status {
		width: fit-content;
		display: flex;
		justify-content: center;
		align-items: center;
		border-radius: 6px;
		background: #87F57D;
		color: #000;
		font-size: 14px;
		height: 16px;
		width: 16px;
	}

	.ui-status span {
		font-size: 16px;
		font-weight: 500;
		line-height: normal;
		letter-spacing: -.5px;
	}

	.ui-status-danger {
		background: var(--danger-color);
		color: var(--danger-font-color);
	}

	.ui-status-success {
		background: var(--success-color);
		color: var(--success-font-color);
	}

	.ui-status-warning {
		background: var(--warning-color);
		color: var(--warning-font-color);
	}

	.ui-status-info {
		background: var(--info-color);
		color: var(--info-font-color);
	}

	.ui-status-primary {
		background: var(--primary-color);
		color: var(--primary-font-color);
	}

	.ui-status-gray {
		background: var(--gray-color);
		color: var(--gray-font-color);
	}

	.ui-status-white {
		background: #fff;
		color: #000;
	}

	.ui-status-s {
		height: 24px;
		padding: 0 6px;
	}

	.ui-status-s span {
		font-size: 14px;
	}

	.ui-status-inner {
		height: 5px;
		width: 5px;
		border-radius: 999px;
		background: #87F57D;
	}

	.ui-status-success .ui-status-inner {
		background: var(--success-font-color);
	}

	.ui-status-warning .ui-status-inner {
		background: var(--warning-font-color);
	}

	.ui-status-danger .ui-status-inner {
		background: var(--danger-font-color);
	}

	.ui-status-info .ui-status-inner {
		background: var(--info-font-color);
	}

	.ui-status-primary .ui-status-inner {
		background: var(--primary-font-color);
	}

	.ui-status-gray .ui-status-inner {
		background: var(--gray-font-color);
	}

	/*.ui-status-danger span {
		color: #930e1e;
	}

	.ui-status-success span {
		color: #218f16;
	}*/
</style>