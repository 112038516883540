<template>
  <div class="spinner" style="color: rgb(0, 0, 0);  font-size: 17px; font-style: normal; font-weight: 500; line-height: normal;" v-if="show">
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 32 32" fill="none" v-if="syncing">
		<path d="M3 3V11.125H3.94497M28.8994 14.375C28.0998 7.96224 22.6294 3 16 3C10.5443 3 5.87347 6.36078 3.94497 11.125M3.94497 11.125H11.125M29 29V20.875H28.055M28.055 20.875C26.1265 25.6392 21.4557 29 16 29C9.37062 29 3.90024 24.0378 3.10058 17.625M28.055 20.875H20.875" stroke="#111827" stroke-width="2.67" stroke-linecap="round" stroke-linejoin="round"/>
	</svg>
	<div v-if="status">
	 {{ status }}
	</div>
	<div style="display: flex;justify-content: center;align-items: center;gap: 4px;" v-if="!syncing && completionStatus">
	 <IconCheck color="#87F57D"></IconCheck>
	 {{ completionStatus }}
	</div>
  </div>
</template>

<script setup>
	import { ref, watch, defineEmits } from 'vue'
	import IconCheck from '../icons/IconCheck'

	const show = ref(false)

	const props = defineProps({
		syncing: Boolean,
		status: String,
		hideAfter: {
			type: Number,
			default: 3000
		},
		completionStatus: {
			type: String,
			default: ''
		}
	})

	const emit = defineEmits(['completed'])

	watch(() => props.syncing, (oldValue, newValue) => {
		if (props.syncing == false) {
			setTimeout(() => {
				emit('completed')
				show.value = false
			}, props.hideAfter)
		} else {
			show.value = true
		}
	}, {
		immediate: true
	})
</script>

<style scoped>
.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  height: 24px;
}

.spinner > svg {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
