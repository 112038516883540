import _ from 'lodash';
import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";

const Api = {
  fetchItems(payload) {
    return http.get(`/${payload.key}`, {
      params: payload.query
    });
  },
  fetchItem(payload) {
    return http.get(`/${payload.key}/${payload.id}`);
  },
  search(payload) {
    return http.get(`/${payload.key}-search`, {
      params: payload.query
    });
  },
  getForm(payload) {
    return http.get(`/form-schemas/by_key/base_${payload.key}`);
  },
  createItem(payload) {
    return http.post(`/${payload.key}`, payload.data);
  },
  updateItem(payload) {
    return http.put(`/${payload.key}/${payload.id}`, payload.data);
  },
  getItemReferenceTables(payload) {
    return http.get(`/custom-tables-by-reference?reference_type=${payload.key}&reference_id=${payload.id}`);
  },
};

const manage = {
  namespaced: true,
  state: {
    items: [],
    total: 0,
    form: null,
    referenceTables: [],
    component: null
  },
  getters: {
    getFormSchema: (state) => {
      return state.form ? state.form.form_schema : null
    },
    getMappedTableData: (state) => {
      return _.chain(state.referenceTables)
        .groupBy('table_name')
        .map((items, table_name) => ({
          table_name,
          items: items.map(item => ({
            id: item.id,
            table_id: item.table_id,
            data: item.data,
            reference_type: item.reference_type,
            reference_id: item.reference_id,
            created_at: item.created_at,
            user_id: item.user_id
          }))
        }))
        .value();
    }
  },
  mutations: {
    updateItems: (state, payload) => {
      state.items = payload
    },
    updateTotal: (state, payload) => {
      state.total = payload
    },
    setForm: (state, payload) => {
      state.form = payload
    },
    updateRefernceTables: (state, payload) => {
      state.referenceTables = payload
    },
    setComponent: (state, payload) => {
      state.component = payload
    },
  },
  actions: {
    fetchItems: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchItems(payload)
          .then((res) => {
            state.commit("updateItems", res.data.items);
            state.commit("updateTotal", res.data.total);
            state.commit("setComponent", res.data.component);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchItem: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchItem(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    search: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.search(payload)
          .then((res) => {
            state.commit("updateItems", res.data.items);
            state.commit("updateTotal", res.data.total);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getForm: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getForm(payload)
          .then((res) => {
            state.commit("setForm", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createItem: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.createItem(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateItem: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.updateItem(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getItemReferenceTables: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getItemReferenceTables(payload)
          .then((res) => {
            state.commit("updateRefernceTables", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  },
};

export default manage;