<template>
	<div :class="classes">
		<slot name="icon"></slot>
		<div class="ui-alert-content">
			<div class="ui-alert-title"><slot name="title" v-if="$slots.title"></slot></div>
			<div class="ui-alert-text"><slot name="text"></slot></div>
		</div>
	</div>
</template>

<script setup>
import { computed } from "vue"

const props = defineProps({
	color: {
		type: String,
		default: 'primary'
	},
	size: {
		type: String,
		default: 'medium', // default size is medium
		validator: value => ['small', 'medium', 'large'].includes(value)
	}
})

const classes = computed(() => [
	'ui-alert',
	`ui-alert-${props.color}`,
	`ui-alert-${props.size}`
])
</script>

<style scoped>
.ui-alert-ligher-pastel {
	--primary-color: #dce7ff;
	--info-color: #e4ecff;
	--success-color: #eaf8e6;
	--warning-color: #fffdf0;
	--danger-color: #ffecec;
	--gray-color: #f5f5f5;
	--blank-color: #fefefe;
}

.ui-alert {
	padding: 16px 32px;
	border-radius: 12px;
	font-weight: 600;
	line-height: 20px;
	letter-spacing: -0.21px;
	font-size: 16px;
	display: flex;
	align-items: center;
}

.ui-alert-small {
	padding: 8px 16px;
	font-size: 14px;
}

.ui-alert-medium {
	padding: 16px 32px;
	font-size: 16px;
}

.ui-alert-large {
	padding: 24px 48px;
	font-size: 18px;
}

.ui-alert-primary {
	background: var(--primary-color);
	color: var(--primary-font-color);
}

.ui-alert-info {
	background: var(--info-color);
	color: var(--info-font-color);
}

.ui-alert-success {
	background: var(--success-color);
	color: var(--success-font-color);
}

.ui-alert-warning {
	background: var(--warning-color);
	color: var(--warning-font-color);
}

.ui-alert-danger {
	background: var(--danger-color);
	color: var(--danger-font-color);
}

.ui-alert-gray {
	background: var(--gray-color);
	color: var(--gray-font-color);
}

.ui-alert-blank {
	background: var(--blank-color);
	color: var(--blank-font-color);
}

.ui-alert-content {
	display: flex;
	flex-direction: column;
	margin-left: 16px;
}

.ui-alert-content ::slotted(.title) {
	font-size: 1.25em;
	margin-bottom: 4px;
}

.ui-alert-content ::slotted(.text) {
	font-size: 1em;
}

.ui-alert-title {
	font-weight: 600;
}

.ui-alert-text {
	font-weight: 500;
}
</style>
