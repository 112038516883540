// main.js
/*
Todo:
Improve smart guides, they are not really smooth at the moment, mainly the getSnappingOffsets function needs improvement
*/

import {
    generateUniqueColor,
    distance,
    findClosestSideCenter
} from './helpers';

import SocketHandler from './socket';

import { drawRect, drawTextRect, drawFrameRect, drawGroupRect, drawCard } from './utils';

class CanvasApp {
    constructor(el, options) {
        this.canvas = el;
        this.ctx = this.canvas.getContext('2d');
        this.dpr = window.devicePixelRatio || 1;

        // Create hit canvas
        this.hitCanvas = document.getElementById('hitCanvas');
        if (!this.hitCanvas) {
            this.hitCanvas = document.createElement('canvas');
            this.hitCanvas.id = 'hitCanvas';
            this.hitCtx = this.hitCanvas.getContext('2d');
            this.hitCanvas.style.display = 'none';
            this.hitCanvas.style.position = 'absolute';
            this.hitCanvas.style.opacity = 0.5;
            this.hitCanvas.style.pointerEvents = 'none';
            document.body.appendChild(this.hitCanvas);
        } else {
            this.hitCtx = this.hitCanvas.getContext('2d');
        }

        const defaultConfig = {
            onlySnapOnShiftPressed: true,
            areSmartGuidesEnabled: true,
            editTextOnCanvas: false,
            isMovementRestricted: false,
            restrictScrolling: false,
            restrictScrollX: false,
            restrictScrollY: false,
            centerFrameRect: true,
            centerScrollOnFrame: false,
            isCrossVisible: false
        }

        const defaultCanvasProps = {
            backgroundColor: "#ffffff"
        }

        this.hitCanvas.width = this.canvas.width;
        this.hitCanvas.height = this.canvas.height;
        this.hitLayerVisible = false;

        this.options = options;
        this.socketHandler = new SocketHandler(options, this);
        this.events = {};
        this.items = options.items;
        this.rectangles = [];
        this.lines = [];
        this.isDrawingLine = false;
        this.isDrawMode = true;
        this.isMoving = true;
        this.isPanning = false;
        this.isGridVisible = false;
        this.selectedRectangle = null;
        this.selectedRectangles = [];
        this.selectedRectIndex = null;
        this.startX = 0;
        this.startY = 0;
        this.panStartX = 0;
        this.panStartY = 0;
        this.offsetX = 0;
        this.offsetY = 0;
        this.zoomLevel = 1;
        this.isSnapToGridEnabled = false;
        this.gridSize = 10;
        this.imageCache = new Map();
        this.isSpacebarPressed = false;
        this.hoveredRectangle = null;
        this.lastHoveredRectangle = null;
        this.holdingRectangle = null;
        this.selectedRectId = null;
        this.history = [];
        this.historyIndex = -1;
        this.topCircle = null;
        this.rightCircle = null;
        this.bottomCircle = null;
        this.leftCircle = null;
        this.showUserNames = false;
        this.isBoundingBoxVisible = false;
        this.handles = [];
        this.resizingRect = null;
        this.resizeHandle = null;
        this.localStorageEnabled = false;
        this.config = { ...defaultConfig };

        if (options.config) {
            this.config = {
                ...this.config,
                ...options.config
            }
        }

        this.RECT_WIDTH = 120;
        this.RECT_HEIGHT = 60;
        this.LINE_SPACING = this.RECT_WIDTH * 1.75;
        this.xSpacing = this.LINE_SPACING;
        this.ySpacing = this.LINE_SPACING;
        this.rectCenterX = ((this.canvas.width / 2 - this.offsetX) / this.zoomLevel - this.RECT_WIDTH / 2) / this.dpr;
        this.rectCenterY = ((this.canvas.height / 2 - this.offsetY) / this.zoomLevel - this.RECT_HEIGHT / 2) / this.dpr;

        this.cursorSize = "2 2";
        this.cursorRedDefault = 'https://virt-ai.com/pointer_b_red.svg';
        this.cursorGreenDefault = 'https://virt-ai.com/pointer_b_green.svg';
        this.cursorBlueDefault = 'https://virt-ai.com/pointer_b_blue.svg';

        this.cursorStyles = [
            this.cursorRedDefault,
            this.cursorGreenDefault,
            this.cursorBlueDefault
        ];

        this.cursorStyles.forEach(style => {
            const img = new Image();
            img.src = style;
            this.imageCache.set(style, img);
        });

        this.cursorDefault = `url('https://virt-ai.com/pointer_b.svg') ${this.cursorSize}, auto`
        this.cursorGrab = `url('https://virt-ai.com/hand_small_open.svg') ${this.cursorSize}, auto`
        this.cursorGrabbing = `url('https://virt-ai.com/hand_small_closed.svg') ${this.cursorSize}, auto`

        this.userCursors = {
            'user1': this.cursorRedDefault,
            'user2': this.cursorGreenDefault,
            'user3': this.cursorBlueDefault,
        };

        this.otherCursors = {};

        this.initEventListeners();
        this.initCircles();
        this.resizeCanvas();
        this.updateDataTextarea();

        const savedData = localStorage.getItem('canvasData');
        if (this.localStorageEnabled) {
            if (savedData) {
                const parsedData = JSON.parse(savedData);
                this.rectangles = parsedData.rectangles || [];
                this.lines = parsedData.lines || [];
                this.offsetX = parsedData.offsetX || 0;
                this.offsetY = parsedData.offsetY || 0;
                this.zoomLevel = parsedData.zoomLevel || 1;

                this.trigger('set_zoom_level', this.zoomLevel);
            }
        }

        this.availableElements = {
            "rect": {
                width: 128,
                height: 128
            },
            "card": {
                width: 120,
                height: 60
            },
        }
    }

    initEventListeners() {
        this.canvas.addEventListener('mousedown', (e) => this.onMouseDown(e));
        this.canvas.addEventListener('mousemove', (e) => this.onMouseMove(e));
        this.canvas.addEventListener('mouseup', (e) => this.onMouseUp(e));
        this.canvas.addEventListener('wheel', (e) => this.onWheel(e), { passive: false });
        window.addEventListener('resize', () => this.resizeCanvas());

        document.addEventListener('keydown', (e) => this.onKeyDown(e));
        document.addEventListener('keyup', (e) => this.onKeyUp(e));

        document.getElementById('connectButton').addEventListener('click', () => this.connectRectangles());
        document.getElementById('toggleSnap').addEventListener('click', () => this.toggleSnap());

        this.canvas.addEventListener('dblclick', (e) => this.onDoubleClick(e));
        this.canvas.addEventListener('click', (e) => this.onClick(e));

        this.canvas.addEventListener('dragover', (e) => this.onDragOver(e));
        this.canvas.addEventListener('drop', (e) => this.onDrop(e));
    }

    // drawOtherCursors() {
    //     this.ctx.save();
    //     for (let id in this.otherCursors) {

    //         const pos = this.otherCursors[id];
    //         const cursorIndex = this.userCursorIndex % this.cursorStyles.length;
    //         const cursorStyle = this.cursorStyles[cursorIndex] || this.cursorDefault;

    //         // Use cached image to draw cursor
    //         const img = this.imageCache.get(this.cursorRedDefault);
    //         if (img) {
    //             this.ctx.drawImage(img, pos.x - 10, pos.y - 10, 14, 18); // Adjust as necessary for cursor size
    //         }
    //     }
    //     this.ctx.restore();
    // }

    centerScrollPosition() {
        const centerX = (this.canvas.width - (window.innerWidth * this.dpr)) / 2;
        const centerY = (this.canvas.height - (window.innerHeight * this.dpr)) / 2;
        this.offsetX = centerX / this.zoomLevel;
        this.offsetY = centerY / this.zoomLevel;
        this.redrawCanvas();
    }

    drawOtherCursors() {
        this.ctx.save();
        for (let id in this.otherCursors) {
            const pos = this.otherCursors[id];

            // Adjust cursor position based on canvas offset and zoom level
            const adjustedX = (pos.x * this.zoomLevel) + this.offsetX;
            const adjustedY = (pos.y * this.zoomLevel) + this.offsetY;

            // Use cached image to draw cursor
            const img = this.imageCache.get(this.cursorRedDefault);
            if (img) {
                this.ctx.drawImage(img, adjustedX - 10, adjustedY - 10, 13, 17); // Adjust as necessary for cursor size

                // Draw the user's name beside the cursor if visibility is enabled
                if (this.showUserNames) {
                    const userName = id; // Replace this with actual user name retrieval if needed
                    this.ctx.font = '12px Arial';
                    this.ctx.fillStyle = 'black';
                    this.ctx.fillText(userName, adjustedX + 12, adjustedY);
                }
            }
        }
        this.ctx.restore();
    }

    resizeCanvas() {
        this.dpr = window.devicePixelRatio || 1;
        this.rectCenterX = ((this.canvas.width / 2 - this.offsetX) / this.zoomLevel - this.RECT_WIDTH / 2) / this.dpr;
        this.rectCenterY = ((this.canvas.height / 2 - this.offsetY) / this.zoomLevel - this.RECT_HEIGHT / 2) / this.dpr;

        this.canvas.width = window.innerWidth * this.dpr;
        this.canvas.height = window.innerHeight * this.dpr;
        this.canvas.style.width = `${window.innerWidth}px`;
        this.canvas.style.height = `${window.innerHeight}px`;
        this.ctx.scale(this.dpr, this.dpr);

        this.hitCanvas.width = this.canvas.width;
        this.hitCanvas.height = this.canvas.height;
        this.hitCanvas.style.width = `${window.innerWidth}px`;
        this.hitCanvas.style.height = `${window.innerHeight}px`;
        this.hitCtx.scale(this.dpr, this.dpr);

        this.redrawCanvas();

        if (this.config.centerScrollOnFrame) {
            this.centerScrollPosition();
        }
    }

    onKeyDown(e) {
        if (e.code === 'Space') {
            this.isSpacebarPressed = true;
            this.canvas.style.cursor = this.cursorGrab;
        }
        if (e.code === 'Escape') {
            this.isDrawingLine = false
            this.selectedRectIndex = null;
            this.holdingRectangle = null;
            this.redrawCanvas();
        }
        if ((e.ctrlKey || e.metaKey) && e.key === 's') {
            e.preventDefault();
            this.trigger('save');
            this.save();
        }
    }

    onKeyUp(e) {
        if (e.code === 'Space') {
            this.isSpacebarPressed = false;
            this.canvas.style.cursor = this.cursorDefault;
        }
    }

    createRectangle(x = this.rectCenterX, y = this.rectCenterY, type = "rect") {
        const rectId = Date.now().toString();
        const newRectangle = {
            id: rectId,
            sequence_id: rectId,
            x: x,
            y: y,
            width: this.availableElements[type].width,
            height: this.availableElements[type].height,
            backgroundColor: "#f4f6f9",
            type: type,
            key: "send_email",
            title: 'Title',
            subtitle: 'Subtitle',
            displayData: "",
            // imageUrl: 'https://virt-ai.com/assets/img/jai.png'
        };
        this.rectangles.push(newRectangle);

        this.redrawCanvas();

        this.socketHandler.addRectangle(newRectangle);

        this.trigger('add_rect', newRectangle);

        this.save()

        return newRectangle
    }

    createTextRectangle(x = this.rectCenterX, y = this.rectCenterY, content = "New Text") {
        const rectId = Date.now().toString();
        const newTextRectangle = {
            id: rectId,
            sequence_id: rectId,
            x: x,
            y: y,
            width: 200, // Default width
            height: 100, // Default height
            type: 'text',
            content: content,
            fontFamily: 'Cera',
            fontSize: 16,
            fontStyle: 'normal',
            fontWeight: 'normal',
            color: '#000000',
            letterSpacing: 0,
            lineHeight: 1.5,
            textDecoration: 'none',
            horizontalAlign: 'left',
            verticalAlign: 'top',
            resizeMode: 'autoWidth',
            truncate: false,
            horizontalResizing: 'hugContents',
            verticalResizing: 'hugContents',
        };

        this.rectangles.push(newTextRectangle);
        this.redrawCanvas();

        this.socketHandler.addRectangle(newTextRectangle);

        this.trigger('add_rect', newTextRectangle);

        this.save();

        return newTextRectangle;
    }

    createFrameRectangle(x = this.rectCenterX, y = this.rectCenterY, width = 400, height = 300) {
        const rectId = Date.now().toString();
        
        const newFrameRectangle = {
            id: rectId,
            sequence_id: rectId,
            x: x,
            y: y,
            width: width,
            height: height,
            type: 'frame',
            elements: []
        };

        this.rectangles.push(newFrameRectangle);
        this.redrawCanvas();

        this.socketHandler.addRectangle(newFrameRectangle);

        this.trigger('add_rect', newFrameRectangle);

        this.save();

        if (this.config.centerScrollOnFrame) {
            this.centerScrollPosition();
        }

        return newFrameRectangle;
    }

    createGroupRectangle(x = this.rectCenterX, y = this.rectCenterY, width = 300, height = 200) {
        const rectId = Date.now().toString();
        const newGroupRectangle = {
            id: rectId,
            sequence_id: rectId,
            x: x,
            y: y,
            width: width,
            height: height,
            type: 'group',
            elements: []
        };

        this.rectangles.push(newGroupRectangle);
        this.redrawCanvas();

        this.socketHandler.addRectangle(newGroupRectangle);

        this.trigger('add_rect', newGroupRectangle);

        this.save();

        return newGroupRectangle;
    }

    createCard(x = this.rectCenterX, y = this.rectCenterY, type = "card") {
        const rectId = Date.now().toString();
        const newRectangle = {
            id: rectId,
            sequence_id: rectId,
            x: x,
            y: y,
            width: this.availableElements[type].width,
            height: this.availableElements[type].height,
            backgroundColor: "#f4f6f9",
            type: type,
            key: "send_email",
            title: 'Title',
            subtitle: 'Subtitle',
            displayData: "",
            // imageUrl: 'https://virt-ai.com/assets/img/jai.png'
        };
        this.rectangles.push(newRectangle);

        this.redrawCanvas();

        this.socketHandler.addRectangle(newRectangle);

        this.trigger('add_rect', newRectangle);

        this.save()

        return newRectangle
    }

    toggleMode() {
        this.isDrawMode = !this.isDrawMode;
        this.isGridVisible = this.isDrawMode;
        this.config.isCrossVisible  = this.isDrawMode;
        // const toggleButton = document.getElementById('toggleMode');
        // if (this.isDrawMode) {
        //     toggleButton.classList.add('active');
        // } else {
        //     toggleButton.classList.remove('active');
        // }
        this.redrawCanvas();
    }

    enableEditMode() {
        this.isDrawMode = true
        this.isGridVisible = true;
        this.config.isCrossVisible  = true;
    }

    onMouseDown(e) {
        if (this.config.isMovementRestricted) return; // Prevent movement if restricted
        
        const mousePos = this.getMousePos(e);
        const rectIndex = this.findRectIndexAt(mousePos);
        const isShiftPressed = e.shiftKey;

        const hitElementColor = this.getHitElement(mousePos);

        if (hitElementColor) {
            const handleInfo = this.getHandleInfoFromColor(hitElementColor);
            const handle = this.handles.find(h => h.id == hitElementColor)
            if (handle) {
                this.resizingRect = this.rectangles.find(r => r.sequence_id === handle.rectId);
                this.resizeHandle = handle.handleType;

                if (this.resizingRect && this.resizeHandle) {
                    return;
                }
            }
        }

        if (isShiftPressed && rectIndex !== -1) {
            const rect = this.rectangles[rectIndex];
            if (!this.selectedRectangles.includes(rect)) {
                this.selectedRectangles.push(rect);
            }
        } else {
            this.selectedRectangles = [];
            if (rectIndex !== -1) {
                this.holdingRectangle = this.rectangles[rectIndex];
                this.selectedRectangle = this.rectangles[rectIndex];
                this.selectedRectIndex = rectIndex;
                this.startX = (mousePos.x - this.offsetX) / this.zoomLevel;
                this.startY = (mousePos.y - this.offsetY) / this.zoomLevel;
                this.canvas.style.cursor = this.cursorGrabbing;
                this.isMoving = true;
                this.selectedRectangles.push(this.holdingRectangle);
            } else {
                this.holdingRectangle = null;
                this.selectedRectangle = null;
                this.canvas.style.cursor = this.cursorDefault;
            }
        }

        if (this.isDrawMode && rectIndex !== -1) {
            this.holdingRectangle = this.rectangles[rectIndex];
            this.selectedRectangle = this.rectangles[rectIndex];
            this.selectedRectIndex = rectIndex;
            this.startX = (mousePos.x - this.offsetX) / this.zoomLevel;
            this.startY = (mousePos.y - this.offsetY) / this.zoomLevel;
            this.canvas.style.cursor = this.cursorGrabbing;
            this.isMoving = true;
        }

        if (this.holdingRectangle) this.trigger('on_hold_rect');

        if (e.button === 1 || this.isSpacebarPressed) {
            this.startPanning(e);
        } else if (this.isDrawMode) {
            if (isShiftPressed || this.isDrawingLine) {
                this.isDrawingLine = true;
                this.handleDrawMode(mousePos);
            } else {
                this.startMovingRectangle(mousePos);
            }
        } else {
            this.drawingFromCircle = null;
        }

        if (this.historyIndex < this.history.length - 1) {
            this.history.splice(this.historyIndex + 1);
        }

        this.redrawCanvas();
        this.save();
    }

    onMouseUp(e) {
        const mousePos = this.getMousePos(e);
        this.isMoving = false;
        this.holdingRectangle = null;

        if (this.resizingRect) {
            this.resizingRect = null;
            this.resizeHandle = null;

            this.trigger('resize_rect_end');
            return;
        }

        this.holdingRectangle = null;
        this.canvas.style.cursor = this.cursorDefault;
        this.trigger('on_hold_rect_end');
        this.trigger('on_move_rect_end');

        if (this.isDrawMode) {
            if (this.selectedRectangle) {
                if (!this.isSnapToGridEnabled && !this.isMoving) {
                    this.showCircles(this.selectedRectangle);
                } else {
                    this.hideCircles();
                }
            } else {
                this.hideCircles();
            }
        }

        this.isDrawingLine = false;
        this.selectedRectIndex = null;

        if (this.isPanning) {
            this.isPanning = false;
            this.canvas.style.cursor = this.isSpacebarPressed ? this.cursorGrab : this.cursorDefault;
        } else if (this.isMoving) {
            this.isMoving = false;
            this.selectedRectIndex = null;
        }

        this.handle_selected_rects_on_frame(mousePos);

        this.redrawCanvas();
        this.save();

        this.trigger('mouse_up');
    }

    onMouseMove(e) {
        const mousePos = this.getMousePos(e);
        const isShiftPressed = e.shiftKey;

        this.socketHandler.sendCursorPosition(mousePos);

        if (this.resizingRect && this.resizeHandle) {
            this.resizeRectangle(this.resizingRect, mousePos, this.resizeHandle);
            this.redrawCanvas();
            return;
        }

        if (this.holdingRectangle) this.trigger('on_move_rect', this.holdingRectangle);

        if (this.isPanning) {
            if (this.config.restrictScrolling) return; // Prevent panning if restricted
            if (!this.config.restrictScrollX) {
                this.offsetX += (e.clientX - this.panStartX);
            }
            if (!this.config.restrictScrollY) {
                this.offsetY += (e.clientY - this.panStartY);
            }
            this.panStartX = e.clientX;
            this.panStartY = e.clientY;

            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.redrawCanvas();
        } else if (this.isMoving && this.holdingRectangle) {
            if (this.config.isMovementRestricted) return; // Prevent movement if restricted

            this.hideCircles();
            let newX = (mousePos.x - this.offsetX) / this.zoomLevel - this.startX;
            let newY = (mousePos.y - this.offsetY) / this.zoomLevel - this.startY;

            if (this.isSnapToGridEnabled) {
                newX = Math.round(newX / this.gridSize) * this.gridSize;
                newY = Math.round(newY / this.gridSize) * this.gridSize;
            }

            let snappingOffsets = null;

            if (isShiftPressed) {
                snappingOffsets = this.getSnappingOffsets({
                    x: this.holdingRectangle.x + newX,
                    y: this.holdingRectangle.y + newY,
                    width: this.holdingRectangle.width,
                    height: this.holdingRectangle.height
                });
            }

            if (snappingOffsets) {
                newX += snappingOffsets.x;
                newY += snappingOffsets.y;

                this.drawGuidingLines(this.holdingRectangle);
            }

            this.selectedRectangles.forEach(rect => {
                rect.x += newX;
                rect.y += newY;
            });

            this.startX = (mousePos.x - this.offsetX) / this.zoomLevel;
            this.startY = (mousePos.y - this.offsetY) / this.zoomLevel;

            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.redrawCanvas();

            this.selectedRectangles.forEach(rect => {
                this.socketHandler.updateRectanglePosition(rect);
            });
        }

        if (this.selectedRectangles.length == 1) {
            this.trigger('update_rect', {
                ...this.selectedRectangles[0]
            });
        }

        this.handle_removing_selected_rects_from_frame(mousePos);

        if (this.isDrawingLine && this.selectedRectIndex !== null && (isShiftPressed || this.drawingFromCircle)) {
            this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
            this.redrawCanvas();
            this.drawTemporaryLine(mousePos.x, mousePos.y);
        }
    }


    onWheel(e) {
        e.preventDefault();
        const delta = Math.sign(e.deltaY);
        const zoomFactor = .125;
        const mouseX = e.clientX;
        const mouseY = e.clientY;

        if (e.ctrlKey) {
            if (delta < 0) {
                this.updateZoom(1 + zoomFactor, mouseX, mouseY);
            } else if (delta > 0) {
                this.updateZoom(1 - zoomFactor, mouseX, mouseY);
            }
        } else {
            if (this.config.restrictScrolling) return; // Prevent scrolling if restricted
            if (!this.config.restrictScrollX) {
                this.offsetX += e.deltaX;
            }
            if (!this.config.restrictScrollY) {
                this.offsetY += e.deltaY;
            }
            this.redrawCanvas();
        }

        this.trigger('set_zoom_level', this.zoomLevel);

        this.saveToLocalStorage();
    }

    onDragOver(e) {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'copy';
    }

    // Handle drop event
    onDrop(e) {
        e.preventDefault();
        const files = e.dataTransfer.files;
        if (files.length > 0) {
            const file = files[0];
            const fileType = file.type;
            const reader = new FileReader();

            if (fileType.startsWith('image/')) {
                reader.onload = (event) => {
                    this.loadImage(event.target.result, e.clientX, e.clientY);
                };
                reader.readAsDataURL(file);
            } else if (fileType === 'application/pdf') {
                reader.onload = (event) => {
                    this.loadPDF(event.target.result, e.clientX, e.clientY);
                };
                reader.readAsArrayBuffer(file);
            } else if (fileType === 'image/svg+xml') {
                reader.onload = (event) => {
                    this.loadSVG(event.target.result, e.clientX, e.clientY);
                };
                reader.readAsText(file);
            }
        }
    }

    updateConfig(config) {
        this.config = config ? config : defaultConfig

        this.redrawCanvas();
    }

    updateCanvas(props) {
        console.log(props)
        const { backgroundColor } = props ? props : defaultCanvasProps;

        if (backgroundColor) {
            this.canvas.style.backgroundColor = backgroundColor;
        }

        this.redrawCanvas();
    }

    // Load image onto the canvas
    loadImage(src, x, y) {
        const img = new Image();
        img.onload = () => {
            const rectId = Date.now().toString();
            const rect = {
                id: rectId,
                x: (x - this.offsetX) / this.zoomLevel,
                y: (y - this.offsetY) / this.zoomLevel,
                width: img.width,
                height: img.height,
                type: 'image',
                img: img
            };
            this.rectangles.push(rect);
            this.redrawCanvas();
        };
        img.src = src;
    }

    // Load SVG onto the canvas
    loadSVG(svgContent, x, y) {
        const img = new Image();
        const svgBlob = new Blob([svgContent], { type: 'image/svg+xml;charset=utf-8' });
        const url = URL.createObjectURL(svgBlob);
        img.onload = () => {
            URL.revokeObjectURL(url);
            const rectId = Date.now().toString();
            const rect = {
                id: rectId,
                x: (x - this.offsetX) / this.zoomLevel,
                y: (y - this.offsetY) / this.zoomLevel,
                width: img.width,
                height: img.height,
                type: 'svg',
                img: img
            };
            this.rectangles.push(rect);
            this.redrawCanvas();
        };
        img.src = url;
    }

    // Load PDF onto the canvas
    async loadPDF(pdfData, x, y) {
        const loadingTask = pdfjsLib.getDocument({ data: pdfData });
        const pdf = await loadingTask.promise;
        const page = await pdf.getPage(1);
        const viewport = page.getViewport({ scale: 1.5 });
        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d');
        canvas.height = viewport.height;
        canvas.width = viewport.width;

        await page.render({ canvasContext: context, viewport: viewport }).promise;

        const img = new Image();
        img.onload = () => {
            const rectId = Date.now().toString();
            const rect = {
                id: rectId,
                x: (x - this.offsetX) / this.zoomLevel,
                y: (y - this.offsetY) / this.zoomLevel,
                width: img.width,
                height: img.height,
                type: 'pdf',
                img: img
            };
            this.rectangles.push(rect);
            this.redrawCanvas();
        };
        img.src = canvas.toDataURL();
    }

    isHoveringOverFrame(mousePos) {
        for (let i = this.rectangles.length - 1; i >= 0; i--) {
            const rect = this.rectangles[i];
            if (this.selectedRectangles.includes(rect)) {
                continue; // Skip the selected rectangles
            }
            if (this.isPointInsideRect(mousePos, rect) && rect.type === 'frame') {
                return rect;
            }
        }
        return null;
    }

    isPointInsideRect(point, rect) {
        const { x, y, width, height } = rect;
        return point.x > x * this.zoomLevel + this.offsetX &&
               point.x < (x + width) * this.zoomLevel + this.offsetX &&
               point.y > y * this.zoomLevel + this.offsetY &&
               point.y < (y + height) * this.zoomLevel + this.offsetY;
    }

    getSnappingOffsets(movingRect) {
        const snapThreshold = 1.5; // Distance within which snapping occurs
        const snapProximity = 4; // Proximity within which snapping happens smoothly
        let snapOffset = { x: 0, y: 0 };

        this.rectangles.forEach(rect => {
            if (rect === movingRect) return;

            const rectSides = {
                top: rect.y,
                bottom: rect.y + rect.height,
                left: rect.x,
                right: rect.x + rect.width,
            };

            const movingRectSides = {
                top: movingRect.y,
                bottom: movingRect.y + movingRect.height,
                left: movingRect.x,
                right: movingRect.x + movingRect.width,
            };

            for (const [side, position] of Object.entries(movingRectSides)) {
                for (const [otherSide, otherPosition] of Object.entries(rectSides)) {
                    const offset = position - otherPosition;
                    if (Math.abs(offset) < snapThreshold) {
                        if (side === 'top' || side === 'bottom') {
                            const distance = Math.abs(position - otherPosition);
                            snapOffset.y = distance < snapProximity ? -offset : 0;
                        } else {
                            const distance = Math.abs(position - otherPosition);
                            snapOffset.x = distance < snapProximity ? -offset : 0;
                        }
                    }
                }
            }
        });

        return snapOffset;
    }

    getSnappingCoordinates(movingRect) {
        const snapThreshold = 2; // Distance within which snapping occurs
        let snapCoords = { x: movingRect.x, y: movingRect.y };

        this.rectangles.forEach(rect => {
            if (rect === movingRect) return;

            const rectSides = {
                top: rect.y,
                bottom: rect.y + rect.height,
                left: rect.x,
                right: rect.x + rect.width,
            };

            const movingRectSides = {
                top: movingRect.y,
                bottom: movingRect.y + movingRect.height,
                left: movingRect.x,
                right: movingRect.x + movingRect.width,
            };

            for (const [side, position] of Object.entries(movingRectSides)) {
                for (const [otherSide, otherPosition] of Object.entries(rectSides)) {
                    if (Math.abs(position - otherPosition) < snapThreshold) {
                        if (side === 'top' || side === 'bottom') {
                            snapCoords.y = otherSide === 'top' ? rect.y : rect.y + rect.height - movingRect.height;
                        } else {
                            snapCoords.x = otherSide === 'left' ? rect.x : rect.x + rect.width - movingRect.width;
                        }
                    }
                }
            }
        });

        return snapCoords;
    }

    drawGuidingLines(movingRect) {
        const { x, y, width, height } = movingRect;
        const movingRectSides = {
            top: y,
            bottom: y + height,
            left: x,
            right: x + width,
        };

        this.rectangles.forEach(rect => {
            if (rect === movingRect) return;

            const rectSides = {
                top: rect.y,
                bottom: rect.y + rect.height,
                left: rect.x,
                right: rect.x + rect.width,
            };

            for (const [side, position] of Object.entries(movingRectSides)) {
                for (const [otherSide, otherPosition] of Object.entries(rectSides)) {
                    if (Math.abs(position - otherPosition) < 2) { // Tolerance of 2 pixels
                        this.drawGuideLine(side, position, otherSide, otherPosition, rect);
                    }
                }
            }
        });
    }

    drawGuideLine(side, position, otherSide, otherPosition, rect) {
        this.ctx.save();
        this.ctx.strokeStyle = 'rgba(0, 122, 255, 0.5)';
        this.ctx.lineWidth = 1;

        if (side === 'top' || side === 'bottom') {
            const y = position * this.zoomLevel + this.offsetY;
            const xStart = Math.min(rect.x, this.holdingRectangle.x) * this.zoomLevel + this.offsetX;
            const xEnd = Math.max(rect.x + rect.width, this.holdingRectangle.x + this.holdingRectangle.width) * this.zoomLevel + this.offsetX;
            this.ctx.beginPath();
            this.ctx.moveTo(xStart, y);
            this.ctx.lineTo(xEnd, y);
            this.ctx.stroke();
        } else if (side === 'left' || side === 'right') {
            const x = position * this.zoomLevel + this.offsetX;
            const yStart = Math.min(rect.y, this.holdingRectangle.y) * this.zoomLevel + this.offsetY;
            const yEnd = Math.max(rect.y + rect.height, this.holdingRectangle.y + this.holdingRectangle.height) * this.zoomLevel + this.offsetY;
            this.ctx.beginPath();
            this.ctx.moveTo(x, yStart);
            this.ctx.lineTo(x, yEnd);
            this.ctx.stroke();
        }

        this.ctx.restore();
    }


    getMousePos(e) {
        const rect = this.canvas.getBoundingClientRect();
        return {
            x: e.clientX - rect.left,
            y: e.clientY - rect.top
        };
    }

    findRectAt(pos) {
  const dpr = window.devicePixelRatio || 1;
  const adjustedX = (pos.x / this.zoomLevel - this.offsetX / this.zoomLevel) / dpr;
  const adjustedY = (pos.y / this.zoomLevel - this.offsetY / this.zoomLevel) / dpr;

  
  for (let i = this.rectangles.length - 1; i >= 0; i--) {
    const rect = this.rectangles[i];
    if (adjustedX > rect.x && adjustedX < rect.x + rect.width && adjustedY > rect.y && adjustedY < rect.y + rect.height) {
      return rect;
    }
  }
  return null;
}


    findLineIndexByColor(color) {
        for (let i = 0; i < this.lines.length; i++) {
            const hitColor = this.generateUniqueColor(`line-${i}`);
            if (hitColor === color) {
                return i;
            }
        }
        return -1;
    }

    saveToHistory() {
        const currentState = {
            rectangles: JSON.parse(JSON.stringify(this.rectangles)), // Deep copy
            lines: JSON.parse(JSON.stringify(this.lines)), // Deep copy
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            zoomLevel: this.zoomLevel
        };
        this.history.push(currentState);
        this.historyIndex++;
    }

    undo() {
        if (this.historyIndex > 0) {
            this.historyIndex--;
            const previousState = this.history[this.historyIndex];
            this.restoreFromHistory(previousState);
            this.redrawCanvas();
        }
    }

    redo() {
        if (this.historyIndex < this.history.length - 1) {
            this.historyIndex++;
            const nextState = this.history[this.historyIndex];
            this.restoreFromHistory(nextState);
            this.redrawCanvas();
        }
    }

    restoreFromHistory(state) {
        this.rectangles = state.rectangles;
        this.lines = state.lines;
        this.offsetX = state.offsetX;
        this.offsetY = state.offsetY;
        this.zoomLevel = state.zoomLevel;
        this.updateDataTextarea(); // Update dataTextarea after restoring from history
    }

    saveToLocalStorage() {
        if (!this.localStorageEnabled) return
        const data = {
            rectangles: this.rectangles,
            lines: this.lines,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            zoomLevel: this.zoomLevel
        };
        localStorage.setItem('canvasData', JSON.stringify(data));
    }

    save() {
        this.saveToHistory();

        this.saveToLocalStorage();
    }

    playAnimation() {
	    const animateItem = (item, delay) => {
	        setTimeout(() => {
	            // Change border color to green
	            item.borderColor = 'green';
	            this.redrawCanvas();

	            // Revert border color back to default after 1 second
	            setTimeout(() => {
	                item.borderColor = '#e7e7e7';
	                this.redrawCanvas();

	                // Animate children
	                const children = this.lines.filter(line => line.from.sequence_id === item.sequence_id).map(line => {
	                    return this.rectangles.find(rect => rect.sequence_id === line.to.sequence_id);
	                });

	                children.forEach((child, index) => {
	                    animateItem(child, 1000 * (index + 1));
	                });

	            }, 1000);
	        }, delay);
	    };

	    // Find root rectangles
	    const rootRectangles = this.rectangles.filter(rect => !this.lines.some(line => line.to.sequence_id === rect.sequence_id));
	    rootRectangles.forEach((rect, index) => {
	        animateItem(rect, 1000 * index);
	    });
	}

    startMovingRectangle(mousePos) {
	    const rectIndex = this.findRectIndexAt(mousePos);
	    if (rectIndex !== -1) {
	        this.isMoving = true;
	        this.selectedRectIndex = rectIndex;
	        this.selectedRectangle = this.rectangles[this.selectedRectIndex];
	        this.startX = (mousePos.x - this.offsetX) / this.zoomLevel;
	        this.startY = (mousePos.y - this.offsetY) / this.zoomLevel;
	    }
	}

    isPointOnLine(point, lineStart, lineEnd) {
        const tolerance = 5;
        const distanceToLine = Math.abs((lineEnd.y - lineStart.y) * point.x - (lineEnd.x - lineStart.x) * point.y + lineEnd.x * lineStart.y - lineEnd.y * lineStart.x) / Math.sqrt(Math.pow(lineEnd.y - lineStart.y, 2) + Math.pow(lineEnd.x - lineStart.x, 2));
        return distanceToLine <= tolerance;
    }

    findLineIndexAt(pos) {
        const dpr = window.devicePixelRatio || 1;
        const adjustedPos = { x: pos.x * dpr, y: pos.y * dpr };

        for (let i = 0; i < this.lines.length; i++) {
            const line = this.lines[i];
            const fromRect = this.rectangles.find(rect => rect.sequence_id === line.from.sequence_id);
            const toRect = this.rectangles.find(rect => rect.sequence_id === line.to.sequence_id);
            if (fromRect && toRect) {
                const fromCenter = { 
                    x: (fromRect.x + fromRect.width / 2) * this.zoomLevel + this.offsetX * dpr, 
                    y: (fromRect.y + fromRect.height / 2) * this.zoomLevel + this.offsetY * dpr 
                };
                const toCenter = { 
                    x: (toRect.x + toRect.width / 2) * this.zoomLevel + this.offsetX * dpr, 
                    y: (toRect.y + toRect.height / 2) * this.zoomLevel + this.offsetY * dpr 
                };
                if (this.isPointOnLine(adjustedPos, fromCenter, toCenter)) {
                    return i;
                }
            }
        }
        return -1;
    }

    findRectIndexAt(pos) {
        for (let i = this.rectangles.length - 1; i >= 0; i--) {
            const rect = this.rectangles[i];
            if (pos.x > (rect.x * this.zoomLevel) + this.offsetX && pos.x < (rect.x + rect.width) * this.zoomLevel + this.offsetX && pos.y > (rect.y * this.zoomLevel) + this.offsetY && pos.y < (rect.y + rect.height) * this.zoomLevel + this.offsetY) {
                return i;
            }
        }
        return -1;
    }

	drawTemporaryLine(mouseX, mouseY) {
        if (this.selectedRectIndex !== null) {
            const fromRect = this.rectangles[this.selectedRectIndex];
            const fromX = (fromRect.x + fromRect.width / 2) * this.zoomLevel + this.offsetX;
            const fromY = (fromRect.y + fromRect.height / 2) * this.zoomLevel + this.offsetY;

            this.ctx.beginPath();
            this.ctx.moveTo(fromX, fromY);
            this.ctx.lineTo(mouseX, mouseY);
            this.ctx.strokeStyle = 'blue';
            this.ctx.lineWidth = 2;
            this.ctx.stroke();
        }
    }

	handleDrawMode(e) {
	    if (e.key === 'Alt') {
	        this.isDrawing = !this.isDrawing;
	        if (this.isDrawing) {
	            this.canvas.style.cursor = 'crosshair';
	        } else {
	            this.canvas.style.cursor = this.cursorDefault;
	        }
	    }
	}

    handleMiddleClick(mousePos) {
        const rectIndex = this.findRectIndexAt(mousePos);

        if (rectIndex !== -1) {
            const rectId = this.rectangles[rectIndex].sequence_id;
            this.rectangles.splice(rectIndex, 1);
            this.lines = this.lines.filter(line => line.from.sequence_id !== rectId && line.to.sequence_id !== rectId);
        } else {
            
        }
    }

	startPanning(e) {
        if (this.config.restrictScrolling) return; // Prevent panning if restricted
        this.isPanning = true;
        this.panStartX = e.clientX;
        this.panStartY = e.clientY;
        this.canvas.style.cursor = this.cursorGrabbing;
    }

    createConnectedRectangle(side) {
        this.isDrawingLine = false;
        if (!this.selectedRectangle) return;

        const rect = this.selectedRectangle;

        const rectId = Date.now().toString();
        let newX, newY;

        switch (side) {
            case 'top':
                newX = rect.x;
                newY = rect.y - this.RECT_HEIGHT - this.LINE_SPACING / 2;
                break;
            case 'right':
                newX = rect.x + this.RECT_WIDTH + this.LINE_SPACING / 2;
                newY = rect.y;
                break;
            case 'bottom':
                newX = rect.x;
                newY = rect.y + this.RECT_HEIGHT + this.LINE_SPACING / 2;
                break;
            case 'left':
                newX = rect.x - this.RECT_WIDTH - this.LINE_SPACING / 2;
                newY = rect.y;
                break;
            default:
                return;
        }

        const newRectangle = this.createRectangle(newX, newY);

        const newConnection = {
            from_rect_id: rect.sequence_id,
            to_rect_id: newRectangle.sequence_id,
        }

        this.lines.push({ from: rect, to: newRectangle });

        this.trigger('add_line', newConnection);

        this.selectedRectangle = newRectangle;

        const audio = new Audio('/sounds/connect.mp3');
        audio.volume = 0.125; // Set the volume (0.0 to 1.0)
        audio.play();

        this.redrawCanvas();
    }

    onCircleMouseDown(e, circlePosition) {
        e.stopPropagation();
        this.isDrawingLine = true;
        this.drawingFromCircle = circlePosition;

        if (this.selectedRectangle) {
            this.selectedRectIndex = this.rectangles.findIndex(rect => rect.sequence_id === this.selectedRectangle.sequence_id);
        }
    }

    initCircles() {
        this.topCircle = document.getElementById('topCircle');
        this.rightCircle = document.getElementById('rightCircle');
        this.bottomCircle = document.getElementById('bottomCircle');
        this.leftCircle = document.getElementById('leftCircle');

        // Event listeners for circle clicks
        this.topCircle.addEventListener('mousedown', (e) => this.onCircleMouseDown(e, 'top'));
        this.rightCircle.addEventListener('mousedown', (e) => this.onCircleMouseDown(e, 'right'));
        this.bottomCircle.addEventListener('mousedown', (e) => this.onCircleMouseDown(e, 'bottom'));
        this.leftCircle.addEventListener('mousedown', (e) => this.onCircleMouseDown(e, 'left'));

        this.topCircle.addEventListener('click', () => this.createConnectedRectangle('top'));
        this.rightCircle.addEventListener('click', () => this.createConnectedRectangle('right'));
        this.bottomCircle.addEventListener('click', () => this.createConnectedRectangle('bottom'));
        this.leftCircle.addEventListener('click', () => this.createConnectedRectangle('left'));
    }

    hideCircles() {
        this.topCircle.style.display = 'none';
        this.rightCircle.style.display = 'none';
        this.bottomCircle.style.display = 'none';
        this.leftCircle.style.display = 'none';
    }

    showCircles(rect) {
        const rectX = (rect.x * this.zoomLevel) + this.offsetX;
        const rectY = (rect.y * this.zoomLevel) + this.offsetY;
        const rectWidth = rect.width * this.zoomLevel;
        const rectHeight = rect.height * this.zoomLevel;

        // Adjust the circles to be slightly inside the rectangle
        this.topCircle.style.left = `${rectX + rectWidth / 2 - 8}px`;
        this.topCircle.style.top = `${rectY - 30}px`;
        this.topCircle.style.display = 'block';

        this.rightCircle.style.left = `${rectX + rectWidth + 14}px`;
        this.rightCircle.style.top = `${rectY + rectHeight / 2 - 8}px`;
        this.rightCircle.style.display = 'block';

        this.bottomCircle.style.left = `${rectX + rectWidth / 2 - 8}px`;
        this.bottomCircle.style.top = `${rectY + rectHeight + 14}px`;
        this.bottomCircle.style.display = 'block';

        this.leftCircle.style.left = `${rectX - 30}px`;
        this.leftCircle.style.top = `${rectY + rectHeight / 2 - 8}px`;
        this.leftCircle.style.display = 'block';
    }

    layoutFrameChildren(frame) {
        const { paddingTop = 0, paddingRight = 0, paddingBottom = 0, paddingLeft = 0 } = frame;

        let xPos = paddingLeft; // Start with left padding
        let yPos = paddingTop; // Start with top padding
        let rowHeight = 0;
        const padding = 0; // Optional padding between elements
        const frameWidth = frame.width - paddingLeft - paddingRight;

        this.rectangles.forEach(child => {
            if (child.sequence_id == child.parent) return;
            if (child.parent === frame.sequence_id && ['rect', 'text', 'group'].includes(child.type)) {
                const childWidth = child.width;
                const childHeight = child.height;

                // Wrap to the next line if there is not enough space
                if (xPos + childWidth > frameWidth) {
                    xPos = paddingLeft;
                    yPos += rowHeight + padding;
                    rowHeight = 0;
                }

                // Position the child within the frame
                child.x = frame.x + xPos;
                child.y = frame.y + yPos;

                // Update xPos and rowHeight for the next child
                xPos += childWidth + padding;
                rowHeight = Math.max(rowHeight, childHeight);
            }
        });
    }

    drawFormCardContent(rect, finalX, finalY, finalWidth, finalHeight) {
        const formPadding = 10;
        const inputHeight = 20;
        const inputWidth = finalWidth - 2 * formPadding;
        const buttonHeight = 25;
        const buttonWidth = 80;

        // Draw title text
        this.ctx.font = '600 16px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText('Form', finalX + formPadding, finalY + formPadding + 16);

        // Draw input fields
        this.ctx.fillStyle = '#fff';
        this.ctx.strokeStyle = '#000';
        this.ctx.lineWidth = 1;

        for (let i = 0; i < 3; i++) {
            const inputY = finalY + formPadding + 30 + i * (inputHeight + formPadding);
            this.ctx.fillRect(finalX + formPadding, inputY, inputWidth, inputHeight);
            this.ctx.strokeRect(finalX + formPadding, inputY, inputWidth, inputHeight);
        }

        // Draw submit button
        const buttonX = finalX + finalWidth - buttonWidth - formPadding;
        const buttonY = finalY + finalHeight - buttonHeight - formPadding;
        this.ctx.fillStyle = '#007bff';
        this.ctx.fillRect(buttonX, buttonY, buttonWidth, buttonHeight);
        this.ctx.strokeRect(buttonX, buttonY, buttonWidth, buttonHeight);

        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#fff';
        this.ctx.fillText('Submit', buttonX + 10, buttonY + 18);
    }

    drawEmailCardContent(rect, finalX, finalY, finalWidth, finalHeight) {
        const { sender, recipient, subject, message } = rect;
        const formPadding = 10;
        const inputHeight = 20;
        const inputWidth = finalWidth - 2 * formPadding;

        // Draw title text
        this.ctx.font = '600 16px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText('Email', finalX + formPadding, finalY + formPadding + 16);

        // Draw sender field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(`From: ${sender}`, finalX + formPadding, finalY + formPadding + 40);

        // Draw recipient field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(`To: ${recipient}`, finalX + formPadding, finalY + formPadding + 60);

        // Draw subject field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(`Subject: ${subject}`, finalX + formPadding, finalY + formPadding + 80);

        // Draw message body
        this.ctx.fillStyle = '#fff';
        this.ctx.strokeStyle = '#000';
        this.ctx.lineWidth = 1;
        const messageY = finalY + formPadding + 100;
        const messageHeight = finalHeight - messageY - formPadding;
        this.ctx.fillRect(finalX + formPadding, messageY, inputWidth, messageHeight);
        this.ctx.strokeRect(finalX + formPadding, messageY, inputWidth, messageHeight);

        this.ctx.font = '400 12px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(message, finalX + formPadding + 5, messageY + 15);
    }

    drawWebhookCardContent(rect, finalX, finalY, finalWidth, finalHeight) {
        const { webhookURL, requestType, headers, body } = rect;
        const formPadding = 10;
        const inputHeight = 20;
        const inputWidth = finalWidth - 2 * formPadding;

        // Draw title text
        this.ctx.font = '600 16px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText('Webhook', finalX + formPadding, finalY + formPadding + 16);

        // Draw webhook URL field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(`URL: ${webhookURL}`, finalX + formPadding, finalY + formPadding + 40);

        // Draw request type field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(`Request Type: ${requestType}`, finalX + formPadding, finalY + formPadding + 60);

        // Draw headers field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText('Headers:', finalX + formPadding, finalY + formPadding + 80);
        let headersY = finalY + formPadding + 100;
        headers.forEach(header => {
            this.ctx.fillText(`${header.key}: ${header.value}`, finalX + formPadding + 10, headersY);
            headersY += 20;
        });

        // Draw body field
        this.ctx.fillText('Body:', finalX + formPadding, headersY + 20);
        this.ctx.fillStyle = '#fff';
        this.ctx.strokeStyle = '#000';
        this.ctx.lineWidth = 1;
        const bodyY = headersY + 40;
        const bodyHeight = finalHeight - bodyY - formPadding;
        this.ctx.fillRect(finalX + formPadding, bodyY, inputWidth, bodyHeight);
        this.ctx.strokeRect(finalX + formPadding, bodyY, inputWidth, bodyHeight);

        this.ctx.font = '400 12px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(body, finalX + formPadding + 5, bodyY + 15);
    }

    drawConditionCardContent(rect, finalX, finalY, finalWidth, finalHeight) {
        const { condition, trueBranch, falseBranch } = rect;
        const formPadding = 10;
        const inputHeight = 20;
        const inputWidth = finalWidth - 2 * formPadding;

        // Draw title text
        this.ctx.font = '600 16px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText('Condition', finalX + formPadding, finalY + formPadding + 16);

        // Draw condition field
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(`If: ${condition}`, finalX + formPadding, finalY + formPadding + 40);

        // Draw true branch field
        this.ctx.fillText('Then:', finalX + formPadding, finalY + formPadding + 60);
        this.ctx.fillStyle = '#fff';
        this.ctx.strokeStyle = '#000';
        this.ctx.lineWidth = 1;
        const trueBranchY = finalY + formPadding + 80;
        const trueBranchHeight = inputHeight;
        this.ctx.fillRect(finalX + formPadding, trueBranchY, inputWidth, trueBranchHeight);
        this.ctx.strokeRect(finalX + formPadding, trueBranchY, inputWidth, trueBranchHeight);

        this.ctx.font = '400 12px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(trueBranch, finalX + formPadding + 5, trueBranchY + 15);

        // Draw false branch field
        const falseBranchY = trueBranchY + trueBranchHeight + formPadding;
        this.ctx.fillText('Else:', finalX + formPadding, falseBranchY - 5);
        this.ctx.fillStyle = '#fff';
        this.ctx.fillRect(finalX + formPadding, falseBranchY, inputWidth, trueBranchHeight);
        this.ctx.strokeRect(finalX + formPadding, falseBranchY, inputWidth, trueBranchHeight);

        this.ctx.font = '400 12px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(falseBranch, finalX + formPadding + 5, falseBranchY + 15);
    }


    drawPill(rect) {
        const { x, y, width, height, title, subtitle, imageUrl, svg, appName, brandColor, status, borderColor = '#e7e7e7' } = rect;
        const radius = 12;
        const scaledX = (x * this.zoomLevel) + this.offsetX;
        const scaledY = (y * this.zoomLevel) + this.offsetY;
        const scaledWidth = width * this.zoomLevel;
        const scaledHeight = height * this.zoomLevel;

        this.ctx.save();
        this.ctx.fillStyle = '#fff';
        this.ctx.strokeStyle = 'borderColor'; // Use borderColor
        this.ctx.lineWidth = 1;

        this.ctx.beginPath();
        this.ctx.moveTo(scaledX + radius, scaledY);
        this.ctx.arcTo(scaledX + scaledWidth, scaledY, scaledX + scaledWidth, scaledY + scaledHeight, radius);
        this.ctx.arcTo(scaledX + scaledWidth, scaledY + scaledHeight, scaledX, scaledY + scaledHeight, radius);
        this.ctx.arcTo(scaledX, scaledY + scaledHeight, scaledX, scaledY, radius);
        this.ctx.arcTo(scaledX, scaledY, scaledX + scaledWidth, scaledY, radius);
        this.ctx.closePath();

        this.ctx.fill();
        this.ctx.stroke();

        // Draw title text with custom font
        this.ctx.font = '600 14px Cera';
        this.ctx.fillStyle = '#000';
        this.ctx.fillText(title, scaledX + 12, scaledY + 18);

        // Draw subtitle text with custom font
        // this.ctx.font = '500 14px Cera';
        // this.ctx.fillStyle = 'gray';
        // this.ctx.fillText(subtitle, scaledX + 12, scaledY + 45);

        // Draw status indicator
        const statusColorMap = {
            idle: 'gray',
            running: 'green',
            issue: 'orange',
            failure: 'red'
        };

        const statusColor = statusColorMap[status] || '#fff'; // Default to gray if status is not found
        const statusSize = 6;
        this.ctx.fillStyle = statusColor;
        this.ctx.beginPath();
        this.ctx.arc(scaledX + scaledWidth - statusSize - 10, scaledY + statusSize + 7, statusSize, 0, 2 * Math.PI);
        this.ctx.fill();

        this.ctx.restore(); // Restore the previous state
		}
    
    drawCardEmail(rect) {
            const { x, y, width, height, title, subtitle, imageUrl, svg, appName, brandColor, status, borderColor = '#e7e7e7' } = rect;
            const radius = 12;
            const scaledX = (x * this.zoomLevel) + this.offsetX;
            const scaledY = (y * this.zoomLevel) + this.offsetY;
            const scaledWidth = width * this.zoomLevel;
            const scaledHeight = height * this.zoomLevel;

            this.ctx.save();
            this.ctx.fillStyle = 'white';
            this.ctx.strokeStyle = '#e7e7e7'; // Use borderColor
            this.ctx.lineWidth = 1;

            this.ctx.beginPath();
            this.ctx.moveTo(scaledX + radius, scaledY);
            this.ctx.arcTo(scaledX + scaledWidth, scaledY, scaledX + scaledWidth, scaledY + scaledHeight, radius);
            this.ctx.arcTo(scaledX + scaledWidth, scaledY + scaledHeight, scaledX, scaledY + scaledHeight, radius);
            this.ctx.arcTo(scaledX, scaledY + scaledHeight, scaledX, scaledY, radius);
            this.ctx.arcTo(scaledX, scaledY, scaledX + scaledWidth, scaledY, radius);
            this.ctx.closePath();

            this.ctx.fill();
            this.ctx.stroke();

        // Draw title text with custom font
        this.ctx.font = '600 16px Cera';
        this.ctx.fillStyle = 'black';
        this.ctx.fillText(title, scaledX + 12, scaledY + 25);

        // Draw subtitle text with custom font
        this.ctx.font = '500 14px Cera';
        this.ctx.fillStyle = 'gray';
        this.ctx.fillText(subtitle, scaledX + 12, scaledY + 45);

        // Draw image in the bottom right
        if (true) {
            let img = this.imageCache["https://img.icons8.com/3d-fluency/94/mail.png"];
            if (img && img.complete) {
                this.ctx.drawImage(img, scaledX + scaledWidth - 40, scaledY + scaledHeight - 40, 30, 30);
            } else {
                img = new Image();
                img.onload = () => {
                    this.ctx.drawImage(img, scaledX + scaledWidth - 40, scaledY + scaledHeight - 40, 30, 30);
                };
                img.src = "https://img.icons8.com/3d-fluency/94/mail.png";
                this.imageCache["https://img.icons8.com/3d-fluency/94/mail.png"] = img;
            }
        }

        // Draw status indicator
        const statusColorMap = {
            idle: 'gray',
            running: 'green',
            issue: 'orange',
            failure: 'red'
        };

        const statusColor = statusColorMap[status] || '#fff'; // Default to gray if status is not found
        const statusSize = 6;
        this.ctx.fillStyle = statusColor;
        this.ctx.beginPath();
        this.ctx.arc(scaledX + scaledWidth - statusSize - 10, scaledY + statusSize + 10, statusSize, 0, 2 * Math.PI);
        this.ctx.fill();

        this.ctx.restore(); // Restore the previous state
    }

    drawRectangle(rect) {
        const { type, img, x, y, width, height } = rect;
        const dpr = window.devicePixelRatio || 1;
        const offsetX = this.offsetX * dpr;
        const offsetY = this.offsetY * dpr;

        if (type === 'image' || type === 'svg' || type === 'pdf') {
            this.ctx.drawImage(img, x * this.zoomLevel + offsetX, y * this.zoomLevel + offsetY, width * this.zoomLevel, height * this.zoomLevel);
        } else {
            // Existing drawing logic for other rectangle types
            switch (type) {
                case 'rect':
                    drawRect(this.ctx, rect, this.zoomLevel, this.offsetX, this.offsetY, this.hoveredRectangle, this.holdingRectangle, this.selectedRectangles);
                    break;
                case 'text':
                    drawTextRect(this.ctx, rect, this.rectangles, this.zoomLevel, this.offsetX, this.offsetY, this.selectedRectangles);
                    break;
                case 'card':
                    drawCard(this.ctx, rect, this.zoomLevel, this.offsetX, this.offsetY, this.selectedRectangles, this.hoveredRectangle, this.holdingRectangle, this.imageCache);
                    break;
                case 'pill':
                    this.drawPill(rect, this.ctx, this.zoomLevel, offsetX, offsetY);
                    break;
                case 'email':
                    this.drawCardEmail(rect, this.ctx, this.zoomLevel, offsetX, offsetY);
                    break;
                case 'frame':
                    drawFrameRect(this.ctx, rect, this.zoomLevel, this.offsetX, this.offsetY, this.selectedRectangles, this.rectangles, this.layoutFrameChildren.bind(this));
                    break;
                case 'group':
                    drawGroupRect(this.ctx, rect, this.zoomLevel, this.offsetX, this.offsetY, this.selectedRectangles);
                    break;
                default:
                    drawRect(this.ctx, rect, this.zoomLevel, this.offsetX, this.offsetY, this.hoveredRectangle, this.holdingRectangle, this.selectedRectangles);
                    break;
            }
        }
    }

    drawRectangles() {
        this.rectangles.forEach(rect => {
            this.drawRectangle(rect);
            if(this.selectedRectangle && this.selectedRectangle.sequence_id === rect.sequence_id) {
                this.drawResizeHandles(rect);
            }
        });
    }

    redrawRect(rect) {
        if (rect) {
            // Clear the area occupied by the rect
            const x = rect.x * this.zoomLevel + this.offsetX;
            const y = rect.y * this.zoomLevel + this.offsetY;
            const width = rect.width * this.zoomLevel;
            const height = rect.height * this.zoomLevel;
            this.drawRectangle(rect);
            if(this.selectedRectangle && this.selectedRectangle.sequence_id === rect.sequence_id) {
                this.drawResizeHandles(rect);
            }
        }
    }

    drawLines() {
        this.lines.forEach(line => {
            const fromRect = this.rectangles.find(rect => rect.sequence_id === line.from.sequence_id);
            const toRect = this.rectangles.find(rect => rect.sequence_id === line.to.sequence_id);

            if (fromRect && toRect) {
                const fromSideCenter = findClosestSideCenter(fromRect, toRect);
                const toSideCenter = findClosestSideCenter(toRect, fromRect);
                this.drawBezierLine(fromSideCenter.x, fromSideCenter.y, toSideCenter.x, toSideCenter.y);

                if (this.isBoundingBoxVisible) {
                    const boundingBox = this.getLineBoundingBox(fromSideCenter.x, fromSideCenter.y, toSideCenter.x, toSideCenter.y);
                    this.drawBoundingBox(boundingBox);
                }
            }
        });
    }

    drawResizeHandles(rect) {
        const handles = this.getRectHandles(rect);
        this.ctx.save();
          handles.forEach(handle => {
            this.ctx.fillStyle = 'blue';
            this.ctx.fillRect(
              handle.x * this.zoomLevel + this.offsetX - handle.size / 2,
              handle.y * this.zoomLevel + this.offsetY - handle.size / 2,
              handle.size,
              handle.size
            );
          });
          this.ctx.restore();
      }

    getResizeHandle(mousePos) {
  const dpr = window.devicePixelRatio || 1;
  const adjustedMousePos = {
    x: (mousePos.x / this.zoomLevel - this.offsetX / this.zoomLevel) / dpr,
    y: (mousePos.y / this.zoomLevel - this.offsetY / this.zoomLevel) / dpr,
  };
  
  for (const rect of this.rectangles) {
    const handles = this.getRectHandles(rect);
    for (const handle of handles) {
      const dx = adjustedMousePos.x - handle.x;
      const dy = adjustedMousePos.y - handle.y;
      if (dx * dx + dy * dy < handle.size * handle.size) {
        return handle;
      }
    }
  }
  return null;
}


resizeRectangle(rect, mousePos, handleType) {
    this.hideCircles();

    const dpr = 1;
    const adjustedMousePos = {
        x: (mousePos.x / this.zoomLevel - this.offsetX / this.zoomLevel) / dpr,
        y: (mousePos.y / this.zoomLevel - this.offsetY / this.zoomLevel) / dpr,
    };

    const { x, y, width, height } = rect;
    
    this.trigger('update_rect', {
        ...rect
    });

    this.trigger('resize_rect', {
        ...rect
    });

    switch (handleType) {
        case 'top-left':
            rect.x = adjustedMousePos.x;
            rect.y = adjustedMousePos.y;
            rect.width = width + (x - adjustedMousePos.x);
            rect.height = height + (y - adjustedMousePos.y);
            break;
        case 'top-right':
            rect.y = adjustedMousePos.y;
            rect.width = adjustedMousePos.x - x;
            rect.height = height + (y - adjustedMousePos.y);
            break;
        case 'bottom-left':
            rect.x = adjustedMousePos.x;
            rect.width = width + (x - adjustedMousePos.x);
            rect.height = adjustedMousePos.y - y;
            break;
        case 'bottom-right':
            rect.width = adjustedMousePos.x - x;
            rect.height = adjustedMousePos.y - y;
            break;
        case 'top':
            rect.y = adjustedMousePos.y;
            rect.height = height + (y - adjustedMousePos.y);
            break;
        case 'bottom':
            rect.height = adjustedMousePos.y - y;
            break;
        case 'left':
            rect.x = adjustedMousePos.x;
            rect.width = width + (x - adjustedMousePos.x);
            break;
        case 'right':
            rect.width = adjustedMousePos.x - x;
            break;
    }
}

getRectHandles(rect) {
    const { x, y, width, height } = rect;
    const size = 8;
    return [
        { x, y, size, cursor: 'nw-resize', type: 'top-left' },
        { x: x + width, y, size, cursor: 'ne-resize', type: 'top-right' },
        { x, y: y + height, size, cursor: 'sw-resize', type: 'bottom-left' },
        { x: x + width, y: y + height, size, cursor: 'se-resize', type: 'bottom-right' },
        { x: x + width / 2, y, size, cursor: 'n-resize', type: 'top' },
        { x: x + width / 2, y: y + height, size, cursor: 's-resize', type: 'bottom' },
        { x, y: y + height / 2, size, cursor: 'w-resize', type: 'left' },
        { x: x + width, y: y + height / 2, size, cursor: 'e-resize', type: 'right' },
    ];
}

    drawBezierLine(x1, y1, x2, y2) {
        if (!isFinite(x1) || !isFinite(y1) || !isFinite(x2) || !isFinite(y2)) {
            return;
        }

        const cp1x = x1 + (x2 - x1) / 2;
        const cp1y = y1;
        const cp2x = x1 + (x2 - x1) / 2;
        const cp2y = y2;

        const gradient = this.ctx.createLinearGradient((x1 * this.zoomLevel) + this.offsetX, (y1 * this.zoomLevel) + this.offsetY, (x2 * this.zoomLevel) + this.offsetX, (y2 * this.zoomLevel) + this.offsetY);
        gradient.addColorStop(0, '#b3b2b2');
        gradient.addColorStop(1, '#e7e7e7');

        let strokeStyle = gradient

        if (this.ctx.isHovered) {
            strokeStyle = "blue"
        }

        this.ctx.beginPath();
        this.ctx.moveTo((x1 * this.zoomLevel) + this.offsetX, (y1 * this.zoomLevel) + this.offsetY);
        this.ctx.bezierCurveTo((cp1x * this.zoomLevel) + this.offsetX, (cp1y * this.zoomLevel) + this.offsetY, (cp2x * this.zoomLevel) + this.offsetX, (cp2y * this.zoomLevel) + this.offsetY, (x2 * this.zoomLevel) + this.offsetX, (y2 * this.zoomLevel) + this.offsetY);
        this.ctx.strokeStyle = strokeStyle;
        this.ctx.lineWidth = 2;
        this.ctx.stroke();

        this.drawArrow((cp2x * this.zoomLevel) + this.offsetX, (cp2y * this.zoomLevel) + this.offsetY, (x2 * this.zoomLevel) + this.offsetX, (y2 * this.zoomLevel) + this.offsetY);
    }

    drawArrow(cp2x, cp2y, tox, toy) {
        const headlen = 10 * this.zoomLevel;
        const angle = Math.atan2(toy - cp2y, tox - cp2x);

        this.ctx.beginPath();
        this.ctx.moveTo(tox, toy);
        this.ctx.lineTo(tox - headlen * Math.cos(angle - Math.PI / 6), toy - headlen * Math.sin(angle - Math.PI / 6));
        this.ctx.moveTo(tox, toy);
        this.ctx.lineTo(tox - headlen * Math.cos(angle + Math.PI / 6), toy - headlen * Math.sin(angle + Math.PI / 6));
        this.ctx.strokeStyle = '#e7e7e7';
        this.ctx.lineWidth = 2;
        this.ctx.stroke();
    }

    drawGrid() {
        if (!this.isGridVisible) return;

        const gridSize = 20;
        const dotSize = 1;
        this.ctx.save();
        this.ctx.fillStyle = 'lightgray';

        for (let x = 0; x < this.canvas.width; x += gridSize) {
            for (let y = 0; y < this.canvas.height; y += gridSize) {
                this.ctx.beginPath();
                this.ctx.arc(x, y, dotSize, 0, Math.PI * 2, true);
                this.ctx.fill();
            }
        }

        this.ctx.restore();
    }

    drawCross() {
        if (!this.config.isCrossVisible) return;
        
        const dpr = window.devicePixelRatio || 1;
        const centerX = (this.canvas.width / 2) / dpr + this.offsetX;
        const centerY = (this.canvas.height / 2) / dpr + this.offsetY;
        const crossSize = 1024 / dpr; // Adjust the size of the cross based on the dpr

        this.ctx.save();
        this.ctx.strokeStyle = '#e7e7e7';
        this.ctx.lineWidth = 1 / dpr; // Adjust line width based on the dpr

        // Draw vertical line
        this.ctx.beginPath();
        this.ctx.moveTo(centerX, centerY - crossSize / 2);
        this.ctx.lineTo(centerX, centerY + crossSize / 2);
        this.ctx.stroke();

        // Draw horizontal line
        this.ctx.beginPath();
        this.ctx.moveTo(centerX - crossSize / 2, centerY);
        this.ctx.lineTo(centerX + crossSize / 2, centerY);
        this.ctx.stroke();

        this.ctx.restore();
    }

    redrawCanvas() {
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.hitCtx.clearRect(0, 0, this.hitCanvas.width, this.hitCanvas.height);
        
        this.drawLines();
        this.drawRectangles();
        this.drawOtherCursors();
        this.drawCross();

        this.ctx._config = this.config
        this.ctx.dpr = this.dpr
        
        this.drawHitLayer();
    }

    drawHitLayer() {
        this.rectangles.forEach(rect => {
            const { x, y, width, height } = rect;
            const hitColor = this.generateUniqueColor(rect.sequence_id);

            this.hitCtx.fillStyle = hitColor;
            this.hitCtx.fillRect(x * this.zoomLevel + this.offsetX, y * this.zoomLevel + this.offsetY, width * this.zoomLevel, height * this.zoomLevel);

            // Draw the handles' hit areas
              const handles = this.getRectHandles(rect);
              handles.forEach(handle => {
                const handleColor = this.generateUniqueColor(`${rect.sequence_id}-${handle.type}`);
                this.hitCtx.fillStyle = handleColor;
                this.hitCtx.fillRect(
                  handle.x * this.zoomLevel + this.offsetX - handle.size / 2,
                  handle.y * this.zoomLevel + this.offsetY - handle.size / 2,
                  handle.size,
                  handle.size
                );
                this.handles.push({
                    id: handleColor,
                    rectId: rect.sequence_id,
                    handleType: handle.type
                })
              });
        });

        this.lines.forEach((line, index) => {
            const fromRect = this.rectangles.find(rect => rect.sequence_id === line.from.sequence_id);
            const toRect = this.rectangles.find(rect => rect.sequence_id === line.to.sequence_id);
            if (fromRect && toRect) {
                const fromSideCenter = findClosestSideCenter(fromRect, toRect);
                const toSideCenter = findClosestSideCenter(toRect, fromRect);
                const hitColor = this.generateUniqueColor(`line-${index}`);
                
                this.hitCtx.strokeStyle = hitColor;
                this.hitCtx.lineWidth = 10; // Wider line for easier hit detection
                this.hitCtx.beginPath();

                const cp1x = fromSideCenter.x + (toSideCenter.x - fromSideCenter.x) / 2;
                const cp1y = fromSideCenter.y;
                const cp2x = fromSideCenter.x + (toSideCenter.x - fromSideCenter.x) / 2;
                const cp2y = toSideCenter.y;

                this.hitCtx.moveTo(fromSideCenter.x * this.zoomLevel + this.offsetX, fromSideCenter.y * this.zoomLevel + this.offsetY);
                this.hitCtx.bezierCurveTo(
                    cp1x * this.zoomLevel + this.offsetX, cp1y * this.zoomLevel + this.offsetY,
                    cp2x * this.zoomLevel + this.offsetX, cp2y * this.zoomLevel + this.offsetY,
                    toSideCenter.x * this.zoomLevel + this.offsetX, toSideCenter.y * this.zoomLevel + this.offsetY
                );
                this.hitCtx.stroke();
            }
        });

        if (this.hitLayerVisible) {
            this.hitCanvas.style.display = "block"
        } else {
            this.hitCanvas.style.display = "none"
        }
    }

    showHitLayer() {
        this.hitLayerVisible = true;
        this.redrawCanvas();
    }

    hideHitLayer() {
        this.hitLayerVisible = false;
        this.redrawCanvas();
    }

    generateUniqueColor(id) {
      const hash = Array.from(id).reduce((acc, char) => acc + char.charCodeAt(0), 0);
      const r = (hash & 0xFF0000) >> 16;
      const g = (hash & 0x00FF00) >> 8;
      const b = hash & 0x0000FF;
      return `rgb(${r},${g},${b})`;
    }

    getHitElement(mousePos) {
        const dpr = window.devicePixelRatio || 1;
        const x = Math.floor(mousePos.x * dpr);
        const y = Math.floor(mousePos.y * dpr);
        const pixel = this.hitCtx.getImageData(x, y, 1, 1).data;
        if (pixel[3] !== 0) {
            return `rgb(${pixel[0]},${pixel[1]},${pixel[2]})`;
        }
        return null;
    }

    getHandleInfoFromColor(color) {
    const parts = color.match(/\d+/g).map(Number);
    const rectId = `${parts[0]}-${parts[1]}-${parts[2]}`;
    const handleTypes = [
        'top-left', 'top-right', 'bottom-left', 'bottom-right',
        'top', 'bottom', 'left', 'right'
    ];
    const handleType = handleTypes[parts[3] % handleTypes.length];
    return {
        rectId,
        handleType
    };
}

    getResizeHandleFromHit(hitElement) {
        const parts = hitElement.split('-');
        if (parts.length === 2) {
          const [rectId, handleType] = parts;
          return { rectId, handleType };
        }
        return null;
      }

    clearCanvas() {
        this.rectangles = [];
        this.lines = [];
        this.offsetX = 0;
        this.offsetY = 0;
        this.zoomLevel = 1;
        this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.redrawCanvas();
        this.save();
    }

    exportData() {
        const data = {
            rectangles: this.rectangles,
            lines: this.lines,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            zoomLevel: this.zoomLevel
        };
        this.trigger('export_data', JSON.stringify(data, null, 2));
    }

    loadData(data) {
        this.rectangles = data.nodes || [];
        this.lines = [];

        if (data.lines) {
            data.lines.forEach(line => {
                const { from, to } = line;
                this.connectRectanglesFromImport(from.id, to.id);
            });
        }

        this.assignCoordinatesIfNeeded();

        this.redrawCanvas();
        
        this.save()
    }

    importData(data) {
        this.rectangles = data.rectangles || [];
        this.lines = [];
        this.offsetX = data.offsetX || 0;
        this.offsetY = data.offsetY || 0;
        this.zoomLevel = data.zoomLevel || 1;

        if (data.lines) {
            data.lines.forEach(line => {
                const { from, to } = line;
                this.connectRectanglesFromImport(from.id, to.id);
            });
        }

        this.assignCoordinatesIfNeeded();

        this.redrawCanvas();
        
        this.save()
    }

    connectRectanglesFromImport(fromId, toId) {
        const fromRect = this.rectangles.find(rect => rect.sequence_id === fromId);
        const toRect = this.rectangles.find(rect => rect.sequence_id === toId);

        if (fromRect && toRect) {
            this.lines.push({ from: fromRect, to: toRect });
        }
        this.save()
    }

    connectRectangles() {
        const connectionData = JSON.parse(connectTextarea.value);
        const { from, to } = connectionData.connect;

        this.connectRectanglesFromImport(from.id, to.id);
        this.redrawCanvas();
    }

    assignCoordinatesIfNeeded() {
        const dpr = window.devicePixelRatio || 1;
        const rootRectangles = this.rectangles.filter(rect => !this.lines.some(line => line.to.sequence_id === rect.sequence_id));
        const assignedRectangles = new Set();
        let currentX = 0;

        rootRectangles.forEach((rootRect, index) => {
            if (rootRect.x === undefined || !rootRect.x || rootRect.y === undefined || !rootRect.y) {
                this.assignCoordinates(rootRect, currentX / dpr, this.canvas.height / 2 / dpr, assignedRectangles);
                currentX += this.xSpacing;
            }
        });
    }

    assignCoordinates(rect, x, y, assignedRectangles) {
        const dpr = window.devicePixelRatio || 1;
        rect.x = x;
        rect.y = y;
        assignedRectangles.add(rect.sequence_id);

        const children = this.lines.filter(line => line.from.sequence_id === rect.sequence_id).map(line => {
            const toRect = this.rectangles.find(r => r.sequence_id === line.to.sequence_id);
            if (!assignedRectangles.has(toRect.sequence_id)) {
                return toRect;
            }
            return null;
        }).filter(child => child !== null);

        let childY = y - (children.length - 1) * this.ySpacing / 2;

        children.forEach(child => {
            this.assignCoordinates(child, x + this.xSpacing, childY, assignedRectangles);
            childY += this.ySpacing;
        });
    }

    resetItemSize(item) {
        const defaultWidths = {
            'card': 120,
            'pill': 220,
            'email': 200
        };

        const defaultHeights = {
            'card': 60,
            'pill': 36,
            'email': 150
        };

        const defaultWidth = defaultWidths[item.type];
        const defaultHeight = defaultHeights[item.type];
        item.width = defaultWidth;
        item.height = defaultHeight;
    }

    reorderItems() {
        // Reset size of each item to default based on type
        this.rectangles.forEach(rectangle => {
            this.resetItemSize(rectangle);
        });

        // Reassign coordinates
        this.assignCoordinatesIfNeeded();

        // Save updated data to localStorage
        this.save()
        
        // Redraw canvas
        this.redrawCanvas();
    }

    toggleSnap() {
        this.isSnapToGridEnabled = !this.isSnapToGridEnabled;
        const toggleSnapButton = document.getElementById('toggleSnap');
        toggleSnapButton.classList.toggle('active', this.isSnapToGridEnabled);
    }

    editSequence(e) {
        const mousePos = this.getMousePos(e);
        const rectIndex = this.findRectIndexAt(mousePos);
        if (rectIndex !== -1) {
            if (this.selectedRectId !== this.rectangles[rectIndex].sequence_id) {
                this.selectedRectId = this.rectangles[rectIndex].sequence_id;
                this.trigger('node_click', this.rectangles[rectIndex]);
            }
        }
    }

    onDoubleClick(e) {
        this.editSequence(e)
    }

    onClick(e) {
        const mousePos = this.getMousePos(e);
        const rectIndex = this.findRectIndexAt(mousePos);

        if (rectIndex !== -1) {
            const rect = this.rectangles[rectIndex];
            if (rect.type === 'text') {
                if (this.config.editTextOnCanvas) this.showTextInput(rect, mousePos);
            }

            this.editSequence(e);
            this.trigger('update_rect', {
                ...rect
            });
        }
    }

    showTextInput(rect, mousePos) {
        const input = document.createElement('input');
        input.type = 'text';
        input.value = rect.text;
        input.style.position = 'absolute';
        input.style.left = `${mousePos.x}px`;
        input.style.top = `${mousePos.y}px`;
        input.style.fontSize = `${rect.fontSize}px`;
        input.style.fontWeight = rect.fontWeight;
        input.style.fontStyle = rect.fontStyle;
        input.style.color = rect.color;
        input.style.background = 'transparent';
        input.style.border = '1px solid blue';
        input.style.zIndex = 1000;

        document.body.appendChild(input);
        input.focus();

        input.addEventListener('blur', () => {
            this.updateTextRectangle(rect, input.value);
            document.body.removeChild(input);
        });

        input.addEventListener('keydown', (e) => {
            if (e.key === 'Enter') {
                input.blur();
            }
        });
    }

    updateTextRectangle(rect, newText) {
        rect.text = newText;
        this.redrawCanvas();

        this.socketHandler.updateRectangle(rect, newText);

        this.trigger('update_rect', rect);

        this.save();
    }

    updateRectangleById(rectId, data) {
        const rectIndex = this.rectangles.findIndex(r => r.sequence_id == rectId);

        if (rectIndex !== -1) {
            // Iterate through each field in the data object and update the corresponding field in rect
            for (const key in data) {
                if (data.hasOwnProperty(key) && this.rectangles[rectIndex].hasOwnProperty(key)) {
                    this.rectangles[rectIndex][key] = data[key];
                }
            }
            this.hideCircles();
            this.redrawCanvas();
        } else {
            console.error(`Rectangle with id ${rectId} not found`);
        }
    }

		updateRectangle(items) {
		    const selectedItemKey = itemSelect.value;
		    const selectedItem = this.items.find(item => item.key === selectedItemKey);
		    const subtitle = subtitleInput.value;
		    const memberImageUrl = memberSelect.value;
		    const status = statusSelect.value;

		    let width, height;

		    if (selectedItem.type === 'card') {
		        width = 200; // Assign card width
		        height = 100; // Assign card height
		    } else if (selectedItem.type === 'pill') {
		        width = 200; // Assign pill width
		        height = 32; // Assign pill height
		    }

		    if (this.selectedRectId) {
		        const rect = this.rectangles.find(r => r.sequence_id === this.selectedRectId);
		        if (rect) {
		            rect.title = subtitle;
		            rect.subtitle = selectedItem.title;
                    rect.displayData = selectedItem.displayData;
		            rect.key = selectedItemKey;
		            rect.imageUrl = memberImageUrl ? memberImageUrl : null;
		            rect.svg = selectedItem.app ? selectedItem.app.svg : null;
		            rect.appName = selectedItem.app ? selectedItem.app.name : null;
		            rect.brandColor = selectedItem.app ? selectedItem.app.brandColor : null;
		            rect.status = status;
		            rect.width = width; // Include width
		            rect.height = height; // Include height
		            rect.type = selectedItem.type;
		            this.redrawCanvas();
		        }
		    }
		    modal.style.display = 'none';
		    this.selectedRectId = null;
		}

		updateDataTextarea() {
        const data = {
            rectangles: this.rectangles,
            lines: this.lines,
            offsetX: this.offsetX,
            offsetY: this.offsetY,
            zoomLevel: this.zoomLevel
        };

        this.trigger('export_data', JSON.stringify(data, null, 2));
    }

    listen(event, callback) {
        if (!this.events[event]) {
          this.events[event] = [];
        }
        this.events[event].push(callback);
      }

      trigger(event, data) {
        if (this.events[event]) {
          this.events[event].forEach(callback => callback(data));
        }
      }

    updateZoom(zoomDelta, mouseX = null, mouseY = null) {
        const currentZoom = this.zoomLevel;
        this.zoomLevel *= zoomDelta;

        if (mouseX !== null && mouseY !== null) {
            const canvasRect = this.canvas.getBoundingClientRect();
            const canvasX = (mouseX - canvasRect.left - this.offsetX) / currentZoom;
            const canvasY = (mouseY - canvasRect.top - this.offsetY) / currentZoom;

            this.offsetX -= canvasX * (this.zoomLevel - currentZoom);
            this.offsetY -= canvasY * (this.zoomLevel - currentZoom);
        }

        this.redrawCanvas();
    }

    setZoom(newZoomLevel, mouseX = null, mouseY = null) {
        // Save the current canvas state
        const currentZoom = this.zoomLevel;
        // Update the zoom level
        this.zoomLevel = newZoomLevel;

        // If zooming on a specific point (like the mouse position)
        if (mouseX !== null && mouseY !== null) {
            // Calculate the offset adjustment for smooth zooming centered on the mouse position
            const canvasRect = this.canvas.getBoundingClientRect();
            const canvasX = (mouseX - canvasRect.left - this.offsetX) / currentZoom;
            const canvasY = (mouseY - canvasRect.top - this.offsetY) / currentZoom;

            this.offsetX -= canvasX * (this.zoomLevel - currentZoom);
            this.offsetY -= canvasY * (this.zoomLevel - currentZoom);
        }

        // Redraw the canvas with the updated zoom level
        this.redrawCanvas();
    }

    getLineBoundingBox(x1, y1, x2, y2) {
        const padding = 5; // Add some padding around the line

        const minX = Math.min(x1, x2) - padding;
        const minY = Math.min(y1, y2) - padding;
        const maxX = Math.max(x1, x2) + padding;
        const maxY = Math.max(y1, y2) + padding;

        return {
            x: minX,
            y: minY,
            width: maxX - minX,
            height: maxY - minY
        };
    }

    drawBoundingBox(box) {
        this.ctx.save();
        this.ctx.strokeStyle = 'rgba(0, 0, 255, 0.5)'; // Light blue color for the bounding box
        this.ctx.lineWidth = 1;
        this.ctx.strokeRect(box.x * this.zoomLevel + this.offsetX, box.y * this.zoomLevel + this.offsetY, box.width * this.zoomLevel, box.height * this.zoomLevel);
        this.ctx.restore();
    }

    remove_selected_rects_from_frame() {
        this.selectedRectangles.forEach(rect => {
            rect.parent = null;
        });
    }

    handle_removing_selected_rects_from_frame(mousePos) {
        if (!this.holdingRectangle) return
        const hoveringFrame = this.isHoveringOverFrame(mousePos);

        if (!hoveringFrame) {
            this.remove_selected_rects_from_frame();
        }
    }

    handle_selected_rects_on_frame(mousePos) {
        const hoveringFrame = this.isHoveringOverFrame(mousePos);

        if (hoveringFrame) {
            this.selectedRectangles.forEach(rect => {
                rect.parent = hoveringFrame.sequence_id;
            });
        } else {
            this.remove_selected_rects_from_frame()
        }
    }

}

export default CanvasApp;
