<template>
	<div class="ui-video">
		<div class="aspect-ratio">
			<div class="content">
				<div class="video img" :style="`background: url(${thumbnail});background-position: center;background-size: cover;`">
					<div class="play-button" @click="play" v-if="!showVideo">
						<IconPlayButton color="#fff"></IconPlayButton>
					</div>
				    <iframe v-if="showVideo" width="100%" height="100%" :src="youtube" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
				</div>
			</div>
		</div>
		<template v-if="description">
			<br>
			{{ description }}
		</template>
	</div>
</template>

<script setup>
	import { ref } from "vue"
	import { IconPlayButton } from "../icons"

	const props = defineProps({
		thumbnail: String,
		youtube: String,
		description: String
	})

	const showVideo = ref(false)

	const play = () => {
		showVideo.value = true
	}
</script>

<style scoped>
	.video {
  width: 100%;
  max-width: 700px;
  height: 100%;
  filter: var(--s1-img_filter);
  background: #000;
  border-radius: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #f3f4f6;
}

.video .play-button {
  height: 56px;
  width: 56px;
  cursor: pointer;
  transition: transform .1s;
}

.video .play-button:hover {
	transform: scale(1.125);
	transition: transform .3s;
}
</style>