<template>
	<div class="chat-popup">
		<div class="chat-popup-chat">
			<div class="chat-popup-header">
				<div>
					Support Chat
				</div>
				<!-- <div class="chat-popup-close-btn"></div> -->
			</div>
			<UiChatMessages class="messages" :messages="messages" :is-typing="isTyping">
				<UiChatMessage :is-sender="message.author.object_id === user_object_id" v-for="message in messages" :key="message.id" :message="message.message" :author="message.author">

						<!-- <UiAlert class="message-box" :color="message.messageColor" style="margin-bottom: 24px;">
				      <template v-slot:title v-if="message.title">{{ message.title }}</template>
				      <template v-slot:text><div v-html="message.message"/></template>
				    </UiAlert> -->
				    <div class="message-loading" v-if="message.loading">
				    	<UiLoadingSpinner></UiLoadingSpinner> Processing your request
				    </div>

				    <div v-if="message.data && message.data.task && (message.data.task.confirmed || message.data.task.result)" style="margin-bottom: 32px;">
					  	<div style="display: flex;">
						  	<UiCard type="flats" style="font-size: 16px;font-size: 16px;border: 1px solid #e7e7e7;border-radius: 12px;max-width: 512px;margin-top: 32px;position: relative;overflow: auto;">
						  		<!-- <div v-if="message.data.task.title">
						  			<UiLabel>Task</UiLabel>
						  			<div>{{message.data.task.title}}</div>
						  		</div> -->

						  		<!-- Task Title -->
						  		<div v-if="message.data.task.description">
						  			<UiLabel>Task</UiLabel>
						  			<div>{{message.data.task.description}}</div>
						  		</div>

						  		<!-- Task Schedule -->
						  		<div v-if="message.data.task.schedule" style="margin-top: 8px;">
						  			<UiLabel>Schedule</UiLabel>
						  			<template v-if="message.data.task.schedule">
							  			<div v-if="message.data.task.schedule.days">
							  				Weekly on the following day<template v-if="message.data.task.schedule.days.length > 1">s</template>: <span v-for="day in message.data.task.schedule.days" style="color: blue;">{{ day }}, </span> <template v-if="message.data.task.schedule.time">at <span style="color: blue;">{{ message.data.task.schedule.time }}</span></template>
							  			</div>
							  			<div v-if="message.data.task.schedule.interval_minutes">
							  				Every {{ message.data.task.schedule.interval_minutes }} Minute<template v-if="message.data.task.schedule.interval_minutes > 0">s</template>
							  			</div>
						  			</template>
						  		</div>

						  		<!-- Table -->
						  		<div v-if="message.data.task.result && message.data.task.result.table">
								    <table v-if="isArray(message.data.task.result.table)" style="font-size: 14px;">
								      <tr v-for="row in message.data.task.result.table" :key="row.id">
								        <td v-for="(cell, index) in row" :key="index" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
								          {{ cell }}
								        </td>
								      </tr>
								    </table>

								    <table v-if="isObject(message.data.task.result.table)" style="font-size: 14px;">
								      <tr v-for="(value, key) in message.data.task.result.table" :key="key">
								        <td style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ key }}</td>
								        <td style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ value }}</td>
								      </tr>
								    </table>
								  </div>

								  <!-- Status -->
						  		<UiBadge type="success" v-if="message.data.task.updated" style="margin-top: 8px;">Updated</UiBadge>
						  			
						  		<!-- CTA -->
						  		<div style="display: flex;justify-content: right;">
						  			<UiButton color="outline" size="s">View</UiButton>
						  		</div>
						  	</UiCard>
					  	</div>
					  </div>

					  <div v-if="message.type === 'form'">
					  	<UiCard>
					  		<FormGenerator :schema="message.formSchema" @submit="onSubmitForm" :form-key="message.data.task"></FormGenerator>
					  	</UiCard>
					  </div>
					  <div v-if="message.type === 'select-option' && message.data">
					  	<div style="display: flex;">
						  	<UiCard v-for="option in message.data.options" type="flat">
						  		<div>{{ option.title }}</div>
						  		<div>{{ option.text }}</div>
						  		<div v-if="option.FIELDS">
						  			<div v-for="(item, key) in option.FIELDS">
						  				<UiInput :modelValue="item" :label="key"></UiInput>
						  			</div>
						  		</div>
						  		<UiButton color="outline">Select</UiButton>
						  	</UiCard>
					  	</div>
					  </div>
					  <div v-if="message.type === 'select'">
					  	<div style="display: flex;">
						  	<UiCard v-for="option in message.options" type="flat">
						  		<UiButton color="outline">{{ option.title }}</UiButton>
						  	</UiCard>
					  	</div>
					  </div>
					  <template v-if="message.type === 'iframe'">
					  	<UiCard type="flat">
					  		<div class="message-iframe-wrapper">
					  			<iframe class="message-iframe" :src="message.websiteUrl"></iframe>
					  		</div>
					  		<UiButton color="outline">Yes</UiButton>
					  		<UiButton color="outline">No</UiButton>
					  	</UiCard>
					  </template>

		  	</UiChatMessage>
	  		<template v-slot:footer>
	  			<!-- <FormGenerator :schema="currentForm.formSchema" @submit="onSubmitForm" :form-key="currentForm.key" v-if="currentForm"></FormGenerator> -->
	  			<UiInput v-model="message" @enter="sendMessage"></UiInput>
	  		</template>
	  	</UiChatMessages>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, watch } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"
	import socket from "@lib/services/socket";
	import cookieService from '@lib/services/cookie';

	const store = useStore()
	const route = useRoute()

	const props = defineProps({
		// chat_id: String
	})
	
	const virt_id = ref(51)
	const messages = ref([])
	const message = ref("")
	const isTyping = ref(false)

	// const user_id = computed(() => store.state.user.user ? store.state.user.user.id : null)
	const chat_object_id = computed(() => route.query.session)
	const user_object_id = computed(() => route.query.user)

	const fetchChatMessages = (id) => {
  	store.dispatch('virt/fetchChatMessages', {
  		id: chat_object_id.value
  	}).then((res) => {
  		messages.value = res.data
  	}).catch(() => {})
  }

  fetchChatMessages()

	const sendMessage = () => {
		if (isTyping.value) return
    if (message.value.trim() !== "") {
      socket.emit("message", {
      	msg: message.value, 
      	chat_id: chat_object_id.value, 
      	virt_id: virt_id.value, 
      	user_id: user_object_id.value,
      	chat_type: 'support',
      	organization_id: "6666915f8f837b9e3a7c3309"
      });
      message.value = "";
      setTimeout(() => {
      	isTyping.value = true
      }, 1000)
    }
  }

  socket.on("message", (data) => {
    messages.value.push(data);

    isTyping.value = false
  });

  socket.on("chat_created", (data) => {
    sendSessionIdToParent(data)
  });

  function sendSessionIdToParent(data) {
	  window.parent.postMessage({ action: 'updateSessionId', sessionId: data.chat_id, userId: data.user_id }, '*');
	}

	var sessionId = 'your-session-id';

	if (!route.query.session) {
		//sendSessionIdToParent(sessionId);
	}

  watch(() => route.query.session, () => {
    console.log('#session')
  })
</script>

<style scoped>
	.chat-popup {
		position: absolute;
		bottom: 0;
		height: 100%;
		right: 0;
		width: 100%;
	}
	.chat-popup.expanded {

	}
	.chat-popup-chat {
		background: #fff;
		padding: 4px 8px;
		border-radius: 6px;
		min-width: 100px;
		/*box-shadow: 0 4px 8px rgba(0, 12, 43, .05);
    -webkit-backdrop-filter: blur(15.5px);*/
    backdrop-filter: blur(15.5px);
/*    border: 1px solid rgb(231, 231, 231);*/
    display: flex;
    height: 100%;
    padding-top: 40px;
    padding-bottom: calc(87px + 8px);
    position: relative;
	}
	.chat-popup-header {
		position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 6px 12px 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
		background: rgb(255,255,255);
		background: linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 20%);
		display: flex;
		justify-content: space-between;
	}
	.chat-popup-close-btn {
    height: 24px;
    width: 24px;
    border-radius: 8px;
    border: 1px solid #e7e7e7;
	}
	.chat-popup-close-btn:hover {
		background: #e7e7e7;
		cursor: pointer;
	}
</style>