<template>
	<component :is="element" :to="to" :href="href" :target="target" :class="classes" @click="onClick">
		<UiRow gap="2" align="center" justify="center">
			<UiLoadingSpinner v-if="loading"></UiLoadingSpinner>
			<slot></slot>
		</UiRow>
	</component>
</template>

<script setup>
	import { computed, defineEmits } from "vue"
	import { useRouter } from "vue-router"
	import UiLoadingSpinner from "../LoadingSpinner/LoadingSpinner"
	import UiRow from "../Row/Row"

	const router = useRouter()

	const emit = defineEmits(["clicked"])

	const props = defineProps({
		size: {
			type: String,
			default: "m"
		},
		color: {
			type: String,
			default: "primary"
		},
		full: Boolean,
		round: Boolean,
		to: String,
		href: String,
		target: String,
		as: {
	      type: String,
	      default: 'div'
	    },
	    loading: Boolean,
	    disabled: Boolean
	})

	const classes = computed(() => [
		"btn",
		`btn-${props.size}`,
		`btn-${props.color}`,
		props.full && 'btn-full',
		props.round && 'btn-round',
		props.disabled && 'btn-disabled'
	])

	const element = computed(() => {
		if (props.to) {
	        return "router-link";
	     }
	    if (props.href) {
	        return "a";
	     }
	      
	      return props.as;
	})

	const onClick = () => {
		if (props.disabled) return

		emit("clicked")

		if (props.to) router.push(props.to)
	}
</script>

<style>
	.btn {
	  background: var(--brand-color);
    color: #313131;
    border-radius: 12px;
    padding: 16px 32px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.21px;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
	}

	.btn-s {
	  padding: 10px 24px;
	  font-weight: 600;
	  line-height: 20px;
	  font-size: 14px;
	}

	.btn-l {
	  padding: 16px 32px;
	  font-size: 16px;
	}

	.btn-full {
	  width: 100%;
	}

	.btn-inv {
	  background: #ffffff;
	  color: var(--brand-color);
	}

	.btn-link {
	  background: transparent;
	  color: var(--brand-color);
	}

	.btn-link:hover {
	  background: #f6f7f7;
	}

	.btn-black {
	  background: #171C1A;
	  color: #fff;
	}

	.btn-gray {
	  background: #f3f3f3;
	  color: var(--brand-color);
	}

	.btn-gray .simple-spinner span {
	  border-right-color: var(--brand-color);
	}

	.btn-outline {
	  border: 1px solid #e7e7e7;
    background-color: var(--white);
    box-shadow: inset 0 -2px 0 0 #f9fafb, 0 1px 2px 0 rgba(16, 24, 40, .06);
    color: #000;
	}

	.btn-disabled {
	  background: #f3f3f3;
	  color: #cdcdcd;
	  border: none;
	  box-shadow: none;
	}

	.btn-disabled .simple-spinner span {
	  border-right-color: var(--brand-color);
	}

	.btn-cta {
	  background: rgb(68,227,148);
	  background: linear-gradient(90deg, rgba(68,227,148,1) 0%, rgba(44,228,137,1) 100%);
	}
	.btn-full {
		width: 100%;
	}
	.btn-success {
		background: #87F57D;
		color: #000;
	}
	.btn-danger {
		background: #FF2C46;
		color: #fff;
	}
	.btn-round {
		border-radius: 999px;
	}
</style>