<template>
	<div class="card" :class="{'has-head':title,'card-flat':type=='flat'}">
        <div class="card-head" @click="toggle" v-if="title || $slots.head" :class="{'head-sticky':headSticky}">
            <slot name="head"></slot>
            <UiHeadline size="m" v-if="title">
                {{ title }}
            </UiHeadline>
            <div class="collapse" v-if="collapsible"></div>
        </div>
        <div class="card-body" v-if="!collapsed">
		  <slot></slot>
        </div>
        <template v-if="$slots.footer">
            <div class="card-footer" :class="{'footer-sticky':footerSticky}">
              <slot name="footer"></slot>
            </div>
        </template>
	</div>
</template>

<style scoped>
.card {
    background: #fff;
    border: 1px solid #f3f4f6;
    border-radius: 16px;
    box-shadow: 0 4px 8px rgba(0, 12, 43, .05);
    backdrop-filter: blur(15.5px);
    width: 100%;
    position: relative;
}

.card-elevated {
    box-shadow: 0 4px 8px rgba(0,12,43,.05);
}

.card-head {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    padding: 20px;
    align-items: center;
}

.head-sticky {
    position: sticky;
    top: 0;
    background: #fff;
    padding: 12px;
}

.card-body {
    padding: 20px;
}

.card-footer {
    background: #ffffff;
    padding: 12px;
    border-top: 1px solid #e7e7e7;
}

.footer-sticky {
    position: sticky;
    bottom: 0;
}

.card-flat {
    border: none;
    border-radius: 0px;
    box-shadow: none;
}

.has-head .card-body {
    padding-top: 1rem;
}

.collapse {
    width: 32px;
    height: 32px;
    border-radius: 999px;
    background: #AFAFAF80;
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 14px;
}
</style>

<script setup>
    import { ref } from "vue"
    import UiHeadline from "../Headline/Headline"

    const collapsed = ref(false)

    const props = defineProps({
        title: String,
        collapsible: Boolean,
        expanded: Boolean,
        type: String,
        footerSticky: Boolean,
        headSticky: Boolean
    })

    const toggle = () => {
        if(props.collapsible) collapsed.value = !collapsed.value
    }

    if (!props.expanded && props.collapsible) collapsed.value = true
</script>