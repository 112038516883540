<template>
	<div class="studio-sidebar-right">
		<slot></slot>
	</div>
</template>

<style>
.studio-sidebar-right {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
    border: 1px solid rgb(231, 231, 231);
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    z-index: 1000;
    height: calc(100vh + 2px);
    overflow: auto;
    display: block;
    pointer-events: all;
    position: fixed;
    top: -1px;
    right: 0px;
    width: 320px;
    }
    input, select {
    border: 1px solid #e7e7e7;
    height: 48px;
    border-radius: 12px;
    font-size: 18px;
    padding: 0 16px;
    color: #000;
    font-family: 'Cera';
}
</style>