import { createStore } from 'vuex';

import baseModules from '@lib/store';
import contact from "./contact"
import manage from "./manage"
import payment from "./payment"
import virt from "./virt"
import studio from "./studio"

export default createStore({
  state: {
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    ...baseModules,
    contact,
    payment,
    manage,
    virt,
    studio
  },
});
