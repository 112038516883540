<template>
        <div :class="classes" v-click-outside="onClickOutside" :style="`width: 100%;max-width: ${size};width: 100%;`">
                <div class="modal-head">
                        <div></div>
                        <div @click="$emit('close')">X</div>
                </div>
                <div class="modal-content">
                        <slot></slot>
                </div>
        </div>
</template>

<script setup>
        import { defineEmits, computed } from "vue"

        const emit = defineEmits(['close'])

        const props = defineProps({
            title: String,
            size: {
                    default: '512px',
                    type: String
            },
            height: {
                    default: 'auto',
                    type: String
            },
            fullHeight: {
            default: false,
            type: Boolean,
            autoClose: {
              type: Boolean,
              default: false
            }
        }
        })

        const onClickOutside = () => {
            if (!props.preventClose && props.autoClose) {
              emit("close")
            }
          }

          document.addEventListener('keydown', function(event) {
      if (event.key === 'Escape') {
          emit("close")
      }
  });

          const classes = computed(() => {
        return [
            "modal",
            props.fullHeight && "modal-full-height"
        ]
    })
</script>

<style scoped>
                        .modal {
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 24px;
    z-index: 1000;
    width: 512px;
    padding: 32px;
    height: auto;
        max-height: calc(100% - 8px);
        overflow: auto;

        display: block;
        pointer-events: all;
/*    transform: skew(348deg, 10deg) scale(.5);*/
    }

    .modal-content {
        display: flex;
        flex-direction: column;
    }

    .modal-full-height {
        height: 100%;
    }
    .modal-head {
        display: flex;
        justify-content: space-between;
    }
</style>