<template>
  <div :class="['navigation', { 'navigation-fixed': fixed }]">
    <div class="nav-left">
      <slot name="logo"></slot>
    </div>
    <div class="nav-center">
      <li class="nav-item" v-for="item in items" :key="item.key">
        <router-link :to="item.route">
          <div class="nav-item-icon-wrapper">
            <!-- <UiIcon :icon-name="item.icon" /> -->
            <div v-if="item.notifications && item.notifications > 0" class="notification-circle"></div>
          </div>
          <div>{{ item.name }}</div>
        </router-link>
        <ul v-if="item.children && item.children.length" class="dropdown">
          <li v-for="child in item.children" :key="child.key" class="dropdown-item">
            <router-link :to="child.route">
              <div class="nav-item-icon-wrapper">
                <!-- <UiIcon :icon-name="child.icon" /> -->
              </div>
              <div>{{ child.name }}</div>
            </router-link>
          </li>
        </ul>
      </li>
    </div>
    <div class="nav-right">
      <div class="user-dropdown">
        <UiAvatar :imgUrl="userAvatar" @click="toggleDropdown" />
        <div v-if="dropdownOpen" class="dropdown-menu">
          <slot name="dropdown-menu"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import UiAvatar from '../Avatar/Avatar';
import UiIcon from '../Icon/Icon'; // Ensure you import the UiIcon component

const props = defineProps({
  items: Array,
  userAvatar: {
    type: String,
  },
  fixed: {
    type: Boolean,
    default: false,
  },
});

const dropdownOpen = ref(false);

const toggleDropdown = () => {
  dropdownOpen.value = !dropdownOpen.value;
};
</script>

<style scoped>
.navigation {
  display: flex;
  position: fixed;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  background-color: #fff;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
  border-bottom: 1px solid rgb(231, 231, 231);
  height: var(--navigation_height);
  z-index: 128;
  top: 0;
}

.nav-left,
.nav-center,
.nav-right {
  display: flex;
  align-items: center;
}

.nav-center {
  flex: 1;
  justify-content: center;
}

.user-dropdown {
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
}

.navigation-fixed {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
}

.nav-item {
  text-decoration: none;
  color: inherit;
  border-radius: 6px;
  transition: background 0.3s;
  cursor: pointer;
  position: relative;
  list-style: none;
}

.nav-item:hover {
  background: #e9ecef;
}

.nav-item a {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;
  padding: 10px;
}

.nav-item-icon-wrapper {
  position: relative;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: #dffc8c;
}

.nav-item-icon {
  color: #000;
}

.notification-circle {
  position: absolute;
  top: -4px;
  right: -4px;
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 50%;
}

.dropdown {
  display: none;
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 10px;
  list-style: none;
}

.dropdown-item {
  cursor: pointer;
  border-radius: 6px;
  transition: background 0.3s;
}

.dropdown-item:hover {
  background: #e9ecef;
}

.dropdown-item a {
  display: flex;
  gap: 10px;
  align-items: center;
  color: #000;
}

.nav-item:hover .dropdown {
  display: block;
}

@media (max-width: 768px) {
  .navigation {
    flex-direction: column;
  }
  .nav-center {
    order: 2;
  }
  .nav-right {
    order: 3;
  }
  .nav-left {
    order: 1;
  }
}
</style>
