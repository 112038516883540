import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";

const Api = {
  fetchSequence(payload) {
    return http.get(`/studio/sequences/${payload.id}`);
  },
  fetchSequenceEvent(payload) {
    return http.get(`/virt-tasks/sequence/${payload.id}`);
  },
  fetchEventOptions(payload) {
    return http.get(`/studio/event/options`);
  },
  updateSequence(payload) {
    return http.put(`/studio/sequences/events/${payload.id}`, payload.data);
  },
  createSequenceEvent(payload) {
    return http.post(`/studio/sequences/${payload.id}/events`, payload.data);
  },
  createSequenceLine(payload) {
    return http.post(`/studio/sequences/${payload.id}/lines`, payload.data);
  },
  removeSequenceLine(payload) {
    return http.post(`/studio/sequences/${payload.id}/lines/remove`, payload.data);
  },
  updateSequenceData(payload) {
    return http.put(`/studio/sequences/events/${payload.id}/data`, payload.data);
  },
  updateSequenceEvents(payload) {
    return http.put(`/studio/sequences/${payload.id}/batch-update-canvas`, payload.data);
  },
  runTrigger(payload) {
    return http.post(`/studio/sequences/events/${payload.id}/trigger`, payload.data);
  },
  runSequenceQuery(payload) {
    return http.post(`/studio/sequences/events/${payload.id}/query`);
  },
  fetchCrafts() {
    return http.get(`/studio/crafts`);
  },
  createCraft(payload) {
    return http.post(`/virt-tasks`, payload.data);
  },
  updateCraftName(payload) {
    return http.put(`/virt-tasks/${payload.id}`, payload.data);
  },
  fetchEmailTemplates(payload) {
    return http.get("/studio/email-templates");
  },
};

const studio = {
  namespaced: true,
  state: {
    sequence: null,
    eventOptions: [],
    sequenceEvent: null,
    crafts: [],
    emailTemplates: []
  },
  getters: {
    getEventOptions: (state) => state.eventOptions,
    getCrafts: (state) => state.crafts.map(c => ({...c, name: c.title})).reverse(),
    getEmailTemplatesForDropdownList: (state) => state.emailTemplates
  },
  mutations: {
    updateSequence: (state, payload) => {
      state.sequence = payload
    },
    updateSequenceEvent: (state, payload) => {
      state.sequenceEvent = payload
    },
    updateEventOptions: (state, payload) => {
      state.eventOptions = payload
    },
    updateCrafts: (state, payload) => {
      state.crafts = payload
    },
    addCraft: (state, payload) => {
      state.crafts.push(payload)
    },
    updateEmailTemplates: (state, payload) => {
      state.emailTemplates = payload
    },
  },
  actions: {
    fetchEventOptions: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchEventOptions(payload)
          .then((res) => {
            state.commit("updateEventOptions", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchSequence: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchSequence(payload)
          .then((res) => {
            state.commit("updateSequence", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchSequenceEvent: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchSequenceEvent(payload)
          .then((res) => {
            state.commit("updateSequenceEvent", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateSequence: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.updateSequence(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createSequenceEvent: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.createSequenceEvent(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateSequenceData: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.updateSequenceData(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createSequenceLine: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.createSequenceLine(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    removeSequenceLine: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.removeSequenceLine(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateSequenceEvents: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.updateSequenceEvents(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    runSequenceQuery: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.runSequenceQuery(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    runTrigger: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.runTrigger(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchCrafts: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchCrafts(payload)
          .then((res) => {
            state.commit("updateCrafts", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    createCraft: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.createCraft(payload)
          .then((res) => {
            state.commit("addCraft", {
              object_id: res.data.object_id,
              title: res.data.title,
            });
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateCraftName: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.updateCraftName(payload)
          .then((res) => {
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchEmailTemplates: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchEmailTemplates(payload)
          .then((res) => {
            state.commit("updateEmailTemplates", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  },
};

export default studio;