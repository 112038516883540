<template>
  <div class="pagination">
    <UiButton @click="prevPage" :disabled="currentPage === 1" size="s" color="outline">Previous</UiButton>
    <span>Page {{ currentPage }} of {{ totalPages }}</span>
    <UiButton @click="nextPage" :disabled="currentPage === totalPages" size="s">Next</UiButton>
    <UiDropdown :items="itemsPerPageOptions" @select="updateItemsPerPage" align="top">
      <template #default>
        <span>{{ itemsPerPage }} items per page</span>
      </template>
    </UiDropdown>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref } from 'vue';
import UiDropdown from '../Dropdown/Dropdown';
import UiButton from '../Button/Button';

const props = defineProps({
  currentPage: {
    type: Number,
    required: true,
    default: 1
  },
  totalPages: {
    type: Number,
    required: true,
    default: 1
  },
  itemsPerPage: {
    type: Number,
    required: true,
    default: 10
  },
  itemsPerPageOptions: {
    type: Array,
    default: () => [10, 20, 50, 100]
  }
});

const emit = defineEmits(['update:currentPage', 'update:itemsPerPage']);

const prevPage = () => {
  if (props.currentPage > 1) {
    emit('update:currentPage', props.currentPage - 1);
  }
};

const nextPage = () => {
  if (props.currentPage < props.totalPages) {
    emit('update:currentPage', props.currentPage + 1);
  }
};

const updateItemsPerPage = (items) => {
  emit('update:itemsPerPage', items);
};
</script>

<style scoped>
.pagination {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}

button {
  padding: 8px 16px;
  border: none;
  background-color: #007bff;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

span {
  font-size: 16px;
}
</style>
