<template>
	<div class="ui-select-group">
		<label v-for="option in options">
			<input v-model="model" type="checkbox" :name="name" :value="option.value">
			{{ option.name }}
		</label>
	</div>
</template>

<script setup>
	import { ref, watch, watchEffect, defineEmits } from "vue"

	const props = defineProps({
		name: String,
		options: Array,
		modelValue: {
	      type: Object,
	      default: () => ([])
	    },
	})

	const model = ref(props.modelValue)

	const emit = defineEmits(['update:modelValue'])

	watchEffect(() => {
      emit('update:modelValue', model.value);
    });

    watch(() => model.value, (newModel) => {
      emit('update:modelValue', model.value);
    });
</script>

<style scoped>
	.ui-select-group {
		display: flex;
		gap: 4px;
		flex-wrap: wrap;
	}

	.ui-select-group label {
		height: 52px;
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 6px 16px;
    width: 100%;
    background: #fff;
    font-size: 19px;
    color: #000;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.76px;
    display: flex;
    align-items: center;
    cursor: pointer;
	}
</style>