<template>
  <div class="zoom-control">
    <div class="zoom-control-btn" @click="zoomOut" :disabled="zoom <= 0.1">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M5 12h14" />
      </svg>
    </div>
    <div :class="['zoom-display', { 'zoom-display-small': zoom >= 1 }]" @click="toggleDropdown">
      {{ displayZoom }}
      <div v-if="showDropdown" class="dropdown">
        <div 
          v-for="percentage in percentages" 
          :key="percentage" 
          @click="setZoom(percentage / 100)"
          class="dropdown-item"
        >
          {{ percentage }}
        </div>
      </div>
    </div>
    <div class="zoom-control-btn" @click="zoomIn" :disabled="zoom >= 2">
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-6">
        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
      </svg>
    </div>
  </div>
</template>

<script>
import { ref, watch, defineProps, defineEmits, computed } from 'vue';

export default {
  name: 'ZoomControl',
  props: {
    modelValue: {
      type: Number,
      default: 1,
    },
  },
  setup(props, { emit }) {
    const zoom = ref(props.modelValue);
    const showDropdown = ref(false);
    const percentages = [25, 50, 75, 100];

    const zoomIn = () => {
      if (zoom.value < 2) {
        zoom.value = parseFloat((zoom.value + 0.1).toFixed(1));
        emit('update:modelValue', zoom.value);
      }
    };

    const zoomOut = () => {
      if (zoom.value > 0.1) {
        zoom.value = parseFloat((zoom.value - 0.1).toFixed(1));
        emit('update:modelValue', zoom.value);
      }
    };

    const setZoom = (value) => {
      zoom.value = value;
      showDropdown.value = false;
      emit('update:modelValue', zoom.value);
    };

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const displayZoom = computed(() => Math.round(zoom.value * 100));

    watch(() => props.modelValue, (newValue) => {
      zoom.value = newValue;
    });

    return {
      zoom,
      showDropdown,
      percentages,
      zoomIn,
      zoomOut,
      setZoom,
      toggleDropdown,
      displayZoom,
    };
  },
};
</script>

<style scoped>
.zoom-control {
  display: flex;
  align-items: center;
  padding: 2px;
  flex-direction: column;
}

.zoom-display {
  position: relative;
  cursor: pointer;
  width: 40px;
  font-size: 16px;
  text-align: center;
  transition: font-size 0.3s;
}

.zoom-display-small {
  font-size: 12px;
}

.dropdown {
    position: absolute;
    top: 0;
    right: 36px;
    background: white;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  padding: 5px 10px;
  cursor: pointer;
}

.dropdown-item:hover {
  background: #f0f0f0;
}

button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.zoom-control-btn {
  width: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  border-radius: 999px;
}

.zoom-control-btn:hover {
  background: #f4f6f9;
}

.zoom-control-btn svg {
  height: 20px;
}
</style>
