import http from "@lib/services/http";
import cookie from "../../services/cookie";

const UserApi = {
  updateProfilePicture(payload) {
    return http.put("/v1/user/profile-picture", payload.files);
  },
  updateConfig(payload) {
    return http.post("/v1/user/config", {
      config: payload
    });
  }
}

const user = {
  namespaced: true,
  state: {
    user: null,
  },
  getters: {
    get: (state) => {
      return state.user;
    },
  },
  mutations: {
    updateUser: (state, payload) => {
      state.user = payload;
      if (window.fcWidget) {
        window.fcWidget.setExternalId(payload.id);
      }
    },
    clearUser: (state) => {
      state.user = null;
    },
  },
  actions: {
    updateProfilePicture: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        UserApi.updateProfilePicture(payload)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    updateConfig: ({ commit }, payload) =>
      new Promise((resolve, reject) => {
        UserApi.updateConfig(payload)
          .then((response) => {
            commit("dashboard/updateVersion", payload, { root: true });
            
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  }
};

export default user;