import { createRouter, createWebHistory } from 'vue-router';
import store from "../store";
import HomeView from '../views/HomeView.vue';
import Auth from '../views/Auth.vue';
import Manage from '../views/Manage.vue';
import Virt from '../views/Virt.vue';
import Task from '../views/Task.vue';
import IframeChat from '../views/IframeChat.vue'; /* Todo: Move into its own repo */
import Studio from '../views/Studio.vue'; /* Todo: Move into own repo */
import STAForms from '../views/STAForms.vue'; /* Todo: Move into own repo */

const guard = (to, from, next, redirectRoute = "/login") => {
  if (store.state.auth.authenticated) {
    next();
  } else {
    store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        store.state.auth.authenticated ? next() : next(redirectRoute);
      })
      .catch(() => {
        next(redirectRoute);
      });
  }
};

const auth = (to, from, next, redirectRoute = "/auth") => {
  if (!store.state.auth.authenticated && store.state.auth.accessToken) {
    store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        return store.state.auth.authenticated ? next() : next(redirectRoute);
      })
      .catch(() => {});
  } else {
    next();
  }
};

const loggedIn = (to, from, next) => {
  store
    .dispatch("auth/authenticate", {
      root: true,
    })
    .then(() => (store.state.auth.authenticated ? next("/") : next()))
    .catch(() => next());
};

const standAloneApps = [
  {
    path: '/sta/forms',
    name: 'sta_forms',
    component: STAForms
  }
]

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter: guard
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Auth,
    beforeEnter: loggedIn
  },
  {
    path: '/m/:key',
    name: 'manage',
    component: Manage,
    beforeEnter: auth
  },
  {
    path: '/m/:key/:id',
    name: 'manage_item',
    component: Manage,
    beforeEnter: auth
  },
  {
    path: '/virts',
    name: 'virts',
    component: Virt,
    beforeEnter: auth
  },
  {
    path: '/virts/:id',
    name: 'virt',
    component: Virt,
    beforeEnter: auth
  },
  {
    path: '/tasks',
    name: 'tasks',
    component: Task,
    beforeEnter: auth
  },
  {
    path: '/tasks/:id',
    name: 'task',
    component: Task,
    beforeEnter: auth
  },
  {
    path: '/iframe/chat',
    name: 'iframe_chat',
    component: IframeChat
  },
  {
    path: '/studio',
    name: 'studio',
    component: Studio
  },
  {
    path: '/studio/:id',
    name: 'studio_craft',
    component: Studio
  },
  ...standAloneApps
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
