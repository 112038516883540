<template>
  <li class="tree-node-item" v-if="node">
    <div class="tree-node-item-name" @dragstart="onDragStart" draggable="true" :data-id="node.id">
      {{ node.name }}
    </div>
   <!--  {{node.children}}
    <VueDraggableNext
      :list="node.children"
      group="nodes"
      @start="onDragStart"
      @end="onDragEnd"
    >
      <template #item="{ element }">
      	{{element}}
        <TreeNode
          :key="element.id"
          :node="element"
          @drop="$emit('drop', $event, element)"
        />
      </template>
    </VueDraggableNext> -->

      <draggable
		    class="tree-node-item-list"
		    tag="ul"
		    :list="node.children"
		    :group="{ name: 'g1' }"
		    item-key="name"
		  >
		    <template #item="{ element }">
		      <li>
		        <div class="tree-node-item-name">{{ element.name }}</div>
		        <TreeNode :tasks="element.children" />
		      </li>
		    </template>
		  </draggable>
  </li>
</template>

<script>
import { defineComponent } from 'vue';
import draggable from 'vuedraggable'

export default defineComponent({
  name: 'TreeNode',
  components: {
    draggable
  },
  props: {
    node: {
      type: Object,
      required: true
    }
  },
  setup(props) {
    const onDragStart = (event) => {
      event.dataTransfer.setData('nodeId', props.node.id);
    };

    const onDragEnd = (event) => {
      // Handle any necessary logic after drag ends
    };

    return {
      onDragStart,
      onDragEnd
    };
  }
});
</script>

<style>
li {
  list-style: none;
}

.tree-node-item {

}

.tree-node-item-list {
	padding-left: 24px;
}
</style>
