import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";
const _ = require('lodash');

function transformTasks(tasks) {
    const possibleStatuses = ['question', 'working', 'preparing', 'tasks', 'done', 'idea', 'backlog'];

    // Initialize result with all possible statuses
    const result = _.transform(possibleStatuses, (res, status) => {
        res[status] = [];
    }, {});

    // Process the tasks
    _.forEach(tasks, (task) => {
        const statusCategory = task.status;

        if (result[statusCategory]) {
          result[statusCategory].push({
              id: task.id,
              title: task.title,
              description: task.description,
              status: task.status.charAt(0).toUpperCase() + task.status.slice(1),
              members: task.members
          });
        }
    });

    return result;
}

const Api = {
  fetchVirts(payload) {
    return http.get("/virts");
  },
  fetchChats(payload) {
    return http.get(`/virt-chats`);
  },
  fetchChatsByVirtId(payload) {
    return http.get(`/virt-chats/virt/${payload.id}`);
  },
  fetchChatMessages(payload) {
    return http.get(`/virt-chats/${payload.id}/messages`);
  },
  fetchTasks() {
    return http.get(`/virt-tasks`);
  },
};

const virt = {
  namespaced: true,
  state: {
    virts: [],
    chats: [],
    messages: [],
    tasks: [],
    currentTask: null
  },
  getters: {
    getTasksForTaskBoard: (state) => {
      return transformTasks(state.tasks)
    },
    getSelectedTask: (state) => state.tasks.find(t => t.id == state.currentTask)
  },
  mutations: {
    updateVirts: (state, payload) => {
      state.virts = payload
    },
    updateChats: (state, payload) => {
      state.chats = payload
    },
    updateChatMessages: (state, payload) => {
      state.messages = payload
    },
    updateTasks: (state, payload) => {
      state.tasks = payload
    },
    setTask: (state, payload) => state.currentTask = payload
  },
  actions: {
    fetchVirts: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchVirts(payload)
          .then((res) => {
            state.commit("updateVirts", res.data.items);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchChats: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchChats(payload)
          .then((res) => {
            state.commit("updateChats", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchChatsByVirtId: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchChatsByVirtId(payload)
          .then((res) => {
            state.commit("updateChats", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchChatMessages: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchChatMessages(payload)
          .then((res) => {
            state.commit("updateChatMessages", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    fetchTasks: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.fetchTasks(payload)
          .then((res) => {
            state.commit("updateTasks", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    setTask: (state, payload) => state.commit("setTask", payload)
  },
};

export default virt;