import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";

const Api = {
  getLeadsCount(payload) {
    return http.get(`/contacts/stats/signup-count`, {
      params: {
        ...payload
      }
    });
  },
  getSignUpsRange(payload) {
    return http.get(`/contacts/stats/signups-range`, {
      params: {
        ...payload
      }
    });
  },
  getSignUpsMonthly(payload) {
    return http.get(`/contacts/stats/signups-monthly`, {
      params: {
        ...payload
      }
    });
  },
};

const contact = {
  namespaced: true,
  state: {
    leadsCount: 0,
    signUpsRange: [],
    signUpsMonthly: []
  },
  getters: {
    
  },
  mutations: {
    updateLeadsCount: (state, payload) => {
      state.leadsCount = payload
    },
    updateSignUpsRange: (state, payload) => {
      state.signUpsRange = payload
    },
    updateSignUpsMonthly: (state, payload) => {
      state.signUpsMonthly = payload
    },
  },
  actions: {
    getLeadsCount: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getLeadsCount(payload)
          .then((res) => {
            state.commit("updateLeadsCount", res.data.signup_count);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getSignUpsRange: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getSignUpsRange(payload)
          .then((res) => {
            state.commit("updateSignUpsRange", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getSignUpsMonthly: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getSignUpsMonthly(payload)
          .then((res) => {
            state.commit("updateSignUpsMonthly", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  },
};

export default contact;