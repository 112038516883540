<template>
  <div v-show="!isEditing">
    <template v-if="!edited">
      {{ craft.name }}
    </template>
    <template v-else>
      {{ editValue }}
    </template>
  </div>
  <div v-show="isEditing">
    <input
      v-model="editValue"
      @keyup.enter="updateCraftName"
      @blur="cancelEdit"
      ref="editInput"
    />
  </div>
</template>

<script setup>
import { ref, nextTick, watch } from 'vue';
import { useStore } from 'vuex';

const props = defineProps({
  craft: Object,
  index: Number
});

const emit = defineEmits(['update']);

const store = useStore();

const isEditing = ref(false);
const editValue = ref(props.craft.name);
const editInput = ref(null);
const edited = ref(false)

const enableEdit = async () => {
  isEditing.value = true;
  await nextTick();
  editInput.value.focus();
  editInput.value.select();
};

const updateCraftName = () => {
  store.dispatch('studio/updateCraftName', {
    id: props.craft.object_id,
    data: { title: editValue.value }
  })
  .then(() => {
    edited.value = true;
    isEditing.value = false;
    emit('update');
  })
  .catch(() => {
    // handle error
  });
};

const cancelEdit = () => {
  isEditing.value = false;
};

// Watch isEditing and focus the input when it changes to true
watch(isEditing, (newValue) => {
  if (newValue) {
    nextTick(() => {
      editInput.value.focus();
      editInput.value.select();
    });
  }
});
</script>

<style>
.studio-sidebar .sidebar-list-item, .studio-sidebar .tree-node-item {
  line-height: 32px;
  padding: 0 16px 0 32px;
  display: block;
  color: #000;
  cursor: default;
  position: relative;
  color: #7b7b7b;
}
.studio-sidebar .sidebar-list-item:hover, .studio-sidebar .tree-node-item-name:hover {
  background: #f4f6f9;
}
.studio-sidebar .sidebar-list-item.router-link-active, .studio-sidebar .tree-node-item-name.router-link-active {
  font-weight: 900;
  color: #000;
}
.studio-sidebar .sidebar-list-item.router-link-active:after, .studio-sidebar .tree-node-item-name.router-link-active:after {
  content: "";
  display: block;
  position: absolute;
  height: 8px;
  width: 3px;
  border-radius: 999px;
  background: #000;
  top: calc(50% - 4px);
  left: 20px;
}

.studio-sidebar .sidebar-list-item input, .studio-sidebar .tree-node-item input {
  height: 30px;
  font-size: 14px;
  font-weight: 600;
  padding: 0 8px;
  border: none;
  border-radius: 0px;
  background: transparent;
  width: 100%;
  outline: none;
}
</style>
