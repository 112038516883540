<template>
  <Layout>
    <Dashboard v-bind="panelConfig"/>
  </Layout>
</template>

<script setup>
  import { ref, computed } from "vue"
  import { useStore } from "vuex"
  import moment from "moment"
  import utils from "@lib/utils"
  import Dashboard from "@lib/views/Dashboard/Dashboard";

  const store = useStore()

  store.dispatch('payment/getTotalSales', {
    start_date: moment().format('YYYY-MM-DD')
  })

  store.dispatch('payment/getWeeklySales', {
    start_date: moment().subtract(52, 'weeky').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  })

  store.dispatch('contact/getLeadsCount', {
    start_date: moment().format('YYYY-MM-DD')
  })

  store.dispatch('contact/getSignUpsRange', {
    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  })

  store.dispatch('contact/getSignUpsMonthly', {
    start_date: moment().subtract(7, 'days').format('YYYY-MM-DD'),
    end_date: moment().format('YYYY-MM-DD'),
  })

  const title = "Dashboard"

  const totalSales = computed(() => store.state.payment.totalSales)
  const weeklySales = computed(() => store.state.payment.weeklySales)
  const leadsCount = computed(() => store.state.contact.leadsCount)
  const signUpsRange = computed(() => store.state.contact.signUpsRange)
  const signUpsMonthly = computed(() => store.state.contact.signUpsMonthly)

  const stats = computed(() => [
    {
      name: "Virt Tasks",
      value: 91,
      change: null,
      range: signUpsRange.value,
      chart: {
        dataValues: [
          {
            label: 'SignUps',
            data: signUpsMonthly.value.map(wS => wS.count),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: '#bbe73d',
            borderWidth: 2.25,
            fill: false,
            tension: 0.2,
            pointRadius: 0,
            pointHoverRadius: 4,
            hitRadius: 10
          }
        ],
        labels: signUpsMonthly.value.map(wS => wS.date),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: false,
                text: 'Month'
              },
              grid: {
                display: false, // Remove grid lines
              },
              ticks: {
                callback: function(value, index, values) {
                  // Show only first and last labels
                  if (index === 0 || index === values.length - 1) {
                    return value;
                  } else {
                    return '';
                  }
                }
              }
            },
            y: {
              position: 'right',
              title: {
                display: false,
                text: 'Sales'
              },
              beginAtZero: false,
              grid: {
                display: false, // Remove grid lines
              },
              ticks: {
                display: false,
                callback: function(value, index, values) {
                  // Show only first and last labels
                  if (index === 0 || index === values.length - 1) {
                    return value;
                  } else {
                    return '';
                  }
                }
              },
              border: {
                display: false // Remove y-axis line
              }
            }
          },
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            legend: {
              display: false,
              position: 'top'
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                title: function(tooltipItems) {
                  // Customize the title of the tooltip
                  return `Week ${tooltipItems[0].label}`;
                },
                label: function(tooltipItem) {
                  // Customize the label of the tooltip
                  return `Sales: $${tooltipItem.raw}`;
                },
                footer: function(tooltipItems) {
                  // Customize the footer of the tooltip (optional)
                  // return 'End of week data';
                },
              },
              backgroundColor: '#ffffff', // Background color of the tooltip
              titleFont: {
                size: 16, // Title font size
                weight: 'bold' // Title font weight
              },
              bodyFont: {
                size: 16, // Body font size
              },
              footerFont: {
                size: 16, // Footer font size
              },
              titleFont: {
                family: 'Euclid Circular A'
              },
              bodyFont: {
                family: 'Euclid Circular A'
              },
              footerFont: {
                family: 'Euclid Circular A'
              },
              titleColor: '#000',
              bodyColor: '#000',
              footerColor: '#000',
              padding: 8, // Padding inside the tooltip
              cornerRadius: 12, // Corner radius of the tooltip
              borderColor: '#e7e7e7', // Border color of the tooltip
              borderWidth: 1 // Border width of the tooltip
            }
          }
        }
      }
    },
    {
      name: "Leads",
      value: leadsCount.value,
      change: +12,
      chart: {
        dataValues: [
          {
            label: 'SignUps',
            data: signUpsMonthly.value.map(wS => wS.count),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: '#bbe73d',
            borderWidth: 2.25,
            fill: false,
            tension: 0.2,
            pointRadius: 0,
            pointHoverRadius: 4,
            hitRadius: 10
          }
        ],
        labels: signUpsMonthly.value.map(wS => wS.date),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: false,
                text: 'Month'
              },
              grid: {
                display: false, // Remove grid lines
              },
              ticks: {
                callback: function(value, index, values) {
                  // Show only first and last labels
                  if (index === 0 || index === values.length - 1) {
                    return value;
                  } else {
                    return '';
                  }
                }
              }
            },
            y: {
              position: 'right',
              title: {
                display: false,
                text: 'Sales'
              },
              beginAtZero: false,
              grid: {
                display: false, // Remove grid lines
              },
              ticks: {
                display: false,
                callback: function(value, index, values) {
                  // Show only first and last labels
                  if (index === 0 || index === values.length - 1) {
                    return value;
                  } else {
                    return '';
                  }
                }
              },
              border: {
                display: false // Remove y-axis line
              }
            }
          },
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            legend: {
              display: false,
              position: 'top'
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                title: function(tooltipItems) {
                  // Customize the title of the tooltip
                  return `Month ${tooltipItems[0].label}`;
                },
                label: function(tooltipItem) {
                  // Customize the label of the tooltip
                  return `SignUps: ${tooltipItem.raw}`;
                },
                footer: function(tooltipItems) {
                  // Customize the footer of the tooltip (optional)
                  // return 'End of week data';
                },
              },
              backgroundColor: '#ffffff', // Background color of the tooltip
              titleFont: {
                size: 16, // Title font size
                weight: 'bold' // Title font weight
              },
              bodyFont: {
                size: 16, // Body font size
              },
              footerFont: {
                size: 16, // Footer font size
              },
              titleFont: {
                family: 'Euclid Circular A'
              },
              bodyFont: {
                family: 'Euclid Circular A'
              },
              footerFont: {
                family: 'Euclid Circular A'
              },
              titleColor: '#000',
              bodyColor: '#000',
              footerColor: '#000',
              padding: 8, // Padding inside the tooltip
              cornerRadius: 12, // Corner radius of the tooltip
              borderColor: '#e7e7e7', // Border color of the tooltip
              borderWidth: 1 // Border width of the tooltip
            }
          }
        }
      }
    },
    {
      name: "Sales",
      value: utils.formatCurrency(totalSales.value, 'USD'),
      change: +291,
      chart: {
        dataValues: [
          {
            label: 'Sales',
            data: weeklySales.value.map(wS => wS.total_sales),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: '#bbe73d',
            borderWidth: 2.25,
            fill: false,
            tension: 0.2,
            pointRadius: 0,
            pointHoverRadius: 4,
            hitRadius: 10
          }
        ],
        labels: weeklySales.value.map(wS => wS.week_number),
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              title: {
                display: false,
                text: 'Week Number'
              },
              grid: {
                display: false, // Remove grid lines
              },
              ticks: {
                callback: function(value, index, values) {
                  // Show only first and last labels
                  if (index === 0 || index === values.length - 1) {
                    return value;
                  } else {
                    return '';
                  }
                }
              }
            },
            y: {
              position: 'right',
              title: {
                display: false,
                text: 'Sales'
              },
              beginAtZero: false,
              grid: {
                display: false, // Remove grid lines
              },
              ticks: {
                display: false,
                callback: function(value, index, values) {
                  // Show only first and last labels
                  if (index === 0 || index === values.length - 1) {
                    return utils.formatCurrency(value/100, 'USD');
                  } else {
                    return '';
                  }
                }
              },
              border: {
                display: false // Remove y-axis line
              }
            }
          },
          interaction: {
            mode: 'index',
            intersect: false
          },
          plugins: {
            legend: {
              display: false,
              position: 'top'
            },
            tooltip: {
              displayColors: false,
              callbacks: {
                title: function(tooltipItems) {
                  // Customize the title of the tooltip
                  return `Week ${tooltipItems[0].label}`;
                },
                label: function(tooltipItem) {
                  // Customize the label of the tooltip
                  return `Sales: ${utils.formatCurrency(tooltipItem.raw, 'USD')}`;
                },
                footer: function(tooltipItems) {
                  // Customize the footer of the tooltip (optional)
                  // return 'End of week data';
                },
              },
              backgroundColor: '#ffffff', // Background color of the tooltip
              titleFont: {
                size: 16, // Title font size
                weight: 'bold' // Title font weight
              },
              bodyFont: {
                size: 16, // Body font size
              },
              footerFont: {
                size: 16, // Footer font size
              },
              titleFont: {
                family: 'Euclid Circular A'
              },
              bodyFont: {
                family: 'Euclid Circular A'
              },
              footerFont: {
                family: 'Euclid Circular A'
              },
              titleColor: '#000',
              bodyColor: '#000',
              footerColor: '#000',
              padding: 8, // Padding inside the tooltip
              cornerRadius: 12, // Corner radius of the tooltip
              borderColor: '#e7e7e7', // Border color of the tooltip
              borderWidth: 1 // Border width of the tooltip
            }
          }
        }
      }
    }
  ])

  const panelConfig = ref({
  title: 'Dashboard',
  userAvatar: 'https://cdn.purdia.com/images/avatar.png',
  containerSize: 'full',
  fixed: true,
  items: [
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'johndoe', firstname: 'John', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'janedoe', firstname: 'Jane', lastname: 'Doe' },
    { profileImageUrl: 'https://cdn.purdia.com/images/avatar.png', username: 'bobsmith', firstname: 'Bob', lastname: 'Smith' },
  ],
  display: 'list',
  menuItems: [
    {
      key: 'file',
      name: 'File',
      icon: 'file',
      children: [
        {
          key: 'new',
          name: 'Export As',
          icon: 'plus',
          children: [
            { key: 'new-file', name: 'CSV', icon: 'file' },
            { key: 'new-folder', name: 'JSON', icon: 'folder' },
          ],
        },
        { key: 'open', name: 'Open', icon: 'folder-open' },
        { key: 'save', name: 'Save', icon: 'save' },
      ],
    },
    {
      key: 'edit',
      name: 'Edit',
      icon: 'edit',
      children: [
        { key: 'undo', name: 'Undo', icon: 'undo' },
        { key: 'redo', name: 'Redo', icon: 'redo' },
      ],
    },
    // Add more menu items as needed
  ],
  currentPage: 1,
  totalPages: 3,
  stats
})
</script>
