<template>
  <div class="mode-selector">
    <div class="form-field">
      <label>Output</label>
      <select v-model="selectedMode" @change="updateMode">
        <option v-for="mode in modes" :key="mode" :value="mode">{{ mode }}</option>
      </select>
      <input v-if="requiresColumnName" v-model="columnName" type="text" placeholder="Column Name" @input="updateMode">
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, defineProps, defineEmits } from 'vue';

const props = defineProps({
  modelValue: Object
});

const emit = defineEmits(['update:modelValue']);

const selectedMode = ref(props.modelValue?.mode || 'all');
const columnName = ref(props.modelValue?.columnName || '');

const modes = ['all', 'first', 'count', 'sum', 'average', 'min', 'max'];
const requiresColumnName = computed(() => ['sum', 'average', 'min', 'max'].includes(selectedMode.value));

function updateMode() {
  emit('update:modelValue', {
    mode: selectedMode.value,
    columnName: requiresColumnName.value ? columnName.value : null
  });
}

watch(() => props.modelValue, (newValue) => {
  if (newValue) {
    selectedMode.value = newValue?.mode || 'all';
    columnName.value = newValue?.columnName || '';
  }
}, { immediate: true });

watch([selectedMode, columnName], updateMode);
</script>

<style scoped>
.mode-selector {
  display: flex;
  align-items: center;
  margin-top: 20px;
}
.mode-selector select, .mode-selector input {
  margin-right: 10px;
}
</style>
