<template>
  <router-view/>
</template>

<style>
@font-face {
  font-family: "Cera";
  src: url("./assets/fonts/Cera Pro Regular.otf") format("opentype");
  font-weight: 400;
}

@font-face {
  font-family: "Cera";
  src: url("./assets/fonts/Cera Pro Medium.otf") format("opentype");
  font-weight: 500;
}

@font-face {
  font-family: "Cera";
  src: url("./assets/fonts/Cera Pro Bold.otf") format("opentype");
  font-weight: 600;
}

#app {
  font-family: Cera, sans-serif;
}
</style>
