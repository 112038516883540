<template>
	<div class="chat-messages" ref="chatContainer">
		<div class="messages">
			<slot></slot>
		</div>
		<div class="footer">
			<div class="dotsContainer" v-if="isTyping">
			  <span id="dot1"></span>
			  <span id="dot2"></span>
			  <span id="dot3"></span>
			  <div style="font-size: 12px;margin-left: 4px;color: #cacaca;">typing..</div>
			</div>
			<slot name="footer"></slot>
		</div>
	</div>
</template>

<script setup>
	import { ref, nextTick, watch } from "vue"

	const props = defineProps({
		messages: Array,
		isTyping: Boolean
	})

	const scrollToBottom = async () => {
    await nextTick();
    if (chatContainer.value) {
      chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
    }
  };

	watch(
	    () => props.messages, 
	    (newId) => {
	        if (newId) {
	            scrollToBottom()
	        }
	    },
	    {
	        deep: true,
	        immediate: true
	    }
	)

	watch(
	    () => props.isTyping, 
	    (newId) => {
	        if (newId) {
	            scrollToBottom()
	        }
	    }
	)

	const chatContainer = ref(null);
</script>

<style scoped>
	.chat-messages {
		height: 100%;
    overflow: auto;
    width: 100%;
	}
	.chat-messages .messages {
		height: 100%;
		display: flex;
		flex-flow: column;
    gap: 16px;
    padding: 0 1px;
	}
	.messages .footer {
		background: #ffffff;
    padding: 12px;
    border-top: 1px solid #e7e7e7;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
	}

	.dotsContainer {
  	width: auto;
  	padding-left: 4px;
	  border-radius: 50px;
	  display: flex;
	  justify-content: start;
	  align-items: center;
	  position: absolute;
    bottom: 95px;

	  #dot1,
	  #dot2,
	  #dot3 {
	    width: 6px;
	    height: 6px;
	    background: #cacaca;
	    border-radius: 50%;
	    margin: 0 4px;
	    transition: all 0.4s ease-in-out;
	    animation: typing .9s infinite;
	  }

	  #dot1 {
	    animation-delay: .9s;
	  }

	  #dot2 {
	    animation-delay: 0.4s;
	  }

	  #dot3 {
	    animation-delay: 0.7s;
	  }
	}

	@keyframes typing {
	  0% {
	    transform: translateY(0);
	    transition: all 0.4s ease-in-out;
	  }

	  50% {
	    transform: translateY(-5px);
	    transition: all 0.4s ease-in-out;
	  }

	  100% {
	    transform: translateY(0);
	    transition: all 0.4s ease-in-out;
	  }
	}

	/* width */
	.chat-messages::-webkit-scrollbar {
	  width: 6px;
	  padding: 24px 0;
	}

	/* Track */
	.chat-messages::-webkit-scrollbar-track {
	  background: #fff;
	  border-radius: 999px;
	}

	/* Handle */
	.chat-messages::-webkit-scrollbar-thumb {
	  background: #e8e8e8;
	  border-radius: 999px;
	}

	/* Handle on hover */
	.chat-messages::-webkit-scrollbar-thumb:hover {
	  background: #555;
	}
</style>