<template>
  <div>
    <ul>
      <TreeNode
        class="tree-node-item-parent"
        v-for="node in treeData"
        :key="node.id"
        :node="node"
        @drop="onDrop"
      />
    </ul>
  </div>
</template>

<script>
import { ref, watch, defineComponent } from 'vue';
import TreeNode from './TreeNode.vue';

export default defineComponent({
  name: 'TreeView',
  components: {
    TreeNode
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    }
  },
  setup(props, { emit }) {
    const treeData = ref([]);

    const buildTree = (data) => {
      const tree = [];
      const lookup = {};

      data.forEach(item => {
        lookup[item.id] = { ...item, children: [] };
      });

      data.forEach(item => {
        if (item.parent && item.parent !== item.id) {
          if (lookup[item.parent]) {
            lookup[item.parent].children.push(lookup[item.id]);
          }
        } else {
          tree.push(lookup[item.id]);
        }
      });

      return tree;
    };

    watch(() => props.modelValue, (newVal) => {
      treeData.value = buildTree(newVal);
    }, { immediate: true, deep: true });

    const onDrop = (event, node) => {
      const draggedNodeId = event.dataTransfer.getData('nodeId');
      const draggedNode = props.modelValue.find(item => item.id === draggedNodeId);
      if (draggedNode) {
        draggedNode.parent = node.id;
        emit('update:modelValue', [...props.modelValue]);
      }
    };

    return {
      treeData,
      onDrop
    };
  }
});
</script>

<style scoped>
li {
  list-style: none;
}

.drag-over {
  border: 1px dashed #000;
}

.dragging {
  opacity: 0.5;
}
</style>
