// socket.js
export default class SocketHandler {
    constructor(options, canvasApp) {
        this.socket = options.socket;
        this.canvasApp = canvasApp;
        this.initWebSocket(options);
    }

    initWebSocket(options) {
        this.socket.on('connect', () => {
            console.log('WebSocket connection established');
            this.joinRoom(options.canvasId); // Join the room based on canvas ID
        });

        this.socket.on('assign_index', (data) => {
            this.canvasApp.userCursorIndex = data.index;
            console.log(`Assigned cursor index: ${this.canvasApp.userCursorIndex}`);
        });

        this.socket.on('joined_room', (data) => {
            console.log(`Joined Room: ${data.room}`);
        });

        this.socket.on('cursor_position', (data) => {
            this.canvasApp.otherCursors[data.id] = data.position;
            this.canvasApp.redrawCanvas();
        });

        this.socket.on('add_rectangle', (data) => {
            this.canvasApp.rectangles.push(data);
            this.canvasApp.redrawCanvas();
        });

        this.socket.on('rectangle_position', (data) => {
            const rect = this.canvasApp.rectangles.find(r => r.sequence_id === data.rectangle_id);
            if (rect) {
                rect.x = data.position.x;
                rect.y = data.position.y;
                this.canvasApp.redrawCanvas();
            }
        });

        this.socket.on('add_line', (data) => {
            this.canvasApp.lines.push(data);
            this.canvasApp.redrawCanvas();
        });

        this.socket.on('disconnect', () => {
            console.log('WebSocket connection closed');
        });
    }

    joinRoom(roomId) {
        this.socket.emit('join', { room: roomId, user_id: this.canvasApp.options.userId });
    }

    sendCursorPosition(position) {
        if (this.socket && this.socket.connected) {
            // Adjust position to be relative to the canvas
            const relativeX = (position.x - this.canvasApp.offsetX) / this.canvasApp.zoomLevel;
            const relativeY = (position.y - this.canvasApp.offsetY) / this.canvasApp.zoomLevel;

            const data = {
                type: 'cursor_position',
                user_id: this.canvasApp.options.userId,
                room: this.canvasApp.options.canvasId,
                position: { x: relativeX, y: relativeY }
            };
            this.socket.emit('cursor_position', data);
        }
    }

    updateRectangle(rect, newText) {
        this.socket.emit('update_rectangle', {
            rectangle_id: rect.sequence_id,
            text: newText,
            room: this.canvasApp.options.canvasId
        });
    }

    updateRectanglePosition(rect) {
        this.socket.emit('rectangle_position', {
            rectangle_id: rect.sequence_id,
            position: { x: rect.x, y: rect.y },
            room: this.canvasApp.options.canvasId
        });
    }

    addRectangle(newRectangle) {
        this.socket.emit('add_rectangle', {
            rectangle: newRectangle,
            room: this.canvasApp.options.canvasId
        });
    }

}
