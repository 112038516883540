import http from "@lib/services/http";
import cookie from "@lib/services/cookie";
import config from "@lib/store/modules/config";

const Api = {
  getTotalSales(payload) {
    return http.get(`/payments/total`, {
      params: {
        ...payload
      }
    });
  },
  getWeeklySales(payload) {
    return http.get(`/payments/weeks`, {
      params: {
        ...payload
      }
    });
  },
};

const payment = {
  namespaced: true,
  state: {
    totalSales: 0,
    weeklySales: []
  },
  getters: {
    
  },
  mutations: {
    updateTotalSales: (state, payload) => {
      state.totalSales = payload
    },
    updateWeeklySales: (state, payload) => {
      state.weeklySales = payload
    },
  },
  actions: {
    getTotalSales: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getTotalSales(payload)
          .then((res) => {
            state.commit("updateTotalSales", res.data.total_sales);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
    getWeeklySales: (state, payload) =>
      new Promise((resolve, reject) => {
        Api.getWeeklySales(payload)
          .then((res) => {
            state.commit("updateWeeklySales", res.data);
            resolve(res);
          })
          .catch((error) => {
            reject(error);
          });
      }),
  },
};

export default payment;