<template>
	<div class="studio">
		<div class="top-navigation-wrapper">
        <div class="top-navigation">
        	<svg xmlns="http://www.w3.org/2000/svg" style="height: 28px;margin:0 6px;" viewBox="0 0 400 400" fill="none">
			<g clip-path="url(#clip0_16_1592)">
			<path d="M303.502 372.206C273.658 381.172 243.957 390.268 214.114 399.052C200.425 403.074 187.203 400.35 174.033 395.588C146.149 385.415 118.096 375.748 90.1469 365.744C83.231 363.279 76.3151 360.749 69.6588 357.686C61.4843 353.924 57.8511 346.476 57.8252 338.107C57.8252 309.781 58.3702 281.443 58.7075 253.105C59.2092 208.729 59.7196 164.353 60.2386 119.977C60.5068 97.6333 60.8182 75.294 61.1728 52.959C61.2683 48.1647 62.9028 43.5281 65.835 39.7338C68.7672 35.9395 72.8417 33.1885 77.457 31.887C113.788 22.4539 150.119 12.5406 186.385 2.78311C203.331 -1.77125 219.278 3.30214 235.199 7.90841C264.692 16.4462 294.068 25.3733 323.561 33.8592C337.64 37.9335 342.739 44.9532 342.519 60.1085C341.714 116.733 341 173.358 340.378 229.982C339.936 268.091 339.781 306.2 339.171 344.309C338.976 356.415 332.891 363.512 321.226 366.977C315.335 368.741 309.379 370.298 303.45 371.946L303.502 372.206Z" fill="#FF0000"/>
			<path d="M236.764 66.3815C217.228 85.0741 189.113 105.835 161.304 122.076C150.425 128.512 129.281 139.313 120.471 142.991C116.87 144.523 115.798 145.366 116.258 146.055C116.564 146.668 118.939 150.498 121.39 154.559C123.842 158.696 126.14 162.22 126.523 162.373C128.209 163.445 165.977 144.676 182.984 134.257C185.589 132.648 187.964 131.346 188.117 131.346C188.347 131.346 188.5 149.809 188.5 172.332V213.318H198.842H209.184V165.59C209.184 139.39 209.491 117.556 209.797 117.097C210.104 116.714 213.245 114.109 216.845 111.428C230.788 100.932 259.747 75.0383 259.747 73.1231C259.747 72.357 245.267 59.3334 244.501 59.3334C244.271 59.41 240.747 62.551 236.764 66.3815Z" fill="white"/>
			<path d="M130.281 206.206V215.782H190.496H250.634L248.489 219.613C243.356 228.959 232.401 241.676 221.063 251.635L215.701 256.385L223.132 263.74L230.563 271.094L235.389 267.034C247.723 256.385 257.299 245.277 266.109 231.257C271.855 222.141 279.669 207.279 279.593 205.67C279.593 205.134 277.524 202.835 274.996 200.614L270.476 196.63H200.379H130.281V206.206Z" fill="white"/>
			<path d="M188.502 243.132C188.502 266.727 186.664 278.449 181.148 289.94C175.709 301.278 165.749 310.778 150.044 319.741L143.456 323.495L150.044 331.692L156.633 339.889L163.145 336.365C189.345 322.269 203.901 302.197 207.961 274.695C208.804 269.026 209.187 260.828 209.187 247.652V228.806H198.845H188.502V243.132Z" fill="white"/>
			</g>
			<defs>
			<clipPath id="clip0_16_1592">
			<rect width="400" height="400" fill="white"/>
			</clipPath>
			</defs>
			</svg>
        	<UiMenuBar :items="menuItems" style="width:100%;" @click-item="handleMenuClick"/>
        </div>
      </div>
        <div class="navigation-wrapper" :class="{'nav-inactive':toolBarInactive}">
            <div class="navigation">
                <div class="button" @click="onToggle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125" />
                    </svg>
                </div>
                <!-- <div class="button" id="exportButton">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M9 8.25H7.5a2.25 2.25 0 0 0-2.25 2.25v9a2.25 2.25 0 0 0 2.25 2.25h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25H15M9 12l3 3m0 0 3-3m-3 3V2.25" />
                    </svg>
                </div>
                <div class="button" style="border: 1px solid #e7e7e7;" @click="undo">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
                <div class="button" style="border: 1px solid #e7e7e7;" @click="redo">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div> -->
                <textarea id="connectTextarea" rows="1" cols="5" placeholder="Enter connection data here" style="display: none;"></textarea>
                <button id="connectButton" style="display: none;">Connect Rectangles</button>
                <button id="toggleSnap" style="display: none;">Toggle Snap to Grid</button>
                <!-- <UiInput placeholder="What do you want to do?" style="font-size: 14px;width: 230px;margin: 0"/> -->
                <!-- <div class="button" id="onShare" style="background: violet;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5" />
                    </svg>
                </div> -->
                <div class="button" style="border: 1px solid #e7e7e7;" @click="onCreateCard">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
                <div class="button" style="border: 1px solid #e7e7e7;" @click="onCreateRectangle">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                </div>
                <div class="button" style="border: 1px solid #e7e7e7;" @click="onCreateText">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5M12 17.25h8.25" />
					</svg>
                </div>
                <div class="button" style="border: 1px solid #e7e7e7;" @click="onCreateFrame">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
					  <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z" />
					</svg>
                </div>
                <!-- <div class="button" style="border: 1px solid #e7e7e7;">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="icon">
											<path stroke-linecap="round" stroke-linejoin="round" d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z" />
										</svg>
										<div class="notifications-icon" style="background: red;color: rgb(255, 255, 255);border-radius: 14px;font-size: 10px;line-height: 13px;position: absolute;top: 3px;right: 3px;min-width: 7px;min-height: 7px;"><span v-show="false">123</span></div>
                </div> -->
                <!-- <div class="button" style="border: 1px solid #e7e7e7;">
                    <img src="https://media.licdn.com/dms/image/D4D03AQH1WIYMGBUZ1g/profile-displayphoto-shrink_200_200/0/1717680630544?e=2147483647&v=beta&t=hJmDvJOdw1u3PztQ72E0io8TfZ4cL-C-zj3IHpKE3wU" style="width: 100%;border-radius: 7px;">
                </div> -->
            </div>
        </div>
        <div class="zoom-control-wrapper">
        	<ZoomControl v-model="zoomLevel" />
        </div>
        <div class="canvas">
            <div id="myCanvasContainer" style="position: relative;">
                <canvas ref="canvas" id="myCanvas"></canvas>
                <div id="topCircle" class="circle"></div>
                <div id="rightCircle" class="circle"></div>
                <div id="bottomCircle" class="circle"></div>
                <div id="leftCircle" class="circle"></div>
            </div>
        </div>
        
        <!-- Left Sidebar -->
        <StudioSidebar @click-item="onSelectCanvas">
    		<div class="sidebar-list-head">
		    	<div class="sidebar-list-title">Elements</div>
		    </div>
        	<TreeView v-model="rects"></TreeView>
        </StudioSidebar>

        <StudioSidebarRight>
        	<template v-if="store.state.user.user">
	        	<!-- Edit Node -->
	        	<EditNode v-if="sequenceEvent" :sequence="sequenceEvent" @close="showEditNode = false" @update="onUpdateRect" @update-style="onEditCanvasElementStyle"></EditNode>

	        	<!-- Edit Canvas -->
	        	<EditCanvas v-model="sequence" v-else-if="sequence"></EditCanvas>
        	</template>
        </StudioSidebarRight>

        <StudioModal v-if="showExportModal" @close="showExportModal = false">
        	<StudioModalBody>
	        	<textarea id="dataTextarea" v-model="sequencesJSON" rows="1" acols="5" style="min-height: 512px;border: none;outline: none;"></textarea>
	        	<button @click="importData">Import</button>
        	</StudioModalBody>
        </StudioModal>

        <!-- Html Preview -->
        <StudioModal v-if="false">
        	<StudioModalBody size="100%">
        		<HtmlPreview></HtmlPreview>
        	</StudioModalBody>
        </StudioModal>
    </div>
</template>

<script setup>
	import { ref, onMounted, watch, computed } from "vue"
	import { useStore } from "vuex"
	import { useRoute } from "vue-router"
	import CanvasApp from "@src/services/canvas"
	import EditNode from "@src/components/Studio/EditNode"
	import EditCanvas from "@src/components/Studio/EditCanvas"
	import Modal from "@src/components/Studio/Modal"
	import StudioModal from "@src/components/Studio/Modal"
  	import StudioModalBody from "@src/components/Studio/ModalBody"
  	import StudioSidebar from "@src/components/Studio/Sidebar"
  	import StudioSidebarRight from "@src/components/Studio/SidebarRight"
	import ZoomControl from "@src/components/Studio/ZoomControl"
	import TreeView from "@src/components/Studio/TreeView"
	import HtmlPreview from "@src/components/Studio/HtmlPreview"
	import socket from "@lib/services/socket";
	import utils from '@lib/utils'

	const store = useStore()
	const route = useRoute()

	const canvasApp = ref(null)
	const canvas = ref(null)
	const showEditNode = ref(null)
	const sequence = ref()
	const sequenceEvent = ref(null)
	const showExportModal = ref(false)
	const sequencesJSON = ref(null)
	const zoomLevel = ref(null)
	const rects = ref([])
    const toolBarInactive = ref(false)

    const layouts = {
        flow: {
            canvas: {
                backgroundColor: "#fff"
            },
            config: {}
        },
        email_template: {
            canvas: {
                backgroundColor: "#f1f1f1"
            },
            config: {
                onlySnapOnShiftPressed: true,
                areSmartGuidesEnabled: true,
                editTextOnCanvas: false,
                isMovementRestricted: false,
                restrictScrolling: false,
                restrictScrollX: true,
                restrictScrollY: false,
                centerFrameRect: true,
                centerScrollOnFrame: true
            }
        }
    }

	const items = ref([
        {"key":"trigger_webhook","title":"Webhook","type": "card", "event_type": "trigger"},
        {"key":"send_form","title":"Send Form","type": "card", "event_type": "send"},
        {"key":"send_email","title":"Send E-Mail","type": "card", "event_type": "send"},
        {"key":"api","title":"API Request","type": "card", "event_type": "send"},
        {"key":"query","title":"Query","type": "card", "event_type": "query"},
        {"key":"schedule","title":"Schedule","type": "card"},
        {"key":"condition","title":"Condition","type": "pill", "event_type": "function"},
        {"key":"delay","title":"Delay","type": "pill", "event_type": "function"},
        {"key":"generate_social_media_post","title":"Social media post"},
        {"key":"post_on_linked_in","title":"Post on LinkedIn","app":{"name":"LinkedIn","svg":`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" /></svg>`,"brandColor":"#0077B5"}},
        {"key":"post_on_twitter","title":"Post on Twitter","app":{"name":"X","svg":`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" /></svg>`,"brandColor":"#000"}},
        {"key":"post_on_facebook","title":"Post on Facebook","app":{"name":"Facebook","svg":`<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#fff" class="size-6"><path stroke-linecap="round" stroke-linejoin="round" d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z" /></svg>`,"brandColor":"#1877F2"}},
    ])

    const fetchSequence = () => {
    	store.dispatch('studio/fetchSequence', {
	    	id: route.query.sequence
	    }).then(res => {
	    	sequence.value = res.data
	    	sequencesJSON.value = res.data
	    	loadData(res.data)
	    	updateTreeView(sequence.value.nodes)
            const layout = layouts[sequence.value.canvas.type] ? layouts[sequence.value.canvas.type] : null

            if (layout) {
                canvasApp.value.updateCanvas(layout.canvas);
                canvasApp.value.updateConfig(layout.config);
            }

	    	res.data.nodes.forEach(rect => {
	    		if (rect.key == "query_db") {
	    			store.dispatch('studio/fetchSequenceEvent', {
			      	id: rect.id
			      }).then(res => {
			      	const sequenceEvent = res.data

			      	store.dispatch('studio/runSequenceQuery', {
			            id: rect.id,
			        }).then((res) => {
			        	canvasApp.value.updateRectangleById(rect.id, {
			        		subtitle: utils.fillTemplate(res.data, sequenceEvent.data.output_template),
			        		displayData: utils.fillTemplate(res.data, sequenceEvent.data.output_template)
		    				})
			        }).catch(() => {

			        })
			      })	
	    		}
	    	})
	    }).catch(() => {})
    }

    const updateTreeView = (items) => {
    	rects.value = items.map(r => ({
    		id:r.sequence_id,
    		name: r.title,
    		parent: r.parent
    	}))
    }

    watch(() => route.query.sequence, () => {
			if (route.query.sequence) {
		  		fetchSequence()
		  	}
	  	}, {
	  		immediate: false,
	  		deep: true
	  	})

	onMounted(() => {
		store
      .dispatch("auth/authenticate", {
        root: true,
      })
      .then(() => {
        const options = { items: items.value, socket: socket, canvasId: route.query.sequence, userId: store.state.user.user.id };

		canvasApp.value = new CanvasApp(canvas.value, options);

		zoomLevel.value = canvasApp.value.zoomLevel
	  	window.addEventListener('resize', resizeCanvas);
	  	resizeCanvas();

	  	fetchSequence()

		  

			  canvasApp.value.listen('export_data', (data) => {
			  	sequencesJSON.value = data
			  })

			  canvasApp.value.listen('node_click', (data) => {
			  	if (route.query.sequence) {
			  		sequenceEvent.value = {
                        canvas: data
                    }
			      store.dispatch('studio/fetchSequenceEvent', {
			      	id: data.id
			      }).then(res => {
			      	sequenceEvent.value = {
                        ...res.data,
                        ...sequenceEvent.value
                    }
			      })	
			  	}
			  	
		      showEditNode.value = true
		    });

              

            canvasApp.value.listen('resize_rect', (data) => {
                toolBarInactive.value = true
            });

            canvasApp.value.listen('resize_rect_end', (data) => {
                toolBarInactive.value = false
            });

            canvasApp.value.listen('on_move_rect', (data) => {
                toolBarInactive.value = true
            });

            canvasApp.value.listen('on_move_rect_end', (data) => {
                toolBarInactive.value = false
            });

			canvasApp.value.listen('update_rect', (data) => {
				if (sequenceEvent.value) sequenceEvent.value.canvas = data
		    });

		    canvasApp.value.listen('set_zoom_level', (data) => {
			  	zoomLevel.value = data
			  })

			  canvasApp.value.listen('save', (data) => {
			  	save()
			  })

			  canvasApp.value.listen('mouse_up', (data) => {
			  	save()
			  })

			  canvasApp.value.listen('add_line', (data) => {
			  	if (!route.query.sequence) return
			  	store.dispatch('studio/createSequenceLine', {
			    	id: route.query.sequence,
			    	data: {
			    		from_rect_id: data.from_rect_id,
			    		to_rect_id: data.to_rect_id,
			    	}
			    }).then(res => {
			    	
			    })
			  })

			  canvasApp.value.listen('add_rect', (data) => {
			  	if (!route.query.sequence) return

					store.dispatch('studio/createSequenceEvent', {
			    	id: route.query.sequence,
			    	data: {
			    		id: data.id,
			    		key: "none",
			    		canvas: {
			    			type: data.type,
				    		x: data.x,
				    		y: data.y,
				    		width: data.width,
				    		height: data.height,
			    		}
			    	}
			    }).then(res => {
			    	canvasApp.value.updateRectangleById(data.sequence_id, {
			    		id: res.data.id
			    	})
			    })
			  })

			  canvasApp.value.listen('remove_line', (data) => {
			  	if (!route.query.sequence) return
			  	store.dispatch('studio/removeSequenceLine', {
			    	id: route.query.sequence,
			    	data: {
			    		from_rect_id: data.from_rect_id,
			    		to_rect_id: data.to_rect_id,
			    	}
			    }).then(res => {
			    	
			    })
			  })
      })
      .catch(() => {});
	});

	const resizeCanvas = () => {
	  if (canvasApp.value) {
	    canvasApp.value.resizeCanvas();
	  }
	};

	const updateRectangle = () => {
	  if (canvasApp.value) {
	    canvasApp.value.updateRectangle();
	  }
	};

	const undo = () => {
	  if (canvasApp.value) {
	    canvasApp.value.undo();
	  }
	};

	const redo = () => {
	  if (canvasApp.value) {
	    canvasApp.value.redo();
	  }
	};

	const loadData = (data) => {
		canvasApp.value.loadData(data)
	}

	const importData = (data) => {
		canvasApp.value.importData(data)
	}

	const createNew = () => {
		canvasApp.value.clearCanvas();
	}

	const handleMenuClick = (item) => {
		switch(item) {
		  case "undo":
		    undo()
		    break;
		  case "redo":
		    redo()
		    break;
		  case "export_as":
		  	showExportModal.value = true
		  	canvasApp.value.exportData();
		    break;
		  case "import":
		  	showExportModal.value = true
		  	canvasApp.value.exportData();
		  	break;
		  case "order":
		  	canvasApp.value.reorderItems();
		  	break;
		  case "new":
		  	createNew()
		    break;
		  case "save":
		  	save()
		    break;
		  case "show_hit_layer":
		  	canvasApp.value.showHitLayer();
		    break;
		  case "hide_hit_layer":
		  	canvasApp.value.hideHitLayer();
		    break;
		  default:
		  	console.log('none')
		  	break;
		}
	}

	const onToggle = () => {
		canvasApp.value.toggleMode();
	}

	const onCreateRectangle = () => {
		const rectangle = canvasApp.value.createRectangle();
		canvasApp.value.enableEditMode();
	}

	const onCreateText = () => {
		const rectangle = canvasApp.value.createTextRectangle();
		canvasApp.value.enableEditMode();
	}

	const onCreateFrame = () => {
		const rectangle = canvasApp.value.createFrameRectangle();
		canvasApp.value.enableEditMode();
	}

	const onCreateCard = () => {
		const rectangle = canvasApp.value.createCard();
		canvasApp.value.enableEditMode();
	}

	const save = () => {
		const rectangles = canvasApp.value.rectangles.map(r => ({
    		id: r.id,
    		height: r.height,
    		width: r.width,
    		x: r.x,
    		y: r.y,
    		backgroundColor: r.backgroundColor,
    		strokeStyle: r.strokeStyle,
    		lineWidth: Number(r.lineWidth),
    		borderRadius: Number(r.borderRadius),
    		boxShadowColor: r.boxShadowColor,
    		boxShadowOffsetX: Number(r.boxShadowOffsetX),
    		boxShadowOffsetY: Number(r.boxShadowOffsetY),
    		boxShadowBlur: Number(r.boxShadowBlur),
    		color: r.color,
    		content: r.content,
    		fontSize: Number(r.fontSize),
    		fontFamily: r.fontFamily,
    		letterSpacing: Number(r.letterSpacing),
    		parent: r.parent,
    		horizontalResizing: r.horizontalResizing,
    		verticalResizing: r.verticalResizing,
    		autoLayout: r.autoLayout,
    		horizontalAlign: r.horizontalAlign,
    		paddingTop: Number(r.paddingTop),
    		paddingRight: Number(r.paddingRight),
    		paddingBottom: Number(r.paddingBottom),
    		paddingLeft: Number(r.paddingLeft),
    		fontWeight: r.fontWeight
    	}))

		sequence.value.nodes = canvasApp.value.rectangles

		updateTreeView(canvasApp.value.rectangles)

		store.dispatch('studio/updateSequenceEvents', {
	    	id: route.query.sequence,
	    	data: {
	    		updates: rectangles
	    	}
	    }).then(res => {
	    	
	    })
	}

	const onUpdateRect = (data) => {
		canvasApp.value.updateRectangleById(data.sequence_id, data)
		save()
	}

	const onEditCanvasElementStyle = (data) => {
		canvasApp.value.updateRectangleById(data.sequence_id, {
			width: Number(data.width),
			height: Number(data.height),
			backgroundColor: data.backgroundColor,
			strokeStyle: data.strokeStyle,
	    	lineWidth: Number(data.lineWidth),
	    	borderRadius: Number(data.borderRadius),
	    	boxShadowColor: data.boxShadowColor,
    		boxShadowOffsetX: Number(data.boxShadowOffsetX),
    		boxShadowOffsetY: Number(data.boxShadowOffsetY),
    		boxShadowBlur: Number(data.boxShadowBlur),
    		color: data.color,
    		content: data.content,
    		fontSize: Number(data.fontSize),
    		fontFamily: data.fontFamily,
    		letterSpacing: Number(data.letterSpacing),
    		parent: data.parent,
    		horizontalResizing: data.horizontalResizing,
    		verticalResizing: data.verticalResizing,
    		autoLayout: data.autoLayout,
    		horizontalAlign: data.horizontalAlign,
    		paddingTop: Number(data.paddingTop),
    		paddingRight: Number(data.paddingRight),
    		paddingBottom: Number(data.paddingBottom),
    		paddingLeft: Number(data.paddingLeft),
    		fontWeight: data.fontWeight
		});
		save();
	}

  watch(() => zoomLevel.value, (newZoom, oldZoom) => {
  	if (!oldZoom) return
  	canvasApp.value.setZoom(newZoom)
  })

	const menuItems = [
    {
      key: 'file',
      name: 'File',
      children: [
      	{
          key: 'new',
          name: 'New',
        },
      	{
          key: 'import',
          name: 'Import',
        },
        {
          key: 'export_as',
          name: 'Export As',
        },
        { key: 'open', name: 'Open'},
        { key: 'save', name: 'Save'},
      ],
    },
    {
      key: 'edit',
      name: 'Edit',
      children: [
        { key: 'undo', name: 'Undo' },
        { key: 'redo', name: 'Redo' },
      ],
    },
    {
      key: 'view',
      name: 'Views',
      children: [
      	{
          key: 'order',
          name: 'Order items'
        },
        {
          key: 'layers',
          name: 'layers',
          children: [
          	{
		          key: 'show_hit_layer',
		          name: 'Show Hit Layer'
		        },
		        {
		          key: 'hide_hit_layer',
		          name: 'Hide Hit Layer'
		        },
          ]
        }
      ],
    },
  ]

	const onSelectCanvas = () => {
		sequenceEvent.value = null
	}
</script>


<style>
	:root {
		--cursor: url('https://virt-ai.com/pointer_b.svg') 2 2, auto;
		--cursor-bracket: auto;
	}
      * {
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
}
        body, html {
            height: 100%;
            margin: 0;
            overflow: hidden;
            font-family: 'Cera';
            font-weight: 500;
            cursor: var(--cursor);
        }
        canvas {
            display: block;
            background: #fff;
        }
        .studio {
        	font-size: 16px;
        }
        .circle {
            position: absolute;
            width: 16px;
            height: 16px;
            background-color: #fff;
            border-radius: 999px;
            border: 1px solid #e7e7e7;
            cursor: var(--cursor);
            display: none; /* Hide by default, show on hover */
        }

        .circle:hover {
        	background: #f4f6f9;
        }

        #myCanvasContainer:hover .circle {
            display: block;
        }
        .navigation-wrapper {
          position: fixed;
          bottom: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: center;
          z-index: 128;
          padding-block: 32px;
          pointer-events: none;
        }
        .navigation {
          height: 64px;
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
          border: 1px solid rgb(231, 231, 231);
          border-radius: 24px;
          width: auto;
          padding: 0 16px;
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 16px;
          font-size: 12px;
          pointer-events: all;
          transition: opacity .1s;
        }
        .navigation-wrapper-left {
        	justify-content: start;
        	top: 0;
        	width: auto;
        	height: 100%;
        	align-items: baseline;
        	padding: 32px;
        }
        .navigation-wrapper-left .navigation {
        	width: 64px;
        	height: auto;
        	padding: 16px 0;
        	flex-flow: column;
        }
        .top-navigation-wrapper {
          position: fixed;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          justify-content: start;
          z-index: 128;
          padding: 8px;
          pointer-events: none;
        }
        .top-navigation {
          height: 32px;
          background-color: #fff;
          box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
          border: 1px solid rgb(231, 231, 231);
          border-radius: 12px;
          width: auto;
          padding: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          pointer-events: all;
        }
        .top-navigation .menu-bar {
        	border: none;
        }
        .top-navigation .menu-item:hover {
        	cursor: var(--cursor);
        }
        .top-navigation .menu-bar {
        	padding: 0;
        }
        .canvas {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
        }
        .icon {
          height: 26px;
        }
        .button {
          background: #fff;
          height: 40px;
          width: 40px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 8px;
          flex: none;
          position: relative;
        }
        .button:hover {
          background: #f4f6f9;
        }
        .button.active {
/*          background: #c5dcff;*/
        }
        .button.active:after {
        	content: "";
          display: block;
          height: 6px;
          width: 6px;
          border-radius: 999px;
          background: blue;
          position: absolute;
          bottom: -3px;
          left: calc(50% - 3px);
        }
        .btn {
        	cursor: var(--cursor);
        }
        label, select {
        	cursor: var(--cursor);	
        }
        input {
        	cursor: var(--cursor-bracket);
        }
        .zoom-control-wrapper {
        	    position: fixed;
    bottom: 0;
    right: 320px;
    display: flex;
    justify-content: start;
    z-index: 128;
    padding: 32px;
    height: 100vh;
    flex-flow: column;
    pointer-events: none;
        }
        .zoom-control {
         width: 32px;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.04) 0px 2px 4px;
    border: 1px solid rgb(231, 231, 231);
    border-radius: 24px;
    height: auto;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    /* gap: 16px; */
    pointer-events: all;
        }
        .nav-inactive .navigation {
            opacity: .5;
            pointer-events: none;
            transition: opacity .2s;
        }
</style>