<template>
  <div class="sta-forms">
    <div class="form card grid gap-1-5" v-if="formSchema">
      <template v-if="success">
        Form submission successful
      </template>
      <template v-else>
        <UiHeadline size="m">Form</UiHeadline>
        <FormGenerator :schema="formSchema" v-model="model" @submit="submit" :errors="errors" :submitting="submitting"></FormGenerator>
      </template>
    </div>
  </div>
</template>
<script setup>
import { ref, computed } from "vue"
import { useStore } from "vuex"
import { useRouter, useRoute } from "vue-router"
import axios from "axios";
import FormGenerator from "@lib/components/functional/FormGenerator/FormGenerator"

const baseUrl = ref("http://127.0.0.1:8000");

const store = useStore()
const router = useRouter()
const route = useRoute()
const model = ref({})
const formSchema = ref()
const submitting = ref(false)
const errors = ref(null)
const success = ref(false)

function isFile(item) {
  return (typeof File !== 'undefined' && item instanceof File);
}

function filterFiles(obj) {
  const files = [];
  
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      const item = obj[key];
      if (typeof File !== 'undefined' && item instanceof File) {
        files.push(item);
      }
    }
  }
  
  return files;
}


const fetchForm = () => {
  return axios.get(`${baseUrl.value}/forms/v1/request/${route.query.id}`);
}

fetchForm().then((res) => {
	formSchema.value = JSON.stringify(res.data)
})

const uploadFile = (formId, fileName, file) => {
	const formData = new FormData();
  formData.append("file", file);

  return axios.post(`${baseUrl.value}/forms/v1/submit-file/${formId}/${fileName}`, formData, {
    headers: {
      "Content-Type": "multipart/form-data"
    }
  });
}

const files = computed(() => filterFiles(model.value))

const uploadAllFiles = async () => {
  const uploadPromises = [];
  for (const key in files.value) {
    if (files.value.hasOwnProperty(key)) {
      // Push each upload promise into the array
      const uploadPromise = uploadFile(route.query.id, "file", files.value[key]);
      uploadPromises.push(uploadPromise);
    }
  }

  // Await all upload promises
  await Promise.all(uploadPromises);
}

const submit = () => {
  if (submitting.value) return


  uploadAllFiles(model, route).then(() => {
	  console.log('All files have been uploaded');
	  axios.post(`${baseUrl.value}/forms/v1/submit/${route.query.id}`, model.value).then(() => {
      success.value = true
    })
	}).catch(error => {
	  console.error('An error occurred while uploading files:', error);
	});

  return
  store.dispatch("dashboard/updateInteracted", true)
  submitting.value = true;
  store.dispatch("auth/login", model.value)
    .then((res) => {
      submitting.value = false;
      if (route.query.redirect) {
        router.push(route.query.redirect)
      } else {
        router.push('/')
      }
    })
    .catch((err) => {
    	console.log(err)
      submitting.value = false;
    });
}

</script>
<style scoped>
.sta-forms {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sta-forms .form {
  width: 100%;
  max-width: 512px;
  margin: auto;
}

.sta-forms .submit {
  margin-top: 24px;
}

</style>
