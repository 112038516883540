<template>
	<TabBar :tabs="tabs">
		<template #tab-0>
			<div class="form-field">
      	<label>Name:</label>
      	{{ modelValue.canvas.name }}
    	</div>
    	<div class="form-field">
      	<label>Type:</label>
      	{{ modelValue.canvas.type }}
    	</div>
		</template>
		<template #tab-1>
			
		</template>
	</TabBar>
</template>

<script setup>
	import { ref } from "vue"
	import TabBar from "./TabBar"

	const props = defineProps({
	  modelValue: {
	    type: Object,
	    default: () => ({}),
	  },
	});

	const tabs = ref([
    { label: 'Configure' },
    { label: 'Design' },
  ]);
</script>