// helpers.js

export function getParent(rect, rectangles) {
    return rect.parent ? rectangles.find(r => r.sequence_id === rect.parent) : null;
}

export function calculateScaledWidth(ctx, rect, content, horizontalResizing, parent, zoomLevel) {
    if (horizontalResizing === 'hugContents') {
        const lines = content.split('\n');
        const maxWidth = Math.max(...lines.map(line => ctx.measureText(line).width));
        rect.width = maxWidth / zoomLevel;
        return maxWidth;
    } else if (horizontalResizing === 'fillContainer' && parent) {
        const innerWidth = parent.innerWidth || parent.width;
        rect.width = innerWidth;
        return innerWidth * zoomLevel;
    }
    return rect.width * zoomLevel;
}

export function calculateScaledHeight(rect, content, fontSize, lineHeight, verticalResizing, parent, zoomLevel) {
    if (verticalResizing === 'hugContents') {
        const lines = content.split('\n');
        const totalHeight = lines.length * fontSize * lineHeight;
        rect.height = totalHeight / zoomLevel;
        return totalHeight;
    } else if (verticalResizing === 'fillContainer' && parent) {
        const innerHeight = parent.innerHeight || parent.height;
        rect.height = innerHeight;
        return innerHeight * zoomLevel;
    }
    return rect.height * zoomLevel;
}

export function calculateVerticalOffset(scaledHeight, lines, fontSize, lineHeight, verticalAlign, zoomLevel) {
    switch (verticalAlign) {
        case 'middle':
            return scaledHeight / 2 - (lines.length * fontSize * lineHeight * zoomLevel) / 2;
        case 'bottom':
            return scaledHeight - (lines.length * fontSize * lineHeight * zoomLevel);
        case 'top':
        default:
            return 0;
    }
}

export function drawTextLines(ctx, lines, scaledX, scaledY, scaledWidth, fontSize, lineHeight, horizontalAlign, yOffset, truncate, zoomLevel) {
    lines.forEach((line, index) => {
        let lineX = scaledX;
        let lineY = scaledY + yOffset + (index * fontSize * lineHeight * zoomLevel);

        switch (horizontalAlign) {
            case 'center':
                lineX = scaledX + scaledWidth / 2;
                break;
            case 'right':
                lineX = scaledX + scaledWidth;
                break;
            case 'left':
            default:
                lineX = scaledX;
                break;
        }

        if (truncate) {
            line = truncateText(ctx, line, scaledWidth);
        }

        ctx.fillText(line, lineX, lineY);
    });
}

export function truncateText(ctx, line, scaledWidth) {
    while (ctx.measureText(line + '...').width > scaledWidth) {
        line = line.substring(0, line.length - 1);
    }
    return line + '...';
}

export function handleRotation(ctx, scaledX, scaledY, scaledWidth, scaledHeight, rotation) {
    if (rotation !== 0) {
        ctx.translate(scaledX + scaledWidth / 2, scaledY + scaledHeight / 2);
        ctx.rotate(rotation * Math.PI / 180);
        ctx.translate(-(scaledX + scaledWidth / 2), -(scaledY + scaledHeight / 2));
    }
}

export function handleBoxShadow(ctx, boxShadowColor, boxShadowBlur, boxShadowOffsetX, boxShadowOffsetY) {
    if (boxShadowColor) {
        ctx.shadowColor = boxShadowColor || 'rgba(0, 0, 0, 0.5)';
        ctx.shadowBlur = boxShadowBlur || 10;
        ctx.shadowOffsetX = boxShadowOffsetX || 5;
        ctx.shadowOffsetY = boxShadowOffsetY || 5;
    }
}

export function handleBorderRadius(ctx, scaledX, scaledY, scaledWidth, scaledHeight, borderRadius) {
    ctx.beginPath();
    ctx.moveTo(scaledX + borderRadius, scaledY);
    ctx.lineTo(scaledX + scaledWidth - borderRadius, scaledY);
    ctx.quadraticCurveTo(scaledX + scaledWidth, scaledY, scaledX + scaledWidth, scaledY + borderRadius);
    ctx.lineTo(scaledX + scaledWidth, scaledY + scaledHeight - borderRadius);
    ctx.quadraticCurveTo(scaledX + scaledWidth, scaledY + scaledHeight, scaledX + scaledWidth - borderRadius, scaledY + scaledHeight);
    ctx.lineTo(scaledX + borderRadius, scaledY + scaledHeight);
    ctx.quadraticCurveTo(scaledX, scaledY + scaledHeight, scaledX, scaledY + scaledHeight - borderRadius);
    ctx.lineTo(scaledX, scaledY + borderRadius);
    ctx.quadraticCurveTo(scaledX, scaledY, scaledX + borderRadius, scaledY);
    ctx.closePath();
}

export function generateUniqueColor(id) {
  const hash = Array.from(id).reduce((acc, char) => acc + char.charCodeAt(0), 0);
  const r = (hash & 0xFF0000) >> 16;
  const g = (hash & 0x00FF00) >> 8;
  const b = hash & 0x0000FF;
  return `rgb(${r},${g},${b})`;
}

export function distance(point1, point2) {
    return Math.sqrt(Math.pow(point2.x - point1.x, 2) + Math.pow(point2.y - point1.y, 2));
}

export function findClosestSideCenter(rect, targetRect) {
    const centers = [
        { x: rect.x + rect.width / 2, y: rect.y },
        { x: rect.x + rect.width / 2, y: rect.y + rect.height },
        { x: rect.x, y: rect.y + rect.height / 2 },
        { x: rect.x + rect.width, y: rect.y + rect.height / 2 }
    ];

    const targetCenter = {
        x: targetRect.x + targetRect.width / 2,
        y: targetRect.y + targetRect.height / 2
    };

    let closestPoint = centers[0];
    let minDistance = distance(centers[0], targetCenter);

    centers.forEach(center => {
        const d = distance(center, targetCenter);
        if (d < minDistance) {
            closestPoint = center;
            minDistance = d;
        }
    });

    return closestPoint;
}