<template>
	<div class="ui-label">
		<slot></slot>
	</div>
</template>

<style scoped>
	.ui-label {
		color: #6b7280;
		font-size: 17px;
    font-weight: 500;
	}
</style>