import { createApp } from 'vue';
import vClickOutside from "click-outside-vue3";
import { install as VueMonacoEditorPlugin } from '@guolao/vue-monaco-editor' /* Todo: move to own repo together with Studio */
import * as components from '@lib/components/UI';
import App from './App.vue';
import router from './router';
import store from './store';
import '@lib/assets/css/variables.css';
import '@lib/assets/css/style.css';
import Layout from "./components/Layout";
import i18n from './services/i18n';

const app = createApp(App);

app.config.globalProperties.$t = i18n.global.t;

Object.entries(components).forEach(([name, component]) => {
  app.component(name, component);
});

app.component('Layout', Layout);

app.use(VueMonacoEditorPlugin, {
  paths: {
    // The recommended CDN config
    vs: 'https://cdn.jsdelivr.net/npm/monaco-editor@0.43.0/min/vs'
  },
})

app.use(vClickOutside).use(store).use(router).mount('#app');
