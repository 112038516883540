<template>
  <div class="form-field">
    <label :for="id" v-if="label">{{ label }}</label>
    <div class="dropdown-wrapper" @click="activateInput">
      <input
        v-show="isActive"
        :id="id"
        v-model="inputValue"
        @input="onInput"
        @focus="showDropdown = true"
        @blur="onBlur"
        :type="type"
        :placeholder="placeholder"
        @keyup.enter="onEnter"
        @keydown.up="onArrowUp"
        @keydown.down="onArrowDown"
        ref="inputRef"
      />
      <div v-if="!isActive" class="static-display">
        <div class="title">{{ selectedItem ? selectedItem.label : placeholder }}</div>
        <div class="info">{{ selectedItem ? selectedItem.description : placeholder }}</div>
      </div>
      <ul v-if="showDropdown" class="dropdown">
        <li 
          v-for="(item, index) in filteredItems" 
          :key="item.value" 
          @mousedown.prevent="selectItem(item)"
          :class="{ highlighted: index === highlightedIndex }"
          ref="dropdownItems"
        >
          <span>{{ item.icon }}</span>
          <span>{{ item.label }}</span>
          <span>{{ item.description }}</span>
        </li>
        <li @mousedown.prevent="createNew" class="create-new">
          <span>➕</span>
          <span>Create New</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, computed, nextTick, defineProps, defineEmits } from 'vue';
import Fuse from 'fuse.js';

const props = defineProps({
  modelValue: {
    type: String,
    default: ''
  },
  label: {
    type: String
  },
  id: {
    type: String
  },
  type: {
    type: String,
    default: 'text'
  },
  placeholder: {
    type: String,
    default: ''
  },
  items: {
    type: Array,
    default: () => []
  },
  fuseOptions: {
    type: Object,
    default: () => ({
      keys: ['label', 'description']
    })
  }
});

const emit = defineEmits(['update:modelValue', 'enter', 'create']);

const inputValue = ref(props.modelValue);
const showDropdown = ref(false);
const isActive = ref(true);
const inputRef = ref(null);
const highlightedIndex = ref(-1);
const dropdownItems = ref([]);

const fuse = new Fuse(props.items, props.fuseOptions);
const filteredItems = computed(() => {
  if (!inputValue.value) return props.items;
  return fuse.search(inputValue.value).map(result => result.item);
});

watch(() => props.modelValue, (newVal) => {
  inputValue.value = props.modelValue;
  isActive.value = false;
},{
  immediate: true
});

const onInput = (event) => {
  inputValue.value = event.target.value;
  showDropdown.value = true;
  highlightedIndex.value = -1;
};

const onBlur = () => {
  setTimeout(() => { // Delay to allow item click
    showDropdown.value = false;
  }, 200);
};

const selectItem = (item) => {
  inputValue.value = item.value;
  emit('update:modelValue', item.value);
  emit('change', item.value);
  showDropdown.value = false;
  isActive.value = false;
};

const activateInput = async () => {
  isActive.value = true;
  inputValue.value = '';
  await nextTick();
  inputRef.value.focus();
};

const onEnter = () => {
  if (highlightedIndex.value >= 0 && highlightedIndex.value < filteredItems.value.length) {
    selectItem(filteredItems.value[highlightedIndex.value]);
  } else {
    emit('enter', inputValue.value);
  }
};

const onArrowUp = (event) => {
  if (showDropdown.value) {
    event.preventDefault();
    highlightedIndex.value = (highlightedIndex.value <= 0) ? filteredItems.value.length - 1 : highlightedIndex.value - 1;
    scrollToHighlightedItem();
  }
};

const onArrowDown = (event) => {
  if (showDropdown.value) {
    event.preventDefault();
    highlightedIndex.value = (highlightedIndex.value + 1) % filteredItems.value.length;
    scrollToHighlightedItem();
  }
};

const scrollToHighlightedItem = () => {
  nextTick(() => {
    const itemElements = dropdownItems.value;
    if (itemElements[highlightedIndex.value]) {
      itemElements[highlightedIndex.value].scrollIntoView({
        block: 'nearest',
        behavior: 'smooth'
      });
    }
  });
};

const createNew = () => {
  emit('create', inputValue.value);
  showDropdown.value = false;
};

const selectedItem = computed(() => props.items.find(i => i.value == props.modelValue));
</script>

<style scoped>
.form-field {
  display: flex;
  flex-direction: column;
}

label {
  margin-bottom: 0.5rem;
  font-weight: bold;
}

.dropdown-wrapper {
  position: relative;
}

input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 1rem;
  width: 100%;
  box-sizing: border-box;
}

.static-display {
  font-family: Euclid Circular A;
  min-height: 52px;
  border: 1px solid #e7e7e7;
  border-radius: 12px;
  padding: 10px 16px;
  width: 100%;
  background: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -.76px;
  outline: none;
}

.static-display .title {
  color: #000;
}

.static-display .info {
  color: #9fb759;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  background: white;
  max-height: 200px;
  overflow-y: auto;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  z-index: 1000;
  list-style: none;
  padding: 0;
  margin: 0;
}

.dropdown li {
  padding: 0.5rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.dropdown li span {
  margin-right: 0.5rem;
}

.dropdown li:hover,
.dropdown li.highlighted {
  background-color: #f0f0f0;
}

.create-new {
  font-weight: bold;
  color: #007BFF;
}

.create-new:hover {
  background-color: #e0e0e0;
}
</style>
