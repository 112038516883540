<template>
    <div class="studio-edit-node">
            <TabBar :tabs="tabs">
              <template #tab-0>
                <div style="padding: 20px;">
                    <EditConfigure v-model="model" v-if="model" @enter="onUpdate" @save="onUpdate"></EditConfigure>

                    <div class="modal-footer">
                    <UiButton :loading="updating" @click="onUpdate">Update</UiButton>
                    <button class="btn-gray" id="closeButton" @click="$emit('close')" style="margin-top: 4px;">Close</button>
                  </div>
              </div>
              </template>
              <template #tab-1>
                <div class="rect-styling form" v-if="model.canvas">
                    <div class="form-style-group">
                        <div class="form-style-group-title">Frame</div>

                        <!-- Position -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">X</div>
                                <input v-model="model.canvas.x" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">Y</div>
                                <input v-model="model.canvas.y" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Width/Height -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">W</div>
                                <input v-model="model.canvas.width" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">H</div>
                                <input v-model="model.canvas.height" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Auto Sizing -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">-</div>
                                <select v-model="model.canvas.horizontalResizing" @change="onEditCanvasElementStyle">
                                    <option value="fixed">Fix</option>
                                    <option value="hugContents">Hug</option>
                                    <option value="fillContainer">Fill</option>
                                </select>
                            </div>
                            <div class="from-field">
                                <div class="form-label">|</div>
                                <select v-model="model.canvas.verticalResizing" @change="onEditCanvasElementStyle">
                                    <option value="fixed">Fix</option>
                                    <option value="hugContents">Hug</option>
                                    <option value="fillContainer">Fill</option>
                                </select>
                            </div>
                        </div>

                        <!-- Rotation/Border radius -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">RT</div>
                                <input v-model="model.canvas.rotation" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">bR</div>
                                <input v-model="model.canvas.borderRadius" @change="onEditCanvasElementStyle">
                            </div>
                        </div>
                    </div>

                    <!-- Auto Layout -->
                    <div class="form-style-group">
                        <div class="form-style-group-title">Auto layout</div>
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">-</div>
                                <select v-model="model.canvas.autoLayout" @change="onEditCanvasElementStyle">
                                    <option value="true">Enabled</option>
                                    <option value="false">Disabled</option>
                                </select>
                            </div>
                        </div>

                        <!-- Horizontal/Vertical Padding -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">hP</div>
                                <input v-model="model.canvas.horizontalPadding" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">vP</div>
                                <input v-model="model.canvas.verticalPadding" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Top/Right Padding -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">pT</div>
                                <input v-model="model.canvas.paddingTop" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">pR</div>
                                <input v-model="model.canvas.paddingRight" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Bottom/Left Padding -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">bP</div>
                                <input v-model="model.canvas.paddingBottom" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">pL</div>
                                <input v-model="model.canvas.paddingLeft" @change="onEditCanvasElementStyle">
                            </div>
                        </div>
                    </div>

                    <!-- Fill -->
                    <div class="form-style-group">
                        <div class="form-style-group-title">Fill</div>

                        <!-- Color -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">BG</div>
                                <input v-model="model.canvas.backgroundColor" @change="onEditCanvasElementStyle">
                            </div>
                        </div>
                    </div>

                    <!-- Stroke -->
                    <div class="form-style-group">
                        <div class="form-style-group-title">Stroke</div>

                        <!-- Color -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">CL</div>
                                <input v-model="model.canvas.strokeStyle" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Size -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">SZ</div>
                                <input v-model="model.canvas.lineWidth" @change="onEditCanvasElementStyle">
                            </div>
                        </div>
                    </div>

                    <!-- Shadow -->
                    <div class="form-style-group">
                        <div class="form-style-group-title">Shadow</div>

                        <!-- Coordinates -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">X</div>
                                <input v-model="model.canvas.boxShadowOffsetX" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">Y</div>
                                <input v-model="model.canvas.boxShadowOffsetY" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Blur/Spread -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">bL</div>
                                <input v-model="model.canvas.boxShadowBlur" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">sP</div>
                                <input v-model="model.canvas.boxShadowSpread" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Color -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">CL</div>
                                <input v-model="model.canvas.boxShadowColor" @change="onEditCanvasElementStyle">
                            </div>
                            <div class="from-field">
                                <div class="form-label">OP</div>
                                <input v-model="model.canvas.boxShadowColorOpacity" @change="onEditCanvasElementStyle">
                            </div>
                        </div>
                    </div>

                    <!-- Text -->
                    <div class="form-style-group">
                        <div class="form-style-group-title">Text</div>

                        <!-- Family -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">fF</div>
                                <select v-model="model.canvas.fontFamily" @change="onEditCanvasElementStyle">
                                    <option value="Cera">Cera</option>
                                    <option value="Arial">Arial</option>
                                </select>
                            </div>
                        </div>

                        <!-- Weight/Size -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">fF</div>
                                <select v-model="model.canvas.fontWeight" @change="onEditCanvasElementStyle">
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    <option value="600">600</option>
                                    <option value="700">700</option>
                                    <option value="800">800</option>
                                    <option value="900">900</option>
                                </select>
                            </div>
                            <div class="from-field">
                                <div class="form-label">SZ</div>
                                <input v-model="model.canvas.fontSize" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Height/Spacing -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">fF</div>
                                <select v-model="model.canvas.fontWeight" @change="onEditCanvasElementStyle">
                                    <option value="100">100</option>
                                    <option value="200">200</option>
                                    <option value="300">300</option>
                                    <option value="400">400</option>
                                    <option value="500">500</option>
                                    <option value="600">600</option>
                                    <option value="700">700</option>
                                    <option value="800">800</option>
                                    <option value="900">900</option>
                                </select>
                            </div>
                            <div class="from-field">
                                <div class="form-label">LS</div>
                                <input v-model="model.canvas.letterSpacing" @change="onEditCanvasElementStyle">
                            </div>
                        </div>

                        <!-- Align -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">fF</div>
                                <select v-model="model.canvas.horizontalAlign" @change="onEditCanvasElementStyle">
                                    <option value="left">Left</option>
                                    <option value="center">Center</option>
                                    <option value="right">Right</option>
                                </select>
                            </div>
                        </div>

                        <!-- Color -->
                        <div class="form-group form-group-2">
                            <div class="from-field">
                                <div class="form-label">fF</div>
                                <input v-model="model.canvas.color" @change="onEditCanvasElementStyle">
                            </div>
                        </div>
                    </div>

                    <!-- Content -->
                    <div>
                        Content <textarea v-model="model.canvas.content" @change="onEditCanvasElementStyle"/>
                    </div>
              

            </div>
              </template>
            </TabBar>


        <StudioModal v-if="showViewWebhookModal && currentReferenceItem" @close="showViewWebhookModal = false" >
            <vue-monaco-editor
                style="min-height: 512px;"
                v-model:value="currentReferenceItem.response_schema"
                theme="vs-dark"
                :options="MONACO_EDITOR_OPTIONS"
                @mount="handleMount"
              />
        </StudioModal>
    </div>
</template>


<script setup>
    import { ref, onMounted, computed, shallowRef, watch, defineEmits} from "vue"
    import { useStore } from "vuex"
    import { useRoute } from "vue-router"
    import StudioModal from "./Modal"
    import StudioModalBody from "./ModalBody"
    import TabBar from "./TabBar"
    import EditConfigure from "./EditConfigure"
    import VueJsonPretty from 'vue-json-pretty';
    import 'vue-json-pretty/lib/styles.css';
    import utils from '@lib/utils'
    import socket from "@lib/services/socket";

    const store = useStore()
    const route = useRoute()
    const updating = ref(false)
    const updatingData = ref(false)
    const testResult = ref(null)
    
    const sequenceRunResults = ref([])

    const MONACO_EDITOR_OPTIONS = {
      automaticLayout: true,
      formatOnType: true,
      formatOnPaste: true,
    }

    const tabs = ref([
      { label: 'Configure', content: 'Content for Tab 1' },
      { label: 'Design', content: 'Content for Tab 2' },
    ]);

    const props = defineProps({
        sequence: Object
    })

    const emit = defineEmits(["update", "updateStyle"])

    
    const conditionOptionsList = computed(() => store.state.studio.sequence.nodes.filter(s => ["send_form"].includes(s.key)).map(s => ({
        label: s.title,
        description: s.key,
        event: "condition_related",
        reference: s.key,
        value: s.id
    })))
    const referenceItemsLists = computed(() => [...store.getters['studio/getEventOptions'], ...conditionOptionsList.value])

    const editorRef = shallowRef()
    const handleMount = editor => (editorRef.value = editor)

    const showViewWebhookModal = ref(false)
    const showEventSendEmailModal = ref(false)
    const showEventSendFormModal = ref(false)

    const model = ref({
        data: {}
    })
    const modelQuerySelector = ref({
        mode: "",
        columnName: ""
    })

    watch(modelQuerySelector.value, () => {
        model.data.mode = modelQuerySelector.value.mode
        model.data.columnName = modelQuerySelector.value.columnName
    }, {
        deep: true
    })

    // const formattedModel = computed(() => ({
    //     title: model.value.title,
    //     key: model.value.key,
    //     status: model.value.status,
    //     data: {
    //         reference: currentReference.value,
    //         reference_id: model.value.reference_id,
    //     }
    // }))

    const currentReference = computed(() => availableReferences.value.find(r => r.key == model.value.key))

    watch(() => props.sequence, (updatedSequence) => {
        model.value = updatedSequence
        if (!model.value.data) model.value.data = {}
    }, {
        immediate: true,
        deep: true
    })

    const currentReferenceItem = computed(() => referenceItemsLists.value.find(w => w.value === model.value.reference_id && w.event === model.value.key))

    const selectedItem = ref(null)

    const onUpdateData = async () => {
        updatingData.value = true
        return new Promise((resolve, reject) => {
            if (model.value.sequence_id) {
                store.dispatch('studio/updateSequenceData', {
                    id: model.value.id,
                    data: {
                        data: model.value.data
                    }
                }).then((res) => {
                    resolve(res)
                    updatingData.value = false
                }).catch((err) => {
                    resolve(err)
                    updatingData.value = false
                })
            } else {
                reject("No sequence_id")
                updatingData.value = false
            }
        })
    }

    const onUpdate = async () => {
        return new Promise((resolve, reject) => {
            onUpdateData();
            if (model.value.sequence_id) {
                updating.value = true
                updatingData.value = true
                store.dispatch('studio/updateSequence', {
                    id: model.value.id,
                    data: {
                        title: model.value.title,
                        condition: model.value.condition,
                        key: model.value.key,
                        test_data: JSON.stringify(model.value.test_data),
                        ...currentReferenceItem.value && {
                            reference: currentReferenceItem.value.reference,
                            reference_id: model.value.reference_id,
                        }
                    }
                }).then((res) => {
                    updating.value = false
                    updatingData.value = false
                    model.value = res.data
                    resolve(res)
                }).catch((err) => {
                    updating.value = false
                    updatingData.value = false
                    resolve(err)
                })

                emit('update', model.value)
            } else {
                reject("No sequence_id")
            }
        })
    }

    const transformPreview = computed(() => {
        if (model.value.data.transformations) {
            return utils.transformObject(testResult.value, model.value.data.transformations)
        }
    })
    
    const outputPreview = computed(() => {
        if (model.value.data.output_template && testResult.value) {
            if (model.value.data.transformations) {
                return utils.fillTemplate(utils.transformObject(testResult.value, model.value.data.transformations), model.value.data.output_template)
            }
            return utils.fillTemplate(testResult.value, model.value.data.output_template)
        }
    })

    socket.emit('join', { room: `sequence_trigger_${route.query.sequence}`, user_id: store.state.user.user.id });

    socket.on('sequence_trigger_event', (data) => {
        sequenceRunResults.value.push(data)
    });

    const onEditCanvasElementStyle = () => {
        emit("updateStyle", model.value.canvas)
    }
</script>


<style setup>
button {
      height: 52px;
    background: #dffc8c;
    color: #313131;
    border-radius: 12px;
    padding: 16px 32px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.21px;
    font-size: 16px;
    display: inline-block;
    text-align: center;
    cursor: pointer;
        border: none;
        font-family: 'Cera';
        font-weight: 600;
}
.btn-gray {
  background: #fbfbfb;
  color: #313131;
}
.form-field .textarea-edit {
        font-family: Euclid Circular A;
    height: 100%;
    border: 1px solid #e7e7e7;
    border-radius: 12px;
    padding: 16px;
    width: 100%;
    background: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -.76px;
    outline: none;
    min-height: 512px;
}

.form {
    font-size: 14px;
}

.form-group {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    margin-bottom: 4px;
}

.form-group-2 {
    max-width: 96px;
}

.from-field {
    width: 100%;
    display: flex;
    border: 1px solid transparent;
}

.from-field:hover {
    border: 1px solid rgb(231, 231, 231);
}

.from-field input, .from-field select {
    width: 64px;
    height: 28px;
    padding: 0 4px;
    font-weight: 500;
    border: 1px solid transparent;
    outline: none;
    border-radius: 0;
    font-size: 14px;
    font-family: "Euclid Circular A";
}

.form-label {
    height: 28px;
    width: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #7b7b7b;
}

.form-style-group {
    border-bottom: 1px solid rgb(231, 231, 231);
    padding: 8px 16px;
}

.form-style-group-title {
    font-weight: 600;
    margin-bottom: 8px;
}
</style>