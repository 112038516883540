<template>
  <Layout>
    <Panel :menu="menuItems"></Panel>
  </Layout>
</template>

<script setup>
  import { ref } from "vue"
  import Panel from "@lib/views/Panels/Panel";

  const menuItems = [
    {
      key: 'file',
      name: 'File',
      icon: 'file',
      children: [
        {
          key: 'new',
          name: 'Export As',
          icon: 'plus',
          children: [
            { key: 'new-file', name: 'CSV', icon: 'file' },
            { key: 'new-folder', name: 'JSON', icon: 'folder' },
          ],
        },
        { key: 'open', name: 'Open', icon: 'folder-open' },
        { key: 'save', name: 'Save', icon: 'save' },
      ],
    },
    {
      key: 'edit',
      name: 'Edit',
      icon: 'edit',
      children: [
        { key: 'undo', name: 'Undo', icon: 'undo' },
        { key: 'redo', name: 'Redo', icon: 'redo' },
      ],
    },
    // Add more menu items as needed
  ]
</script>
