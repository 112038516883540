<template>
	<div class="ui-locked" :class="{'is-locked':!unlocked}" @click="onClick">
		<div class="content">
			<slot></slot>
		</div>
		<UiAlert v-if="text && !unlocked">{{ text }}</UiAlert>
	</div>
</template>

<style scoped>
	.ui-locked {
		position: relative;
	}

	.ui-locked.is-locked .content {
		opacity: .25;
		pointer-events: none;
	}

	.ui-locked .ui-alert {
		padding: 4px;
		position: absolute;
		left: 0;
		bottom: 0;
	    font-size: 12px;
	    font-style: normal;
	    font-weight: 500;
	    line-height: normal;
	    letter-spacing: 0.17px;
	}
</style>

<script setup>
	import { defineEmits } from "vue"

	const props = defineProps({
		text: {
			type: String,
			default: "[Premium feature] available in the full program"
		},
		unlocked: Boolean
	})

	const emit = defineEmits(["click-locked"])

	const onClick = () => {
		if (!props.unlocked) emit("click-locked")
	}
</script>