import io from "socket.io-client";

let config = {
  transports: ['websocket'],
  cors: {
    origin: "https://app.virt-ai.com",
    methods: ["GET", "POST"]
  }
};

const socket = io.connect(process.env.VUE_APP_SOCKET_URL, config);

export default socket;
