<template>
	<div :class="classes">
		<slot></slot>
	</div>
</template>

<style scoped>
	.ui-row {
		display: flex;
	}
	.ui-row.align-center {
		align-items: center;
	}
	.ui-row.justify-center {
		justify-content: center;
	}
	.ui-row.justify-between {
		justify-content: space-between;
	}
	.ui-row.justify-end {
		justify-content: end;
	}
	.ui-row.gap-1 {
		gap: 4px;
	}
	.ui-row.gap-2 {
		gap: 8px;
	}
	.ui-row.gap-4 {
		gap: 16px;
	}
	.ui-row.gap-8 {
		gap: 32px;
	}
	.ui-row.gap-16 {
		gap: 64px;
	}
</style>

<script setup>
	import { computed } from "vue"

	const props = defineProps({
		gap: {
			type: String,
			default: ''
		},
		align: {
			type: String,
			default: ''
		},
		justify: {
			type: String,
			default: ''
		},
	})

	const classes = computed(() => [
		'ui-row',
		`align-${props.align}`,
		`gap-${props.gap}`,
		`justify-${props.justify}`,
	])
</script>