<template>
  <div class="form-field">
    <textarea
      v-model="internalValue"
      :readonly="!isEditable"
      @click="makeEditable"
      @input="autoExpand"
      ref="textarea"
      :class="['auto-expand-textarea', { 'editable': isEditable, 'not-editable': !isEditable && props.toggleEdit }]"
    ></textarea>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, nextTick } from 'vue';

// Props
const props = defineProps({
  modelValue: String,
  toggleEdit: Boolean
});

// Emit function to update the parent component's v-model
const emit = defineEmits(['update:modelValue']);

// Internal state
const internalValue = ref(props.modelValue);
const isEditable = ref(!props.toggleEdit);
const textarea = ref(null);

const autoExpand = () => {
  const el = textarea.value;
  if (el) {
    el.style.height = 'auto'; // Reset the height
    el.style.height = el.scrollHeight + 24 + 'px'; // Set the height to match the scroll height
  }
};

const makeEditable = () => {
  if (props.toggleEdit) {
    isEditable.value = true;
  }
};

onMounted(() => {
  autoExpand(); // Auto-expand on mount in case there's initial content
});

watch(internalValue, () => {
  nextTick(() => {
    autoExpand();
    emit('update:modelValue', internalValue.value);
  });
});

watch(() => props.modelValue, (newValue) => {
  internalValue.value = newValue;
});
</script>

<style>
.auto-expand-textarea {
  width: 100%;
  min-height: 50px;
  resize: none; /* Disable manual resizing */
  overflow: hidden; /* Hide scrollbars */
  transition: background-color 0.3s;
}

.auto-expand-textarea.not-editable {
    border: none;
    padding: 0;
    cursor: default;
}

.auto-expand-textarea.editable {
  background-color: white;
}
</style>
