<template>
  <div class="copy-to-clipboard" @click="copyText">
  	<UiRow gap="1">
	    <slot></slot>
	    <UiRow gap="1" style="width: 62px;">
		    <a v-if="copied">Copied</a>
		    <a v-else>
		    	<UiRow gap="1">
		    		<Icon icon="duplicate" :size="19" color="#215dff"></Icon> 
		    		<div>Copy</div>
		    	</UiRow>
		    </a>
		</UiRow>
	</UiRow>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const props = defineProps({
  textToCopy: {
    type: String,
    required: true
  }
});

const emit = defineEmits(['copied']);
const copied = ref(false)

const copyText = async () => {
  try {
    await navigator.clipboard.writeText(props.textToCopy);
    // Emit an event to notify that the text has been copied
    copied.value = true
    emit('copied', true);
    // You can also use a local state variable to show a confirmation message
    setTimeout(() => {
    	copied.value = false
    }, 1000)
  } catch (err) {
    console.error('Failed to copy text: ', err);
    emit('copied', false);
  }
};
</script>

<style>
/* Add your CSS styling here */
.copy-to-clipboard {
  cursor: pointer;

  /* Add more styling as needed */
}
</style>
