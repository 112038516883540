const config = {
  namespaced: true,
  state: {
    config: {
      landing_page: {
        hero_section: "coming_soon" // ["coming_sooc", "sign_up"]
      }
    },
  },
  getters: {
    get: (state) => {
      return state.config;
    },
  },
  mutations: {
  },
  actions: {}
};

export default config;