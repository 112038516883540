<template>
	<Layout>
		<div class="panel">
			<div class="panel-content">
				<div class="content">
					<UiChatMessages class="messages" v-if="$route.name === 'virt'" :messages="messages">
						<UiChatMessage :is-sender="message.is_sender" v-for="message in messages" :key="message.id" :message="message.message" :author="message.author">

								<!-- <UiAlert class="message-box" :color="message.messageColor" style="margin-bottom: 24px;">
						      <template v-slot:title v-if="message.title">{{ message.title }}</template>
						      <template v-slot:text><div v-html="message.message"/></template>
						    </UiAlert> -->
						    <div class="message-loading" v-if="message.loading">
						    	<UiLoadingSpinner></UiLoadingSpinner> Processing your request
						    </div>

						    <div v-if="message.data && message.data.task && (message.data.task.confirmed || message.data.task.result)" style="margin-bottom: 32px;">
							  	<div style="display: flex;">
								  	<UiCard type="flats" style="font-size: 16px;font-size: 16px;border: 1px solid #e7e7e7;border-radius: 12px;max-width: 512px;margin-top: 32px;position: relative;overflow: auto;">
								  		<!-- <div v-if="message.data.task.title">
								  			<UiLabel>Task</UiLabel>
								  			<div>{{message.data.task.title}}</div>
								  		</div> -->

								  		<!-- Task Title -->
								  		<div v-if="message.data.task.description">
								  			<UiLabel>Task</UiLabel>
								  			<div>{{message.data.task.description}}</div>
								  		</div>

								  		<!-- Task Schedule -->
								  		<div v-if="message.data.task.schedule" style="margin-top: 8px;">
								  			<UiLabel>Schedule</UiLabel>
								  			<template v-if="message.data.task.schedule">
									  			<div v-if="message.data.task.schedule.days">
									  				Weekly on the following day<template v-if="message.data.task.schedule.days.length > 1">s</template>: <span v-for="day in message.data.task.schedule.days" style="color: blue;">{{ day }}, </span> <template v-if="message.data.task.schedule.time">at <span style="color: blue;">{{ message.data.task.schedule.time }}</span></template>
									  			</div>
									  			<div v-if="message.data.task.schedule.interval_minutes">
									  				Every {{ message.data.task.schedule.interval_minutes }} Minute<template v-if="message.data.task.schedule.interval_minutes > 0">s</template>
									  			</div>
								  			</template>
								  		</div>

								  		<!-- Table -->
								  		<div v-if="message.data.task.result && message.data.task.result.table">
										    <table v-if="isArray(message.data.task.result.table)" style="font-size: 14px;">
										      <tr v-for="row in message.data.task.result.table" :key="row.id">
										        <td v-for="(cell, index) in row" :key="index" style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">
										          {{ cell }}
										        </td>
										      </tr>
										    </table>

										    <table v-if="isObject(message.data.task.result.table)" style="font-size: 14px;">
										      <tr v-for="(value, key) in message.data.task.result.table" :key="key">
										        <td style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ key }}</td>
										        <td style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;">{{ value }}</td>
										      </tr>
										    </table>
										  </div>

										  <!-- Status -->
								  		<UiBadge type="success" v-if="message.data.task.updated" style="margin-top: 8px;">Updated</UiBadge>
								  			
								  		<!-- CTA -->
								  		<div style="display: flex;justify-content: right;">
								  			<UiButton color="outline" size="s">View</UiButton>
								  		</div>
								  	</UiCard>
							  	</div>
							  </div>

							  <div v-if="message.type === 'form'">
							  	<UiCard>
							  		<FormGenerator :schema="message.formSchema" @submit="onSubmitForm" :form-key="message.data.task"></FormGenerator>
							  	</UiCard>
							  </div>
							  <div v-if="message.type === 'select-option' && message.data">
							  	<div style="display: flex;">
								  	<UiCard v-for="option in message.data.options" type="flat">
								  		<div>{{ option.title }}</div>
								  		<div>{{ option.text }}</div>
								  		<div v-if="option.FIELDS">
								  			<div v-for="(item, key) in option.FIELDS">
								  				<UiInput :modelValue="item" :label="key"></UiInput>
								  			</div>
								  		</div>
								  		<UiButton color="outline">Select</UiButton>
								  	</UiCard>
							  	</div>
							  </div>
							  <div v-if="message.type === 'select'">
							  	<div style="display: flex;">
								  	<UiCard v-for="option in message.options" type="flat">
								  		<UiButton color="outline">{{ option.title }}</UiButton>
								  	</UiCard>
							  	</div>
							  </div>
							  <template v-if="message.type === 'iframe'">
							  	<UiCard type="flat">
							  		<div class="message-iframe-wrapper">
							  			<iframe class="message-iframe" :src="message.websiteUrl"></iframe>
							  		</div>
							  		<UiButton color="outline">Yes</UiButton>
							  		<UiButton color="outline">No</UiButton>
							  	</UiCard>
							  </template>

				  	</UiChatMessage>
			  		<template v-slot:footer>
			  			<!-- <FormGenerator :schema="currentForm.formSchema" @submit="onSubmitForm" :form-key="currentForm.key" v-if="currentForm"></FormGenerator> -->
			  			<UiInput v-model="message" @enter="sendMessage"></UiInput>
			  		</template>
			  	</UiChatMessages>
					<UiCard type="flat" footer-sticky head-sticky v-else>
						<router-link :to="`/virts/${chat.id}`" class="list-item" v-for="chat in chats">
							<UiAvatar size="32px" img-url="https://virt-ai.com/assets/img/jai.png" /> {{ chat.name }}
						</router-link>

				  	<div class="vert-chat" v-if="$route.name === 'virts'">
				  		<UiHeadline>
				  			Team
				  		</UiHeadline>
				  		<div style="margin-top: 32px;">
					  		<div class="message" v-for="virt in virts">
									<UiAvatar :imgUrl="virt.avatar_url"></UiAvatar>
									<div class="message-content">
										<UiAlert class="message-box" color="message" style="margin-bottom: 24px;">
								      <template v-slot:title v-if="virt.name">{{ virt.name }}</template>
								      <template v-slot:text>
								      	<div v-html="virt.job_id"/>
								      	<div>
								      		<UiBadge type="gray" style="margin-top: 8px;">Idle</UiBadge>
								      		<UiButton size="s" color="outline" style="margin-top: 4px;" :to="`/virts/${virt.id}`">Assign Task</UiButton>
								      	</div>
								      </template>
								    </UiAlert>
								  </div>
								</div>
							</div>
				  	</div>
			  	</UiCard>
		  	</div>
		  </div>
		</div>
	</Layout>
</template>

<script setup>
	import { ref, computed, watch, onUpdated } from "vue"
	import { useStore } from "vuex"
	import { useRouter, useRoute } from "vue-router"
	import FormGenerator from "@lib/components/functional/FormGenerator/FormGenerator"
	import socket from "@lib/services/socket";

	localStorage.debug = '*';

	const store = useStore()
	const route = useRoute()

  store.dispatch('virt/fetchVirts')

  const virts = computed(() => store.state.virt.virts)
  const chats = computed(() => store.state.virt.chats)
  const currentChat = computed(() => chats.value ? chats.value[chats.value.length - 1] : null)
  const currentForm = ref(null)

  const fetchChats = () => {
  	store.dispatch('virt/fetchChats', {}).then(() => {
  		
  	}) 
  }

  // fetchChats()

  const fetchChatsByVirtId = (id) => {
  	store.dispatch('virt/fetchChatsByVirtId', {
  		id
  	}).then(() => {
  		fetchChatMessages(currentChat.value.id)
  	}) 
  }

	watch(
	    () => route.params.id, 
	    (newId) => {
	        if (newId) {
	            fetchChatsByVirtId(newId)
	        }
	    },
	    {
	        deep: true,
	        immediate: true
	    }
	)

	const isArray = (data) => {
    return Array.isArray(data);
  }

  const isObject = (data) => {
    return data !== null && typeof data === 'object' && !Array.isArray(data);
  }

  const fetchChatMessages = (id) => {
  	store.dispatch('virt/fetchChatMessages', {
  		id
  	}).then((res) => {
  		messages.value = res.data
  	})
  }

	watch(
	    () => currentChat.value, 
	    (newChat) => {
	        if (newChat) {
	            fetchChatMessages(newChat.id)
	        }
	    },
	    {
	        deep: true,
	        immediate: true
	    }
	)

  const onSubmitForm = (data) => {
  	socket.emit("message", { 
  		type: 'confidential_task', 
  		msg: message.value, 
  		chat_id: currentChat.value.id, 
  		data, 
  		user_id: store.state.user.user.id 
  	});
  }

	const authorJaiVirt = {
		name: "Jai Virt",
		profile_img: "https://img.freepik.com/premium-photo/graphic-designer-digital-avatar-generative-ai_934475-9292.jpg"
	}

	const messages = ref([
	// {
	// 	type: "text",
	// 	title: "Jai Virt",
	// 	author: authorJaiVirt,
	// 	message: "Hi I'm Jai, your first Virt. I'm kinda like an employee but just 50x faster and I work 24/7 and yea as I don't need any things like a human.. house, car, food I'm fine with my salary.",
	// 	messageColor: "message"
	// },
	// {
	// 	type: "text",
	// 	author: authorJaiVirt,
	// 	message: "I will help you manage your business.",
	// 	messageColor: "message",
	// },
	// {
	// 	type: "form",
	// 	author: authorJaiVirt,
	// 	message: "Let's get to work. Do you have a website already?",
	// 	messageColor: "message",
	// 	formSchema: `{
	// 		  "rows": [
	// 		    {
	// 		      "columns": [
	// 		        {
	// 		          "fields": [
	// 		            {
	// 		              "key": "email",
	// 		              "type": "email",
	// 		              "label": "Website Domain",
	// 		              "name": "email",
	// 		              "placeholder": "",
	// 		              "required": true
	// 		            }
	// 		          ]
	// 		        }
	// 		      ]
	// 		    },
	// 		    {
	// 		      "columns": [
	// 		        {
	// 		          "fields": [
	// 		            {
	// 		              "type": "submit",
	// 		              "label": "Submit"
	// 		            }
	// 		          ]
	// 		        }
	// 		      ]
	// 		    }
	// 		  ]
	// 		}
	// 		`
	// },
	// {
	// 	type: "select-option",
	// 	title: "Is this your business model?",
	// 	author: authorJaiVirt,
	// 	message: "I wan't to make sure I have the right informations, please confirm if this is your business model?",
	// 	messageColor: "message",
	// 	options: [
	// 		{
	// 			title: "Option 1",
	// 			text: `
	// 			Traders who work with us quickly see the difference beween Purdia Capital and other funding firms. Our clear rules, straightforward approach, and incentives to help our traders are immediately apparent and appreciated by many.

	// 			See what some of our traders are saying about Purdia Capital.`
	// 		},
	// 		{
	// 			title: "Option 2",
	// 			text: `
	// 			Traders who work with us quickly see the difference and incentives to help our traders are immediately apparent and appreciated by many.
	// 			See what some of our traders are saying about Purdia Capital.`
	// 		}
	// 	] 
	// },
	// {
	// 	type: "iframe",
	// 	author: authorJaiVirt,
	// 	message: "And is this your website right?",
	// 	messageColor: "message",
	// 	websiteUrl: "https://purdia.com"
	// },
	// {
	// 	type: "text",
	// 	author: {
	// 		name: "Jonas",
	// 		profile_img: "https://media.licdn.com/dms/image/D4D03AQH82NuJDu0YbQ/profile-displayphoto-shrink_400_400/0/1714418506510?e=1722470400&v=beta&t=hsqH-d0DE1ZGyhTQ_2_XSxzI4aIuwIdn15TtYSzi24I"
	// 	},
	// 	message: "I wan't to update my password",
	// 	messageColor: "message-user",
	// 	isAuthor: true
	// },
	// {
	// 	type: "form",
	// 	author: authorJaiVirt,
	// 	message: "Sure let's update your password, I will go in offline mode as I'm not permitted to access this information.",
	// 	messageColor: "message",
	// 	formSchema: `{
	// 		  "rows": [
	// 		    {
	// 		      "columns": [
	// 		        {
	// 		          "fields": [
	// 		            {
	// 		              "key": "email",
	// 		              "type": "email",
	// 		              "label": "New Password",
	// 		              "name": "email",
	// 		              "placeholder": "",
	// 		              "required": true
	// 		            }
	// 		          ]
	// 		        },
	// 		        {
	// 		          "fields": [
	// 		            {
	// 		              "key": "email",
	// 		              "type": "email",
	// 		              "label": "Repeat Password",
	// 		              "name": "email",
	// 		              "placeholder": "",
	// 		              "required": true
	// 		            }
	// 		          ]
	// 		        }
	// 		      ]
	// 		    },
	// 		    {
	// 		      "columns": [
	// 		        {
	// 		          "fields": [
	// 		            {
	// 		              "type": "submit",
	// 		              "label": "Update Password"
	// 		            }
	// 		          ]
	// 		        }
	// 		      ]
	// 		    }
	// 		  ]
	// 		}
	// 		`
	// },
	// {
	// 	type: "system-alert",
	// 	title: "Safety alert",
	// 	author: {
	// 		profile_img: ""
	// 	},
	// 	message: "Your password will not be passed to the AI, your request will go directly to our database.",
	// 	messageColor: "danger"
	// },
	// {
	// 	type: "system-alert",
	// 	title: "System alert",
	// 	author: {
	// 		profile_img: ""
	// 	},
	// 	message: "Your password has been updated successfully",
	// 	messageColor: "success"
	// },
	// {
	// 	type: "text",
	// 	author: {
	// 		name: "Jonas",
	// 		profile_img: "https://media.licdn.com/dms/image/D4D03AQH82NuJDu0YbQ/profile-displayphoto-shrink_400_400/0/1714418506510?e=1722470400&v=beta&t=hsqH-d0DE1ZGyhTQ_2_XSxzI4aIuwIdn15TtYSzi24I"
	// 	},
	// 	message: "Yes",
	// 	messageColor: "message-user",
	// 	isAuthor: true
	// },
	// {
	// 	type: "text",
	// 	author: authorJaiVirt,
	// 	message: "Cool, I'm importing your pages now so I get a better sence of what your business is all about.",
	// 	messageColor: "message",
	// 	loading: true
	// },
	// {
	// 	type: "select",
	// 	author: authorJaiVirt,
	// 	message: "There are some typos on a few pages, would you like me to fix them?",
	// 	messageColor: "message",
	// 	options: [
	// 		{
	// 			title: "Yes"
	// 		},
	// 		{
	// 			title: "No"
	// 		}
	// 	]
	// },
	// {
	// 	type: "text",
	// 	author: {
	// 		name: "Jonas",
	// 		profile_img: "https://media.licdn.com/dms/image/D4D03AQH82NuJDu0YbQ/profile-displayphoto-shrink_400_400/0/1714418506510?e=1722470400&v=beta&t=hsqH-d0DE1ZGyhTQ_2_XSxzI4aIuwIdn15TtYSzi24I"
	// 	},
	// 	message: "No",
	// 	messageColor: "message-user",
	// 	isAuthor: true
	// },
	// {
	// 	type: "text",
	// 	author: authorJaiVirt,
	// 	message: "Ok no worries, but I will still add it to the todo list so we don't forget about it",
	// 	messageColor: "message"
	// },
	// {
	// 	type: "select",
	// 	author: authorJaiVirt,
	// 	message: "What do you want to do next?",
	// 	messageColor: "message",
	// 	options: [
	// 		{
	// 			title: "Market my business"
	// 		},
	// 		{
	// 			title: "Analyse Finances"
	// 		},
	// 		{
	// 			title: "Send newsletter"
	// 		}
	// 	]
	// },
	])

	/* Socket */
	let socket_connected = ref(false);
	let reconnecting = ref(false);
	const message = ref('')

	socket.on("connect", () => {
    socket_connected.value = true;
    reconnecting.value = false;
    socket.off("message");

    socket.on("message", (data) => {
      messages.value.push(data);

      if (data.type === "form") {
      	currentForm.value = {
	      	formSchema: data.formSchema,
	      	key: data.data.task
	      }
      }
    });
  });

  socket.on('disconnect', (reason) => {
    console.log('Socket disconnected:', reason);
	});

	socket.on('connect_error', (error) => {
	    console.error('Connection error:', error.message);
	});

	socket.on('error', (error) => {
	    console.error('Socket error:', error.message);
	});

	// Log connection attempts
	socket.on('reconnect_attempt', () => {
	    console.log('Reconnection attempt');
	});

	socket.on('reconnect_failed', () => {
	    console.log('Reconnection failed');
	});

	const sendMessage = () => {
    if (message.value.trim() !== "") {
      socket.emit("message", { 
      	msg: message.value, chat_id: 
      	currentChat.value ? currentChat.value.id : null, 
      	virt_id: route.params.id, 
      	user_id: store.state.user.user.id 
      });
      message.value = "";
    }
  }
</script>

<style>
	.list-item {
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 6px;
    border-radius: 5px;
    border: 1px solid #e8e8e8;
    border-radius: 10px;
    cursor: pointer;
    /* height: 56px; */
    /* display: flex; */
    overflow: hidden;
    display: flex;
    width: 100%;
    align-items: center;
}
</style>