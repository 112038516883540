<template>
  <div id="capp">
    <div class="lists-container">
      <div class="list" ref="leftList">
        <div v-for="item in leftItems" :key="item.id" class="item">
          <button
            @mousedown="startConnecting(item, $event)"
            @mouseup="stopConnecting(item)"
          >
            {{ item.name }}
          </button>
        </div>
      </div>
      <div class="list" ref="rightList">
        <div v-for="item in rightItems" :key="item.id" class="item">
          {{ item.name }}
        </div>
      </div>
    </div>
    <svg ref="svg" class="canvas">
      <line
        v-for="(connection, index) in connections"
        :key="index"
        :x1="connection.startX"
        :y1="connection.startY"
        :x2="connection.endX"
        :y2="connection.endY"
        stroke="black"
      />
    </svg>
  </div>
</template>

<script>
export default {
  data() {
    return {
      leftItems: [
        { id: 1, name: 'Left Item 1' },
        { id: 2, name: 'Left Item 2' },
        // Add more items as needed
      ],
      rightItems: [
        { id: 1, name: 'Right Item 1' },
        { id: 2, name: 'Right Item 2' },
        // Add more items as needed
      ],
      connecting: false,
      startX: 0,
      startY: 0,
      connections: [],
    };
  },
  methods: {
    startConnecting(item, event) {
      this.connecting = true;
      const rect = this.$refs.leftList.getBoundingClientRect();
      this.startX = event.clientX - rect.left;
      this.startY = event.clientY - rect.top;

      document.addEventListener('mousemove', this.drawTemporaryLine);
      document.addEventListener('mouseup', this.stopConnecting);
    },
    drawTemporaryLine(event) {
      if (!this.connecting) return;

      const rect = this.$refs.svg.getBoundingClientRect();
      const svg = this.$refs.svg;

      // Remove temporary line if it exists
      const tempLine = svg.querySelector('.temp-line');
      if (tempLine) {
        svg.removeChild(tempLine);
      }

      const line = document.createElementNS('http://www.w3.org/2000/svg', 'line');
      line.setAttribute('x1', this.startX);
      line.setAttribute('y1', this.startY);
      line.setAttribute('x2', event.clientX - rect.left);
      line.setAttribute('y2', event.clientY - rect.top);
      line.setAttribute('stroke', 'red');
      line.classList.add('temp-line');
      svg.appendChild(line);
    },
    stopConnecting(event) {
      if (!this.connecting) return;

      this.connecting = false;

      const rect = this.$refs.rightList.getBoundingClientRect();
      const endX = event.clientX - rect.left;
      const endY = event.clientY - rect.top;

      this.connections.push({
        startX: this.startX,
        startY: this.startY,
        endX: endX,
        endY: endY,
      });

      const svg = this.$refs.svg;
      const tempLine = svg.querySelector('.temp-line');
      if (tempLine) {
        svg.removeChild(tempLine);
      }

      document.removeEventListener('mousemove', this.drawTemporaryLine);
      document.removeEventListener('mouseup', this.stopConnecting);
    },
  },
};
</script>

<style>
#capp {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
}
.lists-container {
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin-top: 20px;
}
.list {
  width: 40%;
}
.item {
  margin: 10px 0;
}
</style>
