<template>
	<li
    class="menu-item"
    :key="item.key"
    @click="onShow"
    v-click-outside="hide"
  >
    <div class="menu-item-content">
      <!-- <UiIcon :icon-name="item.icon" size="16"/> -->
      <span>{{ item.name }}</span>
    </div>
    <Dropdown v-if="item.children" :items="item.children" v-show="show" @click-item="onClick"/>
  </li>
</template>

<script setup>
	import { ref, defineEmits, nextTick } from "vue"
	import Dropdown from './Dropdown';

	const props = defineProps({
		item: Object
	})

  const emit = defineEmits(["click-item"])

	const show = ref(false)

	const hide = () => {
		show.value = false
	}

  const onShow = () => {
    show.value = true
  }

  const onClick = async (e) => {
    emit('click-item', e)
    await nextTick();
    hide()
  }
</script>

<style scoped>
.menu-item {
  position: relative;
  padding: 0 8px;
}

.menu-item:hover {
  border-radius: 6px;
  background: lightgray;
}

.menu-item-content {
  display: flex;
  align-items: center;
  gap: 5px;
}

.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  transition: background 0.3s;
}

.dropdown-item:hover {
  background: #e9ecef;
}

.dropdown-item-content {
  display: flex;
  align-items: center;
  gap: 5px;
}
</style>