<template>
  <div>
    <table>
      <thead>
        <tr>
          <th v-for="(label, field) in fieldLabels" :key="field">{{ label }}</th>
          <th>ID</th>
          <th>Table ID</th>
          <th>Reference Type</th>
          <th>Reference ID</th>
          <th>Created At</th>
          <th>User ID</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="entry in data" :key="entry.id">
          <td v-for="(label, field) in fieldLabels" :key="field">{{ entry.data[field] }}</td>
          <td>{{ entry.id }}</td>
          <td>{{ entry.table_id }}</td>
          <td>{{ entry.reference_type }}</td>
          <td>{{ entry.reference_id }}</td>
          <td>{{ entry.created_at }}</td>
          <td>{{ entry.user_id }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    fieldLabels: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style>
table {
  width: 100%;
  border-collapse: collapse;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  text-align: left;
}
</style>
