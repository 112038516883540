<template>
  <div :class="['panel', containerClass]">
    <div class="panel-content">
      <!-- <UiSidebar :items="navItems" /> -->
      <div class="content">
        <UiAlert color="warning" style="margin-bottom: 24px;">
          <template v-slot:title>Additional information required</template>
          <template v-slot:text>Provide your business owner information. View details</template>
        </UiAlert>
        <UiHeadline size="l" style="padding: 0 24px;flex: none;">
          {{ title }}
        </UiHeadline>
        <UiCard type="flat" footer-sticky head-sticky>
          <template v-slot:head>
            <UiMenuBar :items="menuItems" style="width:100%;"/>
            <div style="width:100%;display: flex;justify-content: end;">
              <UiButton color="outline" size="s">Edit Dashboard</UiButton>
            </div>
          </template>

          <div style="margin-bottom: 64px;">
            <UiCard>
              <div style="display: flex; justify-content:space-around;">
                <template v-for="stat in stats">
                  <div style="padding-right: 0px;">
                    <UiHeadline size="m">
                      {{ stat.name }}
                    </UiHeadline>
                    <div style="font-size: 64px;display: flex;">
                      <div v-html="stat.value"></div>
                      <span style="color: #228b22;font-size: 24px">{{ stat.change }}</span>
                    </div>
                    <div style="display: flex;gap: 2px;">
                      <!-- <div v-for="bar in stat.range" style="width: 16px;display: flex;justify-content: center;flex-direction: column;align-items: center;">
                        <div style="width: 8px;height: 32px; background: #e0fc8c;border-radius: 3px;"></div>
                        <div style="font-size: 11px;text-align: center;">139</div>
                      </div> -->
                    </div>
                    <div style="color: rgb(123, 123, 123);">Past 12 Weeks</div>
                    <div style="max-width: 250px;" v-if="stat.chart">
                      <Chart v-bind="stat.chart"/>
                    </div>
                  </div>
                  <div style="height: 128px;width: 1px;background: #e7e7e7;display: block;"></div>
                </template>
              </div>
            </UiCard>
          </div>

          <div style="display: flex;gap: 8px;">
            <div style="width: 100%;display: flex;flex-direction: column;gap: 64px;">
              <div>
                <UiHeadline size="l">Campaign Health</UiHeadline>
                <UiCard type="flat">
                  <ul style="display:flex;flex-direction: column;gap: 16px;">
                    <li v-for="campaign in campaigns">
                      <div style="display: flex;gap: 16px;">
                        <UiThumbnail></UiThumbnail>
                        <div style="width: 100%;">
                          <div style="color:#7b7b7b;font-size: 16px">{{ campaign.type }}</div>
                          <UiHeadline size="m" style="margin-bottom: 8px">{{ campaign.name }}</UiHeadline>
                          <UiBadge :type="campaign.statusBadge">{{ campaign.status }}</UiBadge>
                          <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 8px;">
                            <div>
                              <div style="color:#7b7b7b;font-size: 16px">Spend</div>
                              <div style="display:flex;gap: 8px;"><div>Today: $129</div><div>|</div><div>Lifetime: $928</div></div>
                            </div>
                            <UiButton color="outline" size="s">Open</UiButton>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </UiCard>
              </div>

              <div>
                <UiHeadline size="l">Virt's work</UiHeadline>
                <UiCard type="flat">
                  <ul style="display:flex;flex-direction: column;gap: 16px;">
                    <li v-for="campaign in virts_work">
                      <div style="display: flex;gap: 16px;">
                        <UiThumbnail title="Task" subtitle="Result"></UiThumbnail>
                        <div style="width: 100%;">
                          <div style="color:#7b7b7b;font-size: 16px">{{ campaign.type }}</div>
                          <UiHeadline size="m" style="margin-bottom: 8px">{{ campaign.name }}</UiHeadline>
                          <UiAvatar class="user-item-avatar" imgUrl="https://cdn.purdia.com/images/avatar.png"></UiAvatar>
                          <UiBadge :type="campaign.statusBadge">{{ campaign.status }}</UiBadge>
                          <div style="display: flex;justify-content: space-between;align-items: center;margin-top: 8px;">
                            <div>
                              <div style="color:#7b7b7b;font-size: 16px">Spend</div>
                              <div style="display:flex;gap: 8px;"><div>Today: $129</div><div>|</div><div>Lifetime: $928</div></div>
                            </div>
                            <UiButton color="outline" size="s">Approve</UiButton>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </UiCard>
              </div>

              <!-- System Status -->
              <div>
                <UiHeadline size="m" style="margin-bottom: 12px;">Status</UiHeadline>
                <div style="display: grid;grid-template-columns: repeat(3, 1fr);gap: 8px;">
                  <div style="width: 100%;height: 128px;border-radius: 12px;border: 1px solid #e7e7e7;" v-for="status in statusCards">
                    <div style="padding: 16px;">
                      <UiStatus :type="status.statusBadge"></UiStatus>
                      <div style="color: #000;">{{ status.name }}</div>
                      <div style="font-size: 32px;">{{ status.value }}</div>
                      <div style="font-size: 12px;color: rgb(123, 123, 123);">{{ status.lastEvent }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <UiHeadline size="l">Automations</UiHeadline>
                <UiCard type="flat">
                  <ul style="display:flex;flex-direction: column;gap: 8px;">
                    <li v-for="campaign in campaigns">
                      <div style="display: flex;gap: 16px;">
                        <UiThumbnail></UiThumbnail>
                        <div style="width: 100%;">
                          <div style="color:#7b7b7b;font-size: 16px;">{{ campaign.type }}</div>
                          <UiHeadline size="m" style="margin-bottom: 8px">{{ campaign.name }}</UiHeadline>
                          <UiBadge :type="campaign.statusBadge">{{ campaign.status }}</UiBadge>
                          <div style="display: flex;justify-content: end;">
                            <UiButton color="outline" size="s">Open</UiButton>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </UiCard>
              </div>
              <div>
                <UiHeadline size="l" align="center">Experimental</UiHeadline>
                <UiCard type="flat">
                  <UiButton color="outline">Tracking</UiButton>
                </UiCard>
              </div>
            </div>

            <!-- Info Sidebar -->
            <!-- Temporarily hidden because of too distracting -->
            <div style="width: 200px;flex: none;display: flex;flex-direction: column;gap: 8px;">
              <template v-if="false">
                <div style="background: #e7e7e7;width: 100%;height: 128px;border-radius: 12px;" v-for="status in statusCards">
                  <div style="padding: 16px;">
                    <div>{{ status.name }}</div>
                    <div style="font-size: 32px;">{{ status.value }}</div>
                    <UiBadge :type="status.statusBadge">{{ status.status }}</UiBadge>
                  </div>
                </div>
              </template>
            </div>
          </div>
          <!-- Search -->
          <div class="panel-search">
            <UiInput label="Search" @update:modelValue="searchTerm" @enter="onSearch"/>
            <UiButton color="outline" size="s">End Search</UiButton>
          </div>
          <!-- <UiMenuBar :items="filterItems" style="width:100%;"/> -->
          <UserListItem 
            v-for="user in items" 
            :key="user.username" 
            :user="user"
            v-if="display === 'list'"
          />
          <slot></slot>
          <template v-slot:footer>
            <UiPagination :current-page="currentPage" :total-pages="totalPages" :items-per-page="itemsPerPage" @update:currentPage="updateCurrentPage" @update:itemsPerPage="updateItemsPerPage"></UiPagination>
          </template>
        </UiCard>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, ref, watch } from 'vue';
import axios from "axios";
import { UiSidebar, UiCard, UiHeadline, UiNavigation, UiMenuBar, UiButton, UiPagination, UiInput, UiThumbnail, UiBadge, UiStatus } from '../../components/UI';
import UserListItem from '../../components/functional/UserListItem/UserListItem';
import Chart from '../../components/charts/Chart';

const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJpbmZvQHB1cmRpYS5jb20iLCJleHAiOjE3MTY5MDA3Njh9.xIN_GD_lf_FbY1rdnmjRPoKts6ktCVe9RiszkNdUifE'

let items = ref([])
let currentPage = ref(1)
let totalItems = ref(0)
let itemsPerPage = ref(100)
let searchTerm = ref('')

const campaigns = ref([
  {
    name: "Blackfriday 40% Discount",
    type: "Sales",
    status: "Running",
    statusBadge: "success"
  },
  {
    name: "Affiliate outreach",
    type: "Leads",
    status: "Running",
    statusBadge: "success"
  },
  {
    name: "First time purchase Welcome",
    type: "Sales",
    status: "Review",
    statusBadge: "warning"
  },
  {
    name: "Non-Purchase List Nuture",
    type: "Nuture",
    status: "Planned",
    statusBadge: "gray"
  }
])

const virts_work = ref([
  {
    name: "3 Ad Suggestions",
    type: "Sales",
    status: "Ready",
    statusBadge: "success"
  },
  {
    name: "Twitter Post",
    type: "Leads",
    status: "Ready",
    statusBadge: "success"
  },
  {
    name: "Marketing Performance Summary",
    type: "Sales",
    status: "Question",
    statusBadge: "warning"
  }
])
const statusCards = ref([
  {
    name: "API Health",
    value: "100%",
    status: "Good",
    statusBadge: "success"
  },
  {
    name: "Ad Spend",
    value: "$462",
    status: "High",
    statusBadge: "warning"
  },
  {
    name: "Checkout's",
    value: "99%",
    status: "Good",
    statusBadge: "success"
  },
  {
    name: "Ext. API Health",
    value: "72%",
    status: "Failure",
    statusBadge: "danger",
    lastEvent: "23 min. ago"
  },
])

const totalPages = computed(() => {
  return totalItems.value / itemsPerPage.value
})

const skipItems = computed(() => {
  return currentPage.value > 1 ? currentPage.value * itemsPerPage.value : 0
})

watch(skipItems, () => {
  axios.request({
    method: 'get',
    maxBodyLength: Infinity,
    url: `http://127.0.0.1:8000/api/v1/contacts?skip=${skipItems.value}&limit=${itemsPerPage.value}&order_by=id&order_direction=asc`,
    headers: { 
      'accept': 'application/json', 
      'Content-Type': 'application/json', 
      'Authorization': `Bearer ${token}`
    }
  })
  .then((response) => {
    items.value = response.data.items
    totalItems.value = response.data.total
  })
  .catch((error) => {
    console.log(error);
  });
}, {
  immediate: true 
})

const filterItems = computed(() => [
  {
    key: 'order',
    name: 'Order by: Name',
    icon: 'edit',
    children: [
      { key: 'undo', name: 'Undo', icon: 'undo' },
      { key: 'redo', name: 'Redo', icon: 'redo' },
    ],
  },
  {
    key: 'skip',
    name: 'Per page: 10',
    icon: 'edit',
    children: [
      { key: 'undo', name: 'Undo', icon: 'undo' },
      { key: 'redo', name: 'Redo', icon: 'redo' },
    ],
  },
])

const props = defineProps({
  title: String,
  display: String,
  navItems: {
    type: Array,
    required: true,
  },
  userAvatar: {
    type: String,
    required: true,
  },
  containerSize: {
    type: String,
    default: 'm',
    validator: (value) => ['full', 'xl', 'm', 's'].includes(value),
  },
  fixed: {
    type: Boolean,
    default: false,
  },
  menuItems: Array,
  totalPages: Number,
  stats: Array
});

const containerClass = computed(() => {
  return {
    'container-full': props.containerSize === 'full',
    'container-xl': props.containerSize === 'xl',
    'container-m': props.containerSize === 'm',
    'container-s': props.containerSize === 's',
  };
});

const updateCurrentPage = (page) => {
  currentPage.value = page;
}

const updateItemsPerPage = (items) => {
  itemsPerPage.value = items
}

const onSearch = (searchTerm) => {
  watch(skipItems, () => {
    axios.request({
      method: 'get',
      maxBodyLength: Infinity,
      url: `http://127.0.0.1:8000/api/v1/contacts-search?query=${searchTerm}&skip=${skipItems.value}&limit=${itemsPerPage.value}`,
      headers: { 
        'accept': 'application/json', 
        'Content-Type': 'application/json', 
        'Authorization': `Bearer ${token}`
      }
    })
    .then((response) => {
      items.value = response.data.items
    })
    .catch((error) => {
      console.log(error);
    });
  }, {
    immediate: true 
  })
}
</script>

<style>
.panel {
  position: fixed;
  top: var(--navigation_height);
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  height: calc(100vh - var(--navigation_height));
  overflow: auto;
}

.panel-content {
  display: flex;
  flex: 1;
}

.container-full {
  width: 100%;
}

.container-xl {
  max-width: 1200px;
  margin: 0 auto;
}

.container-m {
  max-width: 800px;
  margin: 0 auto;
}

.container-s {
  max-width: 600px;
  margin: 0 auto;
}

.content {
  width: 100%;
  padding: 20px;
  margin: auto;
  max-width: 960px;
  height: 100%;
}

.panel .sidebar {
  position: fixed;
  top: 74px;
  left: 0;
  z-index: 128;
  height: calc(100vh - 74px);
}

.panel-search {
  width: 100%;
  display: flex;
  gap: 16px;
  align-items: end;
  margin-bottom: 16px;
}

@media (min-width: 768px) {
  .panel {
    flex-direction: row;
  }
}
</style>
