<template>
  <div class="tab-bar">
    <div
      v-for="(tab, index) in tabs"
      :key="index"
      :class="['tab', { active: activeTab === index }]"
      @click="selectTab(index)"
    >
      {{ tab.label }}
    </div>
  </div>
  <div class="tab-content">
    <slot :name="`tab-${activeTab}`"></slot>
  </div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
  tabs: Array
})

const activeTab = ref(1);

const selectTab = (index) => {
  activeTab.value = index;
};
</script>

<style scoped>
.tab-bar {
  display: flex;
  border-bottom: 1px solid #e7e7e7;
}

.tab {
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
}

.tab.active {
  font-weight: bold;
  border-bottom: 2px solid var(--brand-color);
}
</style>
